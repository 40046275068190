import { rbacActions } from "../actionTypes";

const getRbacs = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.rbac.lastUpdate;
  }
  return dispatch({ type: rbacActions.GET_RBACS_REQUEST, ...extra });
};
const getRbac = (id) => (dispatch) => dispatch({ type: rbacActions.GET_RBAC_REQUEST, id });
const putRbac = (id, changes) => (dispatch) => dispatch({ type: rbacActions.PUT_RBAC_REQUEST, id, ...changes });
const postRbac = (rbac) => (dispatch) => dispatch({ type: rbacActions.POST_RBAC_REQUEST, ...rbac });
const removeRbac = (id) => (dispatch) => dispatch({ type: rbacActions.DELETE_RBAC_REQUEST, id });

export { getRbac, getRbacs, putRbac, postRbac, removeRbac };
