import { authActions } from "../actionTypes";
import { createLogger } from "../../utils/logging";

const DEBUG = true;
const debug = createLogger(DEBUG, `authReducer.js`);

const initialState = {
  groups: [],
  idToken: null,
  userDetails: null,
  user: null,
  session: null,
  organization: null,
  sentry_web_key: null,
  sentry_mobile_key: null,
  log_rocket_organization: null,
  log_rocket_web_key: null,
  log_rocket_mobile_key: null
};

const authReducer = (state = initialState, action) => {
  debug.enter("authReducer");
  debug.log(`action(${action.type}) -> `, action);
  let newState = state;

  switch (action.type) {
    /* -------- auth0 authentication -------- */
    case authActions.SET_ID_TOKEN:
      newState = { ...state, idToken: action.idToken };
      break;

    case authActions.AUTH_SUCCESS:
      newState = {
        ...state,
        session: action.session
      };
      break;

    case authActions.AUTH_FAILURE:
      newState = {
        ...state,
        session: null,
        user: null
      };
      break;

    /* -------- auth0 user details retrieval -------- */
    case authActions.USER_SUCCESS:
      newState = {
        ...state,
        user: action.user
      };
      break;

    case authActions.USER_FAILURE:
      newState = {
        ...state,
        user: null
      };
      break;

    /* -------- clear auth state -------- */
    case authActions.CLEAR_AUTH:
      newState = initialState;
      break;

    case authActions.POST_SESSION_SUCCESS:
      newState = { ...state, ...action.data.object };
      break;

    case authActions.POST_SESSION_FAILURE:
      newState = { ...state, sessionError: action.data };
      break;

    default:
      newState = state;
  }
  debug.log("authReducer results: ", newState);
  debug.exit("authReducer");
  return newState;
};

export default authReducer;
