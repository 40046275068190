import React from "react";
import { createLogger } from "../../../utils/logging";
import { DialogTitle, DialogContentText, DialogContent, Dialog } from "@material-ui/core";

const DEBUG = true;
const debug = createLogger(DEBUG, `WhatsNew.js`);

const WhatsNew = ({ isOpen, onClose }) => {
  debug.log("render WhatsNew");

  return (
    <div>
      <Dialog
        fullWidth="md"
        maxWidth="md"
        scroll="body"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle>What's New In C2Sense</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description">new features, bugfixes, etc</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WhatsNew;
