import { createLogger } from "./logging";

const DEBUG = true;
const debug = createLogger(DEBUG, `requestUtils.js`);

function insertRecord(list, record, byId) {
  debug.enter(`insertRecord`);
  debug.log(`  record:`, record);
  debug.log(`  list:`, list);

  const updateIndex = list.findIndex((listRecord) => record._id === listRecord._id);

  if (updateIndex !== -1) {
    debug.log(`  found matching id`);
    debug.log(`    record:`, record);
    debug.log(`    listRecord:`, list[updateIndex]);
    let newRecord = { ...record };
    list[updateIndex] = {
      ...list[updateIndex],
      ...newRecord
    };
    if (byId) byId[newRecord._id] = list[updateIndex];
  } else {
    list.push(record);
    if (byId) byId[record._id] = record;
  }
  debug.exit(`insertRecord`);
  return true;
}

function mergeLists(oldList, newList, byId) {
  debug.enter(`mergeLists`);
  debug.log(`  oldList: len=${oldList.length}`, oldList);
  debug.log(`  newList: len=${newList.length}`, newList);
  let mergedList;

  if (!oldList || oldList.length < 1) {
    debug.log(`  oldList is empty; returning newList`);
    let retval = [...newList];
    if (byId) {
      retval.forEach((item) => {
        byId[item._id] = item;
      });
    }
    mergedList = [...newList];
  } else {
    if (!byId) byId = {};
    let idList = [];
    let oldCopy = [...oldList];
    oldCopy.forEach((item) => {
      byId[item._id] = item;
      idList.push(item._id);
    });
    let newCopy = [...newList];
    newCopy.forEach((newRecord) => {
      if (!byId[newRecord._id]) idList.push(newRecord._id);
      byId[newRecord._id] = newRecord;
    });
    mergedList = [];
    idList.forEach((id) => mergedList.push(byId[id]));
  }

  debug.exit("mergeLists");
  return mergedList;
}

const deleteRecord = (cache, id, byId) => {
  debug.enter(`deleteRecord`);
  if (byId) {
    delete byId[id];
  }
  let newCache = [...cache];
  for (let idx = 0; idx < cache.length; ++idx) {
    if (cache[idx]._id === id) {
      newCache.splice(idx, 1);
      break;
    }
  }
  debug.log(`  newList:`, newCache);
  debug.exit("deleteRecord");
  return newCache;
};

const processDependentChanges = (action, state, clz) => {
  const key = `${clz}s`,
    dependentChanges = action?.data?.dependent_changes;

  if (dependentChanges && dependentChanges[key]) {
    let newState = { ...state };

    dependentChanges[key].forEach((object) => {
      insertRecord(newState.cache, object, newState.cacheById);
      newState.cacheUpdate = new Date();
    });
    return newState;
  }
  return state;
};

export { mergeLists, insertRecord, deleteRecord, processDependentChanges };
