import { authentagActions } from "../actionTypes";

const getAuthentags = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.authentag.lastUpdate;
  }
  return dispatch({ type: authentagActions.GET_AUTHENTAGS_REQUEST, ...extra });
};
const getAuthentag = (id) => (dispatch) => dispatch({ type: authentagActions.GET_AUTHENTAG_REQUEST, id });
const putAuthentag = (id, changes) => (dispatch) => dispatch({ type: authentagActions.PUT_AUTHENTAG_REQUEST, id, ...changes });
const postAuthentag = (authentag) => (dispatch) => dispatch({ type: authentagActions.POST_AUTHENTAG_REQUEST, ...authentag });
const removeAuthentag = (id) => (dispatch) => dispatch({ type: authentagActions.DELETE_AUTHENTAG_REQUEST, id });

export { getAuthentag, getAuthentags, putAuthentag, postAuthentag, removeAuthentag };
