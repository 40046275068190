/*
 *
 * DO NOT EDIT, this is automatically generated by __main__
 */

export const AUTH_CONFIG = {
	 domain: 'c2sense.us.auth0.com',
	 clientId: 'EGHRkAGRe5zk2ZuG8YBnsUaKsqtW9Nd8',
	 callbackUrl: 'https://autags.c2sense.com:443/callback'
};
