import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia } from "@material-ui/core";
import "ag-grid-enterprise";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    maxWidth: 500,
    height: 300,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    position: "relative"
  },
  cardHovered: {
    transform: "scale3d(1.05, 1.05, 1)"
  },
  media: {
    width: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

const OrganizationCard = ({ organization }) => {
  const [hoverState, setHoverState] = useState({
    raised: false,
    shadow: 1
  });

  const classes = useStyles();

  return (
    <Link className="overlay-fade-in" underline="none" to={`/c2sense/organizations/${organization.name}`}>
      <Card
        className={classes.root}
        classes={{ root: hoverState.raised ? classes.cardHovered : "" }}
        onMouseOver={() => {
          setHoverState({ raised: true, shadow: 3 });
        }}
        onMouseOut={() => {
          setHoverState({ raised: false, shadow: 1 });
        }}
        raised={hoverState.raised}
        zdepth={hoverState.shadow}>
        <CardMedia>
          <img alt="media" src={organization.logo} align="center" className={classes.media} />
        </CardMedia>
      </Card>
    </Link>
  );
};

export default OrganizationCard;
