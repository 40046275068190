import { experimentActions } from "../actionTypes";

const getExperiments = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.experiment.lastUpdate;
  }
  return dispatch({ type: experimentActions.GET_EXPERIMENTS_REQUEST, ...extra });
};
const getExperiment = (id) => (dispatch) => dispatch({ type: experimentActions.GET_EXPERIMENT_REQUEST, id });
const putExperiment = (id, changes) => (dispatch) => dispatch({ type: experimentActions.PUT_EXPERIMENT_REQUEST, id, ...changes });
const postExperiment = (experiment) => (dispatch) => dispatch({ type: experimentActions.POST_EXPERIMENT_REQUEST, ...experiment });
const removeExperiment = (id) => (dispatch) => dispatch({ type: experimentActions.DELETE_EXPERIMENT_REQUEST, id });

export { getExperiment, getExperiments, putExperiment, postExperiment, removeExperiment };
