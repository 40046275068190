import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  DialogTitle,
  IconButton,
  TextField,
  InputLabel,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { putAuthentag } from "../../../../data/authentag/actions";
import { makeStyles } from "@material-ui/core/styles";
import ReactMarkdown from "react-markdown";
import lodash from "lodash";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  },
  markdown: {
    display: "flex",
    flexDirection: "column"
  },
  markdownFields: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    },
    width: "100%",
    maxWidth: "100%",
    fontSize: "1rem"
  },
  configTitle: {
    marginTop: "30px"
  },
  divider: {
    background: "#9E9E9E"
  }
}));

const EditAuthentagForm = (props) => {
  const { data } = props;

  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [notes, setNotes] = useState(data.notes);
  const [config, setConfig] = useState(null);
  const [supportedVersions, setSupportedVersions] = useState(data.supported_versions);
  const atkitVersions = useSelector((state) => Object.values(state.atkit.cacheById));
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleName = (e) => setName(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleNotes = (e) => setNotes(e.target.value);
  const handleConfig = (e) => setConfig(e.target.value);
  // Multi-select
  const handleSupportedVersions = (e) => setSupportedVersions(e.target.value);

  const id = data._id;

  useEffect(() => {
    setConfig(data.config ? JSON.stringify(data.config, null, 2) : "No Config Available");
  }, [data.config]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let backtick = config
      .replace(/^\s+|\s+$/gm, "")
      .split("\n")
      .join("");
    let woBacktick = backtick.replace(/`/g, "");
    let reversed = JSON.parse(woBacktick);

    const editedAuthentag = {
      name: name,
      description: description,
      notes: notes,
      config: reversed,
      supported_versions: supportedVersions
    };

    //FIXME: should be "setting" from the response
    dispatch(putAuthentag(id, editedAuthentag)).then((response) => {
      let autag = response.object;

      props.setOpenUpdateAuthentagSnackbar(true);
      setName(autag.name);
      setDescription(autag.description);
      setNotes(autag.notes);
      setConfig(autag.config);
      setSupportedVersions(autag.supported_versions);
      setShowForm(false);
    });
  };

  return (
    <div>
      <Dialog open={showForm} onClose={() => setShowForm(false)} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Update Authentag
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="name"
            name="name"
            label="Name"
            value={name}
            onChange={handleName}
            type="text"
            required
            fullWidth
            error={name === ""}
            helperText={name === "" ? "Name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="description"
            name="description"
            label="Description"
            value={description}
            onChange={handleDescription}
            type="text"
            required
            fullWidth
            error={description === ""}
            helperText={description === "" ? "Description is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="notes"
            name="notes"
            multiline
            label="Notes"
            value={notes}
            onChange={handleNotes}
            type="text"
            fullWidth
            className={classes.input}
          />
          <TextField
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => selected.join(", ")
            }}
            multiple
            variant="outlined"
            margin="dense"
            key="supported_versions"
            name="supported_versions"
            label="Supported AtKit Versions"
            value={supportedVersions}
            onChange={(e) => setSupportedVersions(e.target.value)}
            fullWidth
            className={classes.input}
            error={lodash.isEmpty(supportedVersions)}
            helperText={lodash.isEmpty(supportedVersions) ? "At least one version is required!" : " "}>
            {atkitVersions.map((atkit) => (
              <MenuItem key={atkit.name} value={atkit.name}>
                <Checkbox checked={supportedVersions.includes(atkit.name)} />
                <ListItemText primary={`Version ${atkit.name}`} />
              </MenuItem>
            ))}
          </TextField>
          <Divider />
          <InputLabel shrink={true} className={classes.configTitle}>
            Config Edit
          </InputLabel>
          <Divider className={classes.divider} />
          <div className={classes.markdown}>
            <TextField
              margin="dense"
              key="config"
              name="config"
              multiline
              rows={6}
              rowsMax={50}
              value={config}
              onChange={handleConfig}
              type="text"
              fullWidth
              className={classes.markdownFields}
            />
            <InputLabel shrink={true} className={classes.configTitle}>
              Preview
            </InputLabel>
            <ReactMarkdown children={config} source={config} onChange={handleConfig} value={config} className={classes.markdownFields} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={() => setShowForm(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setShowForm(true)}>
        <EditIcon />
      </IconButton>
    </div>
  );
};

export default EditAuthentagForm;
