import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { defaultGridConfig } from "./config/defaultGridConfig";
import { Typography, Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import GridColumnIcon from "./cellComponents/GridColumnIcon";
import { createLogger } from "../../utils/logging";
import { getHostPort } from "../../utils/environmentHelper";
import { download } from "../../utils/utils";
import MetaDataDialog from "./cellComponents/MetaDataDialog";
import ScanNotesImage from "./cellComponents/scan/ScanNotesImage";
import ReactMarkdown from "react-markdown";
import Image from "material-ui-image";
import _ from 'lodash';

const DEBUG = true;
const debug = createLogger(DEBUG, `ScanGrid.js`);

const ScanGrid = () => {
  const scans = useSelector((state) => _.filter(state.scan.cache, {parent_type: 'experiment'}));
  const authentags = useSelector((state) => state.authentag.cacheById);
  const organizations = useSelector((state) => state.organization.cacheById);
  const superOrg = useSelector((state) => state.auth.organization.super_org);
  const orgId = useSelector((state) => state.auth.organization._id);
  const showIntensity = useSelector((state) => state.auth.organization.show_intensity);
  const users = useSelector((state) => state.user.cacheById);
  const idToken = useSelector((state) => state.auth.idToken);

  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [notesImageDataId, setNotesImageDataId] = useState(null);
  const [openNotesImage, setOpenNotesImage] = useState(false);

  debug.log("ScanGrid render()");

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotesImageClose = () => {
    setOpenNotesImage(false);
  };

  const image = {
    src: {
      uri: `${getHostPort()}/api/v1/scan/notes_jpg/${notesImageDataId}`,
      // width,
      // height,
      headers: {
        "content-type": "image/jpeg",
        // Authorization: `Bearer ${session.idToken}`,
        Authorization: `Bearer ${idToken}`
      }
    }
  };

  const rowData = useMemo(() => {
    let sortedArr = [];
    if (superOrg) {
      Object.keys(organizations).forEach((orgId) => {
        let scansList = scans.filter((scan) => scan.organization_id === orgId);
        let newScanList = [];
        let orgName = organizations[orgId].name;

        scansList.forEach((scan) => {
          let newScan = { ...scan };

          let tag = authentags[newScan.authentag_id];
          if (!tag) {
            debug.error(`Missing tag ${newScan.authentag_id} for org ${orgName}`)
          }
          newScan["authentagName"] = tag ? tag.name : `Invalid id ${newScan.authentag_id}`;
          newScan["orgName"] = organizations[orgId].name;
          newScan["date"] = newScan["scan_datetime"].toDateString();
          newScan["createdBy"] = users[newScan.created_by_id].email;
          newScan["tagsString"] = newScan.tags ? newScan.tags.toString() : "";
          newScan["metaData"] = newScan.meta_data ? JSON.stringify(newScan.meta_data, null, 2) : "No Meta Data Available";
          newScan["productData"] = newScan.product_data ? newScan.product_data : "";
          newScan.scanExceptionsString = newScan.scan_exceptions ? newScan.scan_exceptions.toString() : "";

          newScanList.push(newScan);
        });
        sortedArr.push(...newScanList);
      });
    } else {
        let orgName = organizations[orgId].name;
        let scansList = scans.filter((scan) => scan.organization_id === orgId);
        let newScanList = [];

        scansList.forEach((scan) => {
          let newScan = { ...scan };
          let tag = authentags[newScan.authentag_id];
          if (!tag) {
            debug.error(`Missing tag ${newScan.authentag_id} for org ${orgName}`)
          }
          newScan["authentagName"] = tag ? tag.name : `Invalid id ${newScan.authentag_id}`;
          newScan["date"] = newScan["scan_datetime"].toDateString();
          newScan["createdBy"] = users[newScan.created_by_id].email;
          newScan["productData"] = newScan.product_data ? newScan.product_data : "";
          newScan.scanExceptionsString = newScan.scan_exceptions ? newScan.scan_exceptions.toString() : "";

          newScanList.push(newScan);
        });
        sortedArr.push(...newScanList);
    }
    return sortedArr;
  }, [superOrg, orgId, scans, organizations, authentags, users]);

  const renderCheckbox = (params) => !!params.data;

  const onDownloadSelectedPackage = (include_data) => () => {
    // include_data seems to get overridden in the python api
    let scan_ids = gridApi.getSelectedNodes().map((node) => node.data._id);

    download(
      `${getHostPort()}/api/v1/scans/download`,
      "POST",
      { scan_ids, include_data },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      },
      include_data ? "package.zip" : "package.xlsx"
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const columnDefs = [
    { headerName: "Organization", field: "orgName", rowGroup: true, hide: true },
    { headerName: "Name", field: "name", rowGroup: false },
    { headerName: "Date", field: "date", checkboxSelection: renderCheckbox, width: 200, tooltipField: "date" },
    {
      headerName: "Meta Data",
      field: "metaData",
      cellRendererFramework: MetaDataDialog,
      width: 40,
      onCellClicked: (params) => {
        if (params.data) {
          setMetaData(params.value);
          setOpen(true);
        }
      }
    },
    {
      headerName: "Notes Image",
      field: "metaData",
      cellRendererFramework: ScanNotesImage,
      width: 40,
      onCellClicked: (params) => {
        if (params.data) {
          setNotesImageDataId(params.data._id);
          setOpenNotesImage(true);
        }
      }
    },
    { headerName: "Result", field: "status", cellRendererFramework: GridColumnIcon, width: 40 },
    { headerName: "Intensity", field: "intensity", width: 200, tooltipField: "intensity" },
    { headerName: "Product Data", field: "productData", width: 200, tooltipField: "productData" },
    { headerName: "Scan Exceptions", field: "scanExceptionsString", hide: true },
    { headerName: "Other Notes", field: "notes", tooltipField: "notes" },
    { headerName: "Tags", field: "tagsString", tooltipField: "tagsString" },
    { headerName: "Created By", field: "createdBy", width: 200, tooltipField: "createdBy" },
    { headerName: "Authentag", field: "authentagName", width: 200, tooltipField: "authentagName" },
    { headerName: "Authentag ID", field: "authentag_id", hide: true },
    { headerName: "Experiment ID", field: "parent_id", hide: true },
    { headerName: "Parent Type", field: "parent_type", tooltipField: "parent_type" },
    { headerName: "Organization ID", field: "organization_id", hide: true },
    { headerName: "Scan ID", field: "_id", hide: true },
    { headerName: "URL", field: "scan_url", hide: true },
    { headerName: "User ID", field: "created_by_id", hide: true },
    {
      headerName: "Archived",
      field: "archived",
      valueGetter: (params) => {
        if (!params.data || !params.data.archived) return;
        else return "Yes";
      }
    }
  ];

  const nonSuperUserColumnDefs = [
    { headerName: "Name", field: "name", checkboxSelection: renderCheckbox },
    { headerName: "Date", field: "date", width: 200, tooltipField: "date" },
    {
      headerName: "Notes Image",
      field: "metaData",
      cellRendererFramework: ScanNotesImage,
      width: 40,
      onCellClicked: (params) => {
        if (params.data) {
          setNotesImageDataId(params.data._id);
          setOpenNotesImage(true);
        }
      }
    },
    { headerName: "Result", field: "status", cellRendererFramework: GridColumnIcon, width: 40 },
    { headerName: "Intensity", field: showIntensity ? "intensity" : null, width: 200, tooltipField: "intensity", hide: !showIntensity },
    { headerName: "Product Data", field: "productData", width: 200, tooltipField: "productData" },
    { headerName: "Scan Exceptions", field: "scanExceptionsString", hide: true },
    { headerName: "Other Notes", field: "notes", tooltipField: "notes" },
    { headerName: "Created By", field: "createdBy", width: 200, tooltipField: "createdBy" },
    { headerName: "Authentag", field: "authentagName", width: 200, tooltipField: "authentagName" },
    { headerName: "Authentag ID", field: "authentag_id", hide: true },
    { headerName: "Experiment ID", field: "parent_id", hide: true },
    { headerName: "Organization ID", field: "organization_id", hide: true },
    { headerName: "Scan ID", field: "_id", hide: true },
    { headerName: "URL", field: "scan_url", hide: true },
    { headerName: "User ID", field: "created_by_id", hide: true }
  ];

  const displayString = { metaData } ? `\`\`\`${metaData}\`\`\`` : "No Meta Data available";

  const gridConfig = {
    ...defaultGridConfig,
    onGridReady,
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: true,
      filter: true,
      resizable: true
    },
    autoGroupColumnDef: {
      minWidth: 200,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox: true
      }
    },
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
    groupMultiAutoColumn: true,
    enableRangeSelection: true,
    animateRows: true,
    rowData: rowData,
    columnDefs: superOrg ? columnDefs : nonSuperUserColumnDefs,
    getRowNodeId: (data) => data._id,
    rowSelection: "multiple",
    groupSelectsChildren: true,
    alwaysShowVerticalScroll: true,
    suppressHorizontalScroll: false,
    scrollbarWidth: 10,
    rowHeight: 50,
    paginationPageSize: 50,
    pagination: true,
    paginateChildRows: true
  };

  return (
    <div style={{ width: "100%", height: 600 }}>
      <Typography gutterBottom variant="h4" component="h2">
        Scans
      </Typography>
      <div
        id="scanGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-alpine">
        <Button
          onClick={onDownloadSelectedPackage(true)}
          variant="outlined"
          style={{ fontWeight: "bold", marginBottom: "10px" }}>
          {superOrg ? "Download Selected Scan Images" : "Download Selected Scans"}
        </Button>
        <AgGridReact {...gridConfig} />
        <Dialog
          fullWidth="md"
          maxWidth="md"
          scroll="body"
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle>Meta Data</DialogTitle>
          <DialogContent dividers>
            <ReactMarkdown source={displayString} children={displayString} />
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth="md"
          maxWidth="md"
          scroll="body"
          open={openNotesImage}
          onClose={handleNotesImageClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle>Notes Image</DialogTitle>
          <DialogContent dividers>
            <Image
              src={
                notesImageDataId
                  ? image.src.uri
                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
              }
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ScanGrid;
