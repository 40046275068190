import { authentagActions } from "../actionTypes";
import { createLogger } from "../../utils/logging";
import { mergeLists, insertRecord, deleteRecord, processDependentChanges } from "../../utils/requestUtils";
import { epoch } from "../../utils/dataUtils";

const DEBUG = true;
const debug = createLogger(DEBUG, `authentagReducer.js`);

const initialState = {
  cache: [],
  cacheById: {},
  cacheUpdate: epoch,
  lastUpdate: epoch,
  inflight: 0
};

const authentagReducer = (state = initialState, action) => {
  debug.enter("authentagReducer");
  debug.log(`action(${action.type}) -> `, action);
  let newState = processDependentChanges(action, state, "Authentag");

  switch (action.type) {
    case authentagActions.GET_AUTHENTAGS_REQUEST:
      newState = { ...state };
      newState.inflight++;
      break;

    case authentagActions.GET_AUTHENTAGS_FAILURE:
      newState = { ...state };
      if (newState.inflight > 0) newState.inflight--;
      break;

    case authentagActions.GET_AUTHENTAGS_SUCCESS:
      newState = { ...state };
      if (action.data.objects.length > 0) {
        newState.cache = mergeLists(state.cache, action.data.objects, newState.cacheById);
        newState.cacheUpdate = new Date();
      } else if (newState.cacheUpdate === epoch) {
        newState.cacheUpdate = new Date();
      }
      newState.lastUpdate = action.data.update_time;
      if (newState.inflight > 0) newState.inflight--;
      break;

    case authentagActions.GET_AUTHENTAG_SUCCESS:
    case authentagActions.POST_AUTHENTAG_SUCCESS:
    case authentagActions.PUT_AUTHENTAG_SUCCESS:
      newState = { ...state };
      insertRecord(newState.cache, action.data.object, newState.cacheById);
      newState.cacheUpdate = new Date();
      if (action.data.update_time) {
        newState.lastUpdate = action.data.update_time;
      }
      newState.inflight = 0;
      break;

    case authentagActions.DELETE_AUTHENTAG_SUCCESS:
      newState = { ...state };
      newState.cache = deleteRecord(state.cache, action.data.object._id, newState.cacheById);
      newState.cacheUpdate = new Date();
      newState.inflight = 0;
      break;

    default:
      break;
  }
  debug.log("authentagReducer results: ", newState);
  debug.exit("authentagReducer");
  return newState;
};

export default authentagReducer;
