import React from "react";
import * as PropTypes from "prop-types";
// import { createLogger } from '../../utils/logging';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faWind, faFingerprint, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// const DEBUG = false;
// const debug = createLogger(DEBUG, `GridColumnIcon.js`);

class GridColumnIcon extends React.Component {
  static propTypes = {
    params: PropTypes.object
  };

  render() {
    const { data, colDef, style, value } = this.props;
    let iconStyle = style || colDef._iconStyle || {};
    let icon;
    console.log("props", this.props);

    if (data) {
      if (colDef.field === "status") {
        if (value === "positive") {
          iconStyle.color = "green";
          icon = faTimesCircle;
        } else {
          iconStyle.color = "red";
          icon = faTimesCircle;
        }
      } else if (data.scan_url) {
        icon = faCheckCircle;
        iconStyle.color = "green";
      } else {
        icon = faCheckCircle;
        iconStyle.color = "red";
      }

      return <FontAwesomeIcon style={iconStyle} icon={icon} />;
    }
    return null;
  }
}

export default GridColumnIcon;
