import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createLogger } from "../utils/logging";
import { getHostPort } from "../utils/environmentHelper";

//actions
import { getAuthentags } from "../data/authentag/actions";
import { getDevices } from "../data/device/actions";
import { getExperiments } from "../data/experiment/actions";
import { getOrganizations } from "../data/organization/actions";
import { getProducts } from "../data/product/actions";
import { getRbacs } from "../data/rbac/actions";
import { getScans } from "../data/scan/actions";
import { getUsers } from "../data/user/actions";
import { getAtkitVersions } from "../data/atkit/actions";
import { getUsersDashboard, getSessionsDashboard, getOrganizationsDashboard } from "../data/dashboard/actions";
import { setNotificationSocket } from "../data/requests/reducer";

const DEBUG = true;
const debug = createLogger(DEBUG, `DataManagement.js`);

const DataManagement = (props) => {
  const dispatch = useDispatch();
  debug.log("DataManagement render()");

  const [notificationsConnected, setNotificationsConnected] = useState(false);
  const [notificationSocket, setNotificationSocket] = useState(false);
  const [notifications, setNotifications] = useState({});
  const notify_port = 2112;
  const [isNeeded, setIsNeeded] = useState(true);

  // const createNotificationSocket = () => {
  //
  // };

  useEffect(() => {
    debug.enter(`createNotificationSocket`);

    if (!notificationSocket) {
      // Here if we do not have a notification socket, so create one
      // and attempt the connection to the server.

      // start with the url for the API server, replace 'http' with 'ws'
      // b/c notifications use websocket protocol
      let url = getHostPort().replace(/http/, "ws");
      if (url.indexOf(":") !== -1) {
        // replace the port number from the API url with the port
        // number used by the notifications server.
        url = url.replace(/:[0-9]*$/, ":" + notify_port);
      } else {
        url += ":" + notify_port;
      }
      debug.log(`creating notification socket to url ${url} based on ${getHostPort()}`);
      let socket = new WebSocket(url);

      // Need to set socket value in case we come back in here before connection
      // is complete.
      setNotificationSocket(socket);

      // set web socket event handlers
      socket.onerror = function (event) {
        const readyStates = ["CONNECTING", "OPEN", "CLOSING", "CLOSED"];
        let my_readyState = event.currentTarget.readyState;
        let my_readyStateString = "";
        if (my_readyState < readyStates.length) {
          my_readyStateString = readyStates[my_readyState];
        }
        debug.log(`Socket error event: readyState: ${my_readyStateString}`);
      };

      socket.onopen = function () {
        debug.log("Socket open event: connect");
        openNotificationSocket();
      };

      socket.onmessage = function (event) {
        const data = JSON.parse(event.data);
        debug.log(`Socket message event: data ${data}`);
        onNotification(data);
      };

      socket.onclose = function (event) {
        debug.log(`Socket closed: code: ${event.code}, reason: ${event.reason}`);
        // MW used only setNotificationSocket(null)
        // closeNotification is more complete
        closeNotification();
      };
    }
    // else
    // {
    //   debug.log(`createNotificationSocket and existing socket is ${notificationSocket}`);
    //   // Here if socket is already created.  If it's been more than 60 seconds
    //   // since connection was requested and it's still not connected, then clear
    //   // request.
    //   let now = Date();
    //   if (!notificationsConnected &&
    //       Date(notifySocketTime).add(60, 'seconds') < now)
    //   {
    //     closeNotificationSocket()
    //   }
    // }

    debug.exit("createNotificationSocket");
  }, [notificationSocket]);

  // Open, flag this notification socket as connected
  const openNotificationSocket = () => {
    debug.enter("openNotificationSocket");
    setNotificationsConnected(true);
    debug.exit("openNotificationSocket: set connected state");
  };

  // Close, cleanup notification socket
  const closeNotificationSocket = () => {
    debug.enter(`closeNotificationSocket`);
    notificationSocket.close();
    setNotificationSocket(null);
    debug.log(`closed notification socket`);
  };

  // Callback function from server when data has changed.
  // "packet" contains 'date' and 'type' values.
  const onNotification = (packet) => {
    let newNotifications = { ...notifications };
    let updateStamp = Date(packet.date);
    let notifyStamp = Date();
    debug.log(`NOTIFY: Got notification message for type ${packet.type} at ${notifyStamp.format("HH:mm:ss.SSS")}`);
    let notificationEntry = {
      updateTime: updateStamp,
      notifyTime: notifyStamp,
      isNeeded: true,
      lastNotify: null
    };
    if (!packet.type) return;

    if (newNotifications.hasOwnProperty(packet.type)) {
      // Packet type is already in notifications dict
      if (!newNotifications[packet.type] || !newNotifications[packet.type].isNeeded) {
        // We only update if notify type is null or existing notification date is older than packet.date
        newNotifications[packet.type] = { ...notificationEntry, lastNotify: newNotifications[packet.type].lastNotify };
        debug.log("Updating notification list: ", newNotifications);
        setNotifications(newNotifications);
      } else {
        debug.log(`Skipping notification list update for type ${packet.type} on ${packet.date}`);
      }
    } else {
      // packet.type is NOT is current notification dict.  Set it and call update
      newNotifications[packet.type] = notificationEntry;
      setNotifications(newNotifications);
    }
  };

  const closeNotification = () => {
    debug.enter("closeNotification");
    setNotificationsConnected(false);
    debug.log(`closeNotification connected=false, socket=null`);
  };

  useEffect(() => {
    for (let prop in notifications) {
      if (notifications.hasOwnProperty(prop) && notifications[prop]) {
        let thisNotify = notifications[prop];
        if (!thisNotify.isNeeded) continue;

        switch (prop.toLowerCase()) {
          case "organizations":
            dispatch(getOrganizations(true));
            break;
          case "users":
            dispatch(getUsers(true));
            break;
          case "products":
            dispatch(getProducts(true));
            break;
          case "experiments":
            dispatch(getExperiments(true));
            break;
          case "scans":
            dispatch(getScans(true));
            break;
          case "authentags":
            dispatch(getAuthentags(true));
            break;
          case "rbacs":
            dispatch(getRbacs(true));
            break;
          case "devices":
            dispatch(getDevices(true));
            break;

          case "atkits":
            dispatch(getAtkitVersions(true));
            break;

          default:
            debug.log(`NOTIFY: Error in notification: got ${prop.toLowerCase()}`);
        }
      }
    }
  }, [notifications]);

  return <div key="data-manager" id="c2sense-datamanagement" />;
};

export default DataManagement;
