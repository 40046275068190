import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createLogger } from "../../utils/logging";
import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { defaultGridConfig } from "./config/defaultGridConfig";
import { Typography, Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import GridColumnIcon from "./cellComponents/GridColumnIcon";
import { download } from "../../utils/utils";
import { getHostPort } from "../../utils/environmentHelper";
import MetaDataDialog from "./cellComponents/MetaDataDialog";
import ReactMarkdown from "react-markdown";

const DEBUG = true;
const debug = createLogger(DEBUG, `ProductGrid.js`);

const ProductGrid = () => {
  const organizations = useSelector((state) => state.organization.cacheById);
  const authentags = useSelector((state) => state.authentag.cacheById);
  const users = useSelector((state) => state.user.cacheById);
  const products = useSelector((state) => state.product.cacheById);
  const scans = useSelector((state) => state.scan.cache);
  const idToken = useSelector((state) => state.auth.idToken);

  const [downloadScanId, setDownloadScanId] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [metaData, setMetaData] = useState(null);

  debug.log("ProductGrid render()");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (downloadScanId) {
      download(
        `${getHostPort()}/api/v1/scans/download`,
        "POST",
        { scan_ids: [downloadScanId], include_data: true },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`
        },
        `${downloadScanId}.mp4`
      );
      setDownloadScanId(null);
    }
  }, [downloadScanId]);

  useEffect(() => {
    let sortedArr = [];
    scans
      .filter((scan) => scan.parent_type === "product")
      .forEach((scan) => {
        let newScan = { ...scan };

        newScan.orgName = organizations[scan.organization_id].name;
        newScan.tagsString = scan.tags ? scan.tags.toString() : "";
        newScan.createdBy = users[scan.created_by_id].email;
        newScan.date = scan.scan_datetime.toDateString();
        newScan.productName = products[scan.parent_id] ? products[scan.parent_id].name : "No Product Name";
        newScan.authentagName = authentags[scan.authentag_id] ? authentags[scan.authentag_id].name : "";
        newScan.scanExceptionsString = newScan.scan_exceptions ? newScan.scan_exceptions.toString() : "";
        newScan["metaData"] = newScan.meta_data ? JSON.stringify(newScan.meta_data, null, 2) : "No Meta Data Available";

        sortedArr.push(newScan);
      });
    setRowData(sortedArr);
  }, [products, scans, organizations, users, authentags]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const renderCheckbox = (params) => !!params.data;

  const onDownloadSelectedPackage = (include_data) => () => {
    let scan_ids = gridApi.getSelectedNodes().map((node) => node.data._id);

    download(
      `${getHostPort()}/api/v1/scans/download`,
      "POST",
      { scan_ids, include_data },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      },
      include_data ? "package.zip" : "package.xlsx"
    );
  };

  const columnDefs = [
    { headerName: "Organization", field: "orgName", rowGroup: true, hide: true },
    { headerName: "Product", field: "productName", rowGroup: true, hide: true },
    { headerName: "Date", field: "date", checkboxSelection: renderCheckbox, width: 200, tooltipField: "date" },
    { headerName: "Scan", field: "name", tooltipField: "name" },
    {
      headerName: "Meta Data",
      field: "metaData",
      cellRendererFramework: MetaDataDialog,
      width: 40,
      onCellClicked: (params) => {
        if (params.data) {
          setMetaData(params.value);
          setOpen(true);
        }
      }
    },
    { headerName: "Result", field: "status", cellRendererFramework: GridColumnIcon, width: 10 },
    {
      headerName: "Download",
      field: "_id",
      width: 10,
      cellRendererFramework: GridColumnIcon,
      onCellClicked: (params) => {
        if (params.data) {
          setDownloadScanId(params.data._id);
        }
      }
    },
    { headerName: "Intensity", field: "intensity", width: 200, tooltipField: "intensity" },
    { headerName: "Scan Exceptions", field: "scanExceptionsString", hide: true },
    { headerName: "Notes", field: "notes", tooltipField: "notes" },
    { headerName: "Tags", field: "tagsString", tooltipField: "tagsString" },
    { headerName: "Created By", field: "createdBy", width: 200, tooltipField: "createdBy" },
    { headerName: "Authentag Name", field: "authentagName", width: 200, tooltipField: "authentagName" },
    { headerName: "Authentag ID", field: "authentag_id", hide: true },
    { headerName: "Experiment ID", field: "parent_id", hide: true },
    { headerName: "Organization ID", field: "organization_id", hide: true },
    { headerName: "Scan ID", field: "_id", hide: true },
    { headerName: "URL", field: "scan_url", hide: true },
    { headerName: "User ID", field: "created_by_id", hide: true }
  ];

  const displayString = { metaData } ? `\`\`\`${metaData}\`\`\`` : `No Meta Data available`;

  const gridConfig = {
    ...defaultGridConfig,
    onGridReady,
    defaultColDef: {
      sortable: true,
      editable: false,
      filter: true,
      resizable: true,
      minWidth: 150,
      flex: 1
    },
    autoGroupColumnDef: {
      minWidth: 200,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox: true
      }
    },
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
    groupMultiAutoColumn: true,
    enableRangeSelection: true,
    animateRows: true,
    rowData,
    columnDefs,
    getRowNodeId: (data) => data._id,
    rowSelection: "multiple",
    groupSelectsChildren: true,
    alwaysShowVerticalScroll: true,
    suppressHorizontalScroll: false,
    scrollbarWidth: 10,
    rowHeight: 50
  };

  return (
    <div style={{ width: "100%", height: 600 }}>
      <Typography gutterBottom variant="h4" component="h2">
        Products
      </Typography>
      <div
        id="productGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-alpine">
        <AgGridReact {...gridConfig} />
        <Dialog
          fullWidth="md"
          maxWidth="md"
          scroll="body"
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle>Meta Data</DialogTitle>
          <DialogContent dividers>
            <ReactMarkdown source={displayString} children={displayString} />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ProductGrid;
