import { deviceActions, scanActions } from "../actionTypes";

const getDevices = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.device.lastUpdate;
  }
  return dispatch({ type: deviceActions.GET_DEVICES_REQUEST, ...extra });
};
const getDevice = (id) => (dispatch) => dispatch({ type: deviceActions.GET_DEVICE_REQUEST, id });
const putDevice = (id, changes) => (dispatch) => dispatch({ type: deviceActions.PUT_DEVICE_REQUEST, id, ...changes });
const downloadDevices = (device_ids, include_data) => (dispatch) =>
  dispatch({ type: deviceActions.POST_DEVICE__DOWNLOAD_REQUEST, device_ids, include_data });

export { getDevice, getDevices, putDevice, downloadDevices };
