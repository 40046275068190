import { createLogger } from "./logging";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { putSession } from "../data/auth/actions";
import { version } from "./version";

const DEBUG = true;
const debug = createLogger(DEBUG, `logRocketUtils.js`);

const lrStateSanitizer = (state) => {
  debug.log("lrStateSanitizer in", state);
  // If we need to reduce the state, do it here
  return state;
};

const lrActionSanitizer = (action) => {
  debug.log("lrActionSanitizer in", action);
  let blocked = ["POST_SESSION_REQUEST", "POST_SESSION_SUCCESS"];

  if (blocked.includes[action.type]) {
    return { type: action.type };
  } else {
    action.data = {};
    return action;
  }
};

const lrRequestSanitizer = (request) => {
  debug.log("lrRequestSanitizer in", request);
  return request;
};

const lrResponseSanitizer = (response) => {
  debug.log("lrResponseSanitizer in", response);
  return response;
};

const setupLogRocket = (sessionId, org, key, user, dispatch) => {
  debug.enter("logrocket setup");
  debug.log(`Log rocket org ${org}, key ${key}, user ${user?.email}...`);
  if (org && key && user) {
    try {
      let lrConfig = {
        release: version,
        console: {
          isEnabled: {
            log: false,
            debug: false
          }
        },
        network: {
          requestSanitizer: lrRequestSanitizer,
          responseSanitizer: lrResponseSanitizer
        }
      };

      // LogRocket.init(`${org}/${key}`, lrConfig);
      // LogRocket.identify(user._id, {
      //   email: user.email,
      //   name: `${user["first_name"]} ${user["last_name"]}`
      // });
      // setupLogRocketReact(LogRocket);
      // LogRocket.getSessionURL((url) => {
      //   debug.log(`Putting log rocket url into session: ${url}`);
      //   dispatch(putSession(sessionId, { log_rocket_session_url: url }));
      // });
    } catch (err) {
      debug.log(`log rocket error standing up: ${err}`);
    }
  }
};

export { lrActionSanitizer, lrRequestSanitizer, lrStateSanitizer, lrResponseSanitizer, setupLogRocket };
