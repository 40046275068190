import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, Divider, DialogTitle, IconButton, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { putProduct } from "../../../../data/product/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  }
}));

const EditProductForm = (props) => {
  const { data } = props;

  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [authentagLocation, setAuthentagLocation] = useState(data.authentag_location);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleName = (e) => setName(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleAuthentagLocation = (e) => setAuthentagLocation(e.target.value);

  const id = data._id;

  const handleSubmit = (e) => {
    e.preventDefault();

    const editedProduct = {
      name: name,
      description: description,
      authentag_location: authentagLocation
    };

    dispatch(putProduct(id, editedProduct)).then(() => {
      props.setOpenUpdateProductSnackbar(true);
      setName(name);
      setDescription(description);
      setAuthentagLocation(authentagLocation);
      setShowForm(false);
    });
  };

  return (
    <div>
      <Dialog open={showForm} onClose={() => setShowForm(false)} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Update Product
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="name"
            name="name"
            label="Name"
            value={name}
            onChange={handleName}
            type="text"
            required
            fullWidth
            error={name === ""}
            helperText={name === "" ? "Name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="description"
            name="description"
            label="Description"
            value={description}
            onChange={handleDescription}
            type="text"
            required
            fullWidth
            error={description === ""}
            helperText={description === "" ? "Description is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="authentag_location"
            name="authentag_location"
            label="Authentag Location"
            value={authentagLocation}
            onChange={handleAuthentagLocation}
            type="authentagLocation"
            required
            fullWidth
            error={authentagLocation === ""}
            helperText={authentagLocation === "" ? "Authentag Location is required!" : " "}
            className={classes.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={() => setShowForm(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setShowForm(true)}>
        <EditIcon />
      </IconButton>
    </div>
  );
};

export default EditProductForm;
