import { atkitActions } from "../actionTypes";
import { createLogger } from "../../utils/logging";
import { mergeLists, processDependentChanges } from "../../utils/requestUtils";
import { epoch } from "../../utils/dataUtils";

const DEBUG = true;
const debug = createLogger(DEBUG, `atkitReducer.js`);

const initialState = {
  cache: [],
  cacheById: {},
  cacheUpdate: epoch,
  lastUpdate: epoch
};

const atkitReducer = (state = initialState, action) => {
  debug.enter("atkitReducer");
  debug.log(`action(${action.type}) -> `, action);
  let newState = processDependentChanges(action, state, "Atkit");

  switch (action.type) {
    case atkitActions.GET_ATKITS_REQUEST:
      newState = { ...state };
      break;

    case atkitActions.GET_ATKITS_FAILURE:
      newState = { ...state };
      break;

    case atkitActions.GET_ATKITS_SUCCESS:
      newState = { ...state };
      if (action.data.objects.length > 0) {
        newState.cache = mergeLists(state.cache, action.data.objects, newState.cacheById);
        newState.cacheUpdate = new Date();
      } else if (newState.cacheUpdate === epoch) {
        newState.cacheUpdate = new Date();
      }
      newState.lastUpdate = action.data.update_time;
      break;

    default:
      break;
  }
  debug.log("atkitReducer results: ", newState);
  debug.exit("atkitReducer");
  return newState;
};

export default atkitReducer;
