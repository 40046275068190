import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { createLogger } from "../../utils/logging";

const DEBUG = true;
const debug = createLogger(DEBUG, `Layouts.js`);

/** -------- App root layout -------- */
class AppLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    debug.enter(`render`);

    const classes = classnames([`layout`, `app`]);

    return <div className={classes}>{children}</div>;
  }
}

/** -------- Login page layout -------- */
class LoginLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children, width } = this.props;

    debug.enter(`render`);

    const classes = classnames([`layout`, `login`]);

    return (
      <div className={classes} style={{ width }}>
        <main>{children}</main>
      </div>
    );
  }
}

export { AppLayout, LoginLayout };
