import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postProduct } from "../../../../data/product/actions";
import { Button, Dialog, DialogActions, DialogContent, Divider, DialogTitle, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  }
}));

const AddProductForm = ({ isOpen, onClose, organization, setOpenSnackbar }) => {
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [authentagLocation, setAuthentagLocation] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const productPackage = {
      name: name,
      description: description,
      authentag_location: authentagLocation,
      organization_id: organization._id
    };
    dispatch(postProduct(productPackage)).then(() => {
      setOpenSnackbar(true);
      setName(null);
      setDescription(null);
      setAuthentagLocation(null);
      onClose();
    });
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Add New Product
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="name"
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            required
            fullWidth
            error={name === ""}
            helperText={name === "" ? "Name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="description"
            name="description"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            required
            fullWidth
            error={description === ""}
            helperText={description === "" ? "Description is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="authentag_location"
            name="authentag_location"
            label="Authentag Location"
            value={authentagLocation}
            onChange={(e) => setAuthentagLocation(e.target.value)}
            type="text"
            required
            fullWidth
            error={authentagLocation === ""}
            helperText={authentagLocation === "" ? "Authentag Location is required!" : " "}
            className={classes.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddProductForm;
