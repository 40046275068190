import { AUTH_CONFIG } from "./config";
import { createLogger } from "../utils/logging";
import { Redirect } from "react-router-dom";

const DEBUG = true;
const debug = createLogger(DEBUG, `Auth0.js`);

const allowedDomains = ["autags.com", "toosymphony.com"];

class Auth0 {
  config = {
    domain: AUTH_CONFIG.domain,
    clientId: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl
  };

  // If search path contains 'return_path', redirect to that value
  getAppRedirect = (search) => {
    let objPath = "/c2sense";
    let objSearch = "";
    let returnPath = /[?&]return_path=([^&#]+)/.exec(search);
    if (!!returnPath) {
      objPath = returnPath[1];
      if (returnPath[0].length === search.length) objSearch = "";
      else objSearch = search.substr(0, returnPath.index) + search.substr(returnPath.index + returnPath[0].length);
    }
    debug.log(`Auth0: getAppRedirect, now back to ${objPath}`);
    let redirectObj = {
      pathname: objPath,
      search: objSearch
    };
    return redirectObj;
  };

  authRedirect = () => {
    let search = String(window.location.search);
    let path = String(window.location.pathname);
    debug.log(`Auth0: Reauth, then back to ${path}`);
    if (search && search.length > 0) search = `${search}&return_path=${path}`;
    else search = `?return_path=${path}`;

    let routeObj = {
      pathname: "/auth",
      search: search
    };
    return <Redirect to={routeObj} />;
  };

  logoutLink = (client) => {
    let path = `${window.location.origin}/logout`;
    if (client) return `https://${this.config.domain}/v2/logout?client_id=${this.config.clientId}&returnTo=${path}`;
    else return `https://${this.config.domain}/v2/logout?returnTo=${path}`;
  };
}

export { allowedDomains };

export default new Auth0();
