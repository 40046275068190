import { organizationActions } from "../actionTypes";

const getOrganizations = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.organization.lastUpdate;
  }
  return dispatch({ type: organizationActions.GET_ORGANIZATIONS_REQUEST, ...extra });
};
const getOrganization = (id) => (dispatch) => dispatch({ type: organizationActions.GET_ORGANIZATION_REQUEST, id });
const putOrganization = (id, changes) => (dispatch) => dispatch({ type: organizationActions.PUT_ORGANIZATION_REQUEST, id, ...changes });
const postOrganization = (organization) => (dispatch) => dispatch({ type: organizationActions.POST_ORGANIZATION_REQUEST, ...organization });
const removeOrganization = (id) => (dispatch) => dispatch({ type: organizationActions.DELETE_ORGANIZATION_REQUEST, id });

export { getOrganization, getOrganizations, putOrganization, postOrganization, removeOrganization };
