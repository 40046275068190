import React, { Fragment, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const Pie = (props) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie"
    },
    credits: {
      enabled: false
    },
    title: {
      text: props.title
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>"
    },
    accessibility: {
      point: {
        valueSuffix: "%"
      }
    },
    plotOptions: {
      pie: {
        size: "70%",
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y} ({point.percentage:.1f} %)"
        }
      }
    },
    series: [{ name: props.title, colorByPoint: true, data: props.series }]
  });

  return (
    <Fragment>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Fragment>
  );
};
export default Pie;
