import React from "react";
import Loader from "react-loader-spinner";

/*
const loaders = [
  "Audio",
  "Ball-Triangle",
  "Bars",
  "Circles",
  "Grid",
  "Oval",
  "Puff",
  "Rings",
  "TailSpin",
  "ThreeDots",
  "Watch",
  "RevolvingDot",
  "Triangle",
  "Plane",
  "MutatingDot",
];
*/

const SpinLoader = (props) => {
  return (
    <div className="spin-loader">
      <Loader type="Watch" />
    </div>
  );
};

export default SpinLoader;
