import rootReducer from "../data/rootReducer";
import { createStore, compose, applyMiddleware } from "redux";
import { requestMiddleware, loggerMiddleware, crashReporterMiddleware, securityMiddleware, fetchMiddleware } from "../data/middlewares";
import thunkMiddleware from "redux-thunk";
import { lrActionSanitizer, lrStateSanitizer } from "./logRocketUtils";
import LogRocket from "logrocket";

const configureStore = (initialState) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        crashReporterMiddleware,
        requestMiddleware,
        securityMiddleware,
        fetchMiddleware(window.fetch),
        LogRocket.reduxMiddleware({
          stateSanitizer: (state) => lrStateSanitizer(state),
          actionSanitizer: (action) => lrActionSanitizer(action)
        })
      )
    )
  );
};

export default configureStore;
