import lodash from "lodash";
import { success, failure } from "./dataUtils";
import { logErrorMessage } from "../data/misc/actions";
import { isDate } from "date-fns";
import { createLogger } from "./logging";
//import { logErrorMessage } from "../data/misc/actions";
const DEBUG = true;
const debug = createLogger(DEBUG, `middlewareUtils.js`);

// const specialRoutes = (action, args) =>  {
//
//
//
// };
//
// export { specialRoutes };

const findRoute = (base, state, args) => {
  /*
   * The route has some substitutions:
   *   __ means a / separator
   *   starts with a lower case letter means its a argument pulled out of args -- it should be surrounded by __'s
   *
   *  For example ENVIRONMENT__id__VARIABLE turns into /environment/{id}/variable
   *
   */
  debug.log(`findRoute: ${base}`, state, args);
  let parts = base.split("__");
  parts.forEach((part, idx) => {
    if (part[0] === part[0].toLowerCase()) {
      if (part === "id") {
        // the argList might have _id or id ....
        parts[idx] = args.id || args._id;
        if (args.hasOwnProperty("_id")) delete args["_id"];
        if (args.hasOwnProperty("id")) delete args["id"];
      } else {
        parts[idx] = args[part];
        delete args[part];
      }
    } else {
      parts[idx] = parts[idx].toLowerCase();
    }
  });
  let route = parts.join("/");
  debug.log(`Gonna go with ${route}:`, parts);
  return { route, args };
};

const buildActionFromResponse = (request, reqAction) => (body, response) => {
  debug.log(`Creating action from response:`, {
    request,
    body,
    response,
    reqAction
  });
  let action = {
    type: response.ok ? success(request.type, request.method) : failure(request.type, request.method),
    status: response.status,
    data: !!body && (body.data || body),
    response,
    reqAction,
    originatingRequest: request
  };
  postprocessData(action.data);
  debug.log(`  built action ${action.type} for ${response.ok ? "success" : "failure"}`, action);
  return action;
};

const appendFiles = (form, args) => {
  args.files.forEach((item, index) => {
    let filename = Object.keys(args).includes("filenames") ? args["filenames"][index] : `file${index}`;
    form.append("files[]", item, filename);
  });
};

const replacer = (key, value) => {
  if (["_inserted", "_updated"].includes(key) || key.startsWith("__") || value === undefined) return undefined;
  if (isDate(value)) return value.format();
  return value;
};

/*
 * pre and postprocessing for data
 */
const postprocessData = (obj) => {
  // dereference an object that might be dot notated as some of ours are
  if (obj && typeof obj === "object") {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].endsWith("_datetime") || ["_updated", "_inserted", "update_time"].includes(keys[i])) {
        // FIXME: These are coming in without a TZ, but I think they are UTC?
        obj[keys[i]] = new Date(obj[keys[i]]);
      } else if (lodash.isString(obj[keys[i]])) {
        obj[keys[i]] = obj[keys[i]].trim();
      } else if (obj[keys[i]]) {
        postprocessData(obj[keys[i]]);
      }
    }
  }
};

// const preprocessData = (action) => {
//     let newAction = {...action};
//
//     // Strip out __ fields
//     for(let field in newAction) {
//         if(newAction.hasOwnProperty(field)) {
//             if(field.startsWith('__')) {
//                 delete newAction[field];
//             }
//         }
//     }
//     return newAction;
// };

const processFetchError = (store, action, error) => {
  // error response formatting for non-HTTP errors in fetch
  let data = {
    title: "Error in Fetch",
    detail: error.stack,
    error: error.stack
  };
  logError(store, action, data);
  return { data };
};

const logError = (store, action, data) => {
  const error = { title: data.title, detail: data.detail, error: data.error };
  store.dispatch(logErrorMessage({ route: action.request.route, error }));
};

export {
  // preprocessData,
  postprocessData,
  buildActionFromResponse,
  appendFiles,
  findRoute,
  replacer,
  processFetchError,
  logError
};
