import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, Divider, DialogTitle, IconButton, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { putExperiment } from "../../../../data/experiment/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  },
  markdown: {
    display: "flex",
    flexDirection: "row"
  },
  markdownFields: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    },
    width: "50%",
    maxWidth: "100%",
    fontSize: "1rem"
  },
  configTitle: {
    marginTop: "30px"
  },
  divider: {
    background: "#9E9E9E"
  }
}));

const EditExperimentForm = (props) => {
  const { data } = props;

  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [notes, setNotes] = useState(data.notes);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleName = (e) => setName(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleNotes = (e) => setNotes(e.target.value);

  const id = data._id;

  const handleSubmit = (e) => {
    e.preventDefault();

    const editedExperiment = {
      name: name,
      description: description,
      notes: notes
    };

    dispatch(putExperiment(id, editedExperiment)).then(() => {
      props.setOpenUpdateExperimentSnackbar(true);
      setName(name);
      setDescription(description);
      setNotes(notes);
      setShowForm(false);
    });
  };

  return (
    <div>
      <Dialog open={showForm} onClose={() => setShowForm(false)} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Update Experiment
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="name"
            name="name"
            label="Name"
            value={name}
            onChange={handleName}
            type="text"
            required
            fullWidth
            error={name === ""}
            helperText={name === "" ? "Name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="description"
            name="description"
            label="Description"
            value={description}
            onChange={handleDescription}
            type="text"
            required
            fullWidth
            error={description === ""}
            helperText={description === "" ? "Description is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="notes"
            name="notes"
            multiline
            label="Notes"
            value={notes}
            onChange={handleNotes}
            type="text"
            fullWidth
            className={classes.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={() => setShowForm(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setShowForm(true)}>
        <EditIcon />
      </IconButton>
    </div>
  );
};

export default EditExperimentForm;
