import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postAuthentag } from "../../../../data/authentag/actions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  DialogTitle,
  TextField,
  Select,
  InputLabel,
  Snackbar,
  MenuItem,
  Checkbox,
  ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactMarkdown from "react-markdown";
import { atKitVersion } from "../../../../utils/version";
import lodash from "lodash";
import { createLogger } from "../../../../utils/logging";

const DEBUG = true;
const debug = createLogger(DEBUG, `AddAuthentagForm.js`);

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  },
  markdown: {
    display: "flex",
    flexDirection: "row"
  },
  markdownFields: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    },
    width: "50%",
    maxWidth: "100%",
    fontSize: "1rem"
  },
  configTitle: {
    marginTop: "30px"
  },
  divider: {
    background: "#9E9E9E"
  }
}));

const AddAuthentagForm = ({ isOpen, onClose, organization, setOpenSnackbar, setErrorOpenSnackbar }) => {
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [notes, setNotes] = useState(null);
  const [supportedVersions, setSupportedVersions] = useState([atKitVersion]);
  const atkitVersions = useSelector((state) => state.atkit.cache);
  const state = useSelector((state) => state);

  debug.log("state: --> ", state);
  debug.log("ADDAUTHENTAGFORM: --> ", atkitVersions);

  const bare = JSON.stringify(
    {
      intensityThreshold: 0,
      isBarcodeTag: false,
      name: "Tag/Recipe Name",
      regionWidth: 0.0,
      delayedColor: "red",
      invertDecision: false,
      promptColor: "blue",
      videoSettings: {
        ISO: 0,
        blueGain: 0,
        dutyCycle: 0,
        exposureTime: 0.0,
        focusLevel: 0,
        greenGain: 0,
        redGain: 0,
        strobeFreq: 0
      }
    },
    null,
    2
  );
  const [config, setConfig] = useState(bare);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleConfig = (e) => setConfig(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    let backtick = config
      .replace(/^\s+|\s+$/gm, "")
      .split("\n")
      .join("");
    let woBacktick = backtick.replace(/`/g, "");
    let reversed;

    try {
      reversed = JSON.parse(woBacktick);
    } catch (err) {
      setErrorOpenSnackbar(err);
      return;
    }

    const authentagPackage = {
      name: name,
      description: description,
      notes: notes,
      organization_id: organization._id,
      config: reversed,
      supported_versions: supportedVersions
    };

    dispatch(postAuthentag(authentagPackage)).then(() => {
      setOpenSnackbar(true);
      setName(null);
      setDescription(null);
      setNotes(null);
      setConfig(`${bare}`);
      setSupportedVersions([atKitVersion]);
      onClose();
    });
  };
  /*
      <Select
              multiple
              variant="outlined"
              margin="dense"
              key="supported_versions"
              name="supported_versions"
              label="Supported AtKit Versions"
              value={supportedVersions}
              renderValue={(selected) => selected.join(', ')}
              onChange={(e) => setSupportedVersions(e.target.value)}
              fullWidth
              className={classes.input}
              error={lodash.isEmpty(supportedVersions)}
              helperText={lodash.isEmpty(supportedVersions) ? "At least one version is required!" : " "}
          >
            {atkitVersions.map((atkit) => (
                <MenuItem key={atkit.name} value={atkit.name}>
                  <Checkbox checked={supportedVersions.includes(atkit.name)} />
                  <ListItemText primary={`Version ${atkit.name}`} />
                </MenuItem>
            ))}
          </Select>
   */

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Add New Authentag
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="name"
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            required
            fullWidth
            className={classes.input}
            error={name === ""}
            helperText={name === "" ? "Name is required!" : " "}
          />
          <TextField
            margin="dense"
            key="description"
            name="description"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            required
            fullWidth
            className={classes.input}
            error={description === ""}
            helperText={description === "" ? "Description is required!" : " "}
          />
          <TextField
            margin="dense"
            key="notes"
            name="notes"
            multiline
            label="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            type="text"
            required
            fullWidth
            className={classes.input}
            error={notes === ""}
            helperText={notes === "" ? "Notes are required!" : " "}
          />
          <TextField
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => selected.join(", ")
            }}
            multiple
            variant="outlined"
            margin="dense"
            key="supported_versions"
            name="supported_versions"
            label="Supported AtKit Versions"
            value={supportedVersions}
            onChange={(e) => setSupportedVersions(e.target.value)}
            fullWidth
            className={classes.input}
            error={lodash.isEmpty(supportedVersions)}
            helperText={lodash.isEmpty(supportedVersions) ? "At least one version is required!" : " "}>
            {atkitVersions.map((atkit) => (
              <MenuItem key={atkit.name} value={atkit.name}>
                <Checkbox checked={supportedVersions.includes(atkit.name)} />
                <ListItemText primary={`Version ${atkit.name}`} />
              </MenuItem>
            ))}
          </TextField>
          <InputLabel shrink={true} className={classes.configTitle}>
            Config Edit
          </InputLabel>
          <Divider className={classes.divider} />
          <div className={classes.markdown}>
            <TextField
              margin="dense"
              key="config"
              name="config"
              multiline
              rows={6}
              rowsMax={50}
              value={config}
              onChange={handleConfig}
              type="text"
              fullWidth
              className={classes.markdownFields}
            />
            <ReactMarkdown children={config} source={config} onChange={handleConfig} value={config} className={classes.markdownFields} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAuthentagForm;
