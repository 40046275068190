const authActions = {
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_FAILURE: "AUTH_FAILURE",

  USER_REQUEST: "USER_REQUEST",
  USER_SUCCESS: "USER_SUCCESS",
  USER_FAILURE: "USER_FAILURE",

  POST_SESSION_REQUEST: "POST_SESSION_REQUEST",
  POST_SESSION_SUCCESS: "POST_SESSION_SUCCESS",
  POST_SESSION_FAILURE: "POST_SESSION_FAILURE",

  PUT_SESSION_REQUEST: "PUT_SESSION_REQUEST",
  PUT_SESSION_SUCCESS: "PUT_SESSION_SUCCESS",
  PUT_SESSION_FAILURE: "PUT_SESSION_FAILURE",

  SET_ID_TOKEN: "SET_ID_TOKEN",
  CLEAR_AUTH: "CLEAR_AUTH"
};

const miscActions = {
  LOG_ERROR: "LOG_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR"
};

const userActions = {
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  POST_USER_REQUEST: "POST_USER_REQUEST",
  POST_USER_SUCCESS: "POST_USER_SUCCESS",
  POST_USER_FAILURE: "POST_USER_FAILURE",

  PUT_USER_REQUEST: "PUT_USER_REQUEST",
  PUT_USER_SUCCESS: "PUT_USER_SUCCESS",
  PUT_USER_FAILURE: "PUT_USER_FAILURE",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE"
};

const organizationActions = {
  GET_ORGANIZATIONS_REQUEST: "GET_ORGANIZATIONS_REQUEST",
  GET_ORGANIZATIONS_SUCCESS: "GET_ORGANIZATIONS_SUCCESS",
  GET_ORGANIZATIONS_FAILURE: "GET_ORGANIZATIONS_FAILURE",

  GET_ORGANIZATION_REQUEST: "GET_ORGANIZATION_REQUEST",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILURE: "GET_ORGANIZATION_FAILURE",

  POST_ORGANIZATION_REQUEST: "POST_ORGANIZATION_REQUEST",
  POST_ORGANIZATION_SUCCESS: "POST_ORGANIZATION_SUCCESS",
  POST_ORGANIZATION_FAILURE: "POST_ORGANIZATION_FAILURE",

  PUT_ORGANIZATION_REQUEST: "PUT_ORGANIZATION_REQUEST",
  PUT_ORGANIZATION_SUCCESS: "PUT_ORGANIZATION_SUCCESS",
  PUT_ORGANIZATION_FAILURE: "PUT_ORGANIZATION_FAILURE",

  DELETE_ORGANIZATION_REQUEST: "DELETE_ORGANIZATION_REQUEST",
  DELETE_ORGANIZATION_SUCCESS: "DELETE_ORGANIZATION_SUCCESS",
  DELETE_ORGANIZATION_FAILURE: "DELETE_ORGANIZATION_FAILURE"
};

const experimentActions = {
  GET_EXPERIMENTS_REQUEST: "GET_EXPERIMENTS_REQUEST",
  GET_EXPERIMENTS_SUCCESS: "GET_EXPERIMENTS_SUCCESS",
  GET_EXPERIMENTS_FAILURE: "GET_EXPERIMENTS_FAILURE",

  GET_EXPERIMENT_REQUEST: "GET_EXPERIMENT_REQUEST",
  GET_EXPERIMENT_SUCCESS: "GET_EXPERIMENT_SUCCESS",
  GET_EXPERIMENT_FAILURE: "GET_EXPERIMENT_FAILURE",

  POST_EXPERIMENT_REQUEST: "POST_EXPERIMENT_REQUEST",
  POST_EXPERIMENT_SUCCESS: "POST_EXPERIMENT_SUCCESS",
  POST_EXPERIMENT_FAILURE: "POST_EXPERIMENT_FAILURE",

  PUT_EXPERIMENT_REQUEST: "PUT_EXPERIMENT_REQUEST",
  PUT_EXPERIMENT_SUCCESS: "PUT_EXPERIMENT_SUCCESS",
  PUT_EXPERIMENT_FAILURE: "PUT_EXPERIMENT_FAILURE",

  DELETE_EXPERIMENT_REQUEST: "DELETE_EXPERIMENT_REQUEST",
  DELETE_EXPERIMENT_SUCCESS: "DELETE_EXPERIMENT_SUCCESS",
  DELETE_EXPERIMENT_FAILURE: "DELETE_EXPERIMENT_FAILURE"
};

const productActions = {
  GET_PRODUCTS_REQUEST: "GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",

  POST_PRODUCT_REQUEST: "POST_PRODUCT_REQUEST",
  POST_PRODUCT_SUCCESS: "POST_PRODUCT_SUCCESS",
  POST_PRODUCT_FAILURE: "POST_PRODUCT_FAILURE",

  PUT_PRODUCT_REQUEST: "PUT_PRODUCT_REQUEST",
  PUT_PRODUCT_SUCCESS: "PUT_PRODUCT_SUCCESS",
  PUT_PRODUCT_FAILURE: "PUT_PRODUCT_FAILURE",

  DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE"
};

const demoProductActions = {
  GET_DEMO_PRODUCTS_REQUEST: "GET_DEMO_PRODUCTS_REQUEST",
  GET_DEMO_PRODUCTS_SUCCESS: "GET_DEMO_PRODUCTS_SUCCESS",
  GET_DEMO_PRODUCTS_FAILURE: "GET_DEMO_PRODUCTS_FAILURE",

  GET_DEMO_PRODUCT_REQUEST: "GET_DEMO_PRODUCT_REQUEST",
  GET_DEMO_PRODUCT_SUCCESS: "GET_DEMO_PRODUCT_SUCCESS",
  GET_DEMO_PRODUCT_FAILURE: "GET_DEMO_PRODUCT_FAILURE",

  POST_DEMO_PRODUCT_REQUEST: "POST_DEMO_PRODUCT_REQUEST",
  POST_DEMO_PRODUCT_SUCCESS: "POST_DEMO_PRODUCT_SUCCESS",
  POST_DEMO_PRODUCT_FAILURE: "POST_DEMO_PRODUCT_FAILURE",

  PUT_DEMO_PRODUCT_REQUEST: "PUT_DEMO_PRODUCT_REQUEST",
  PUT_DEMO_PRODUCT_SUCCESS: "PUT_DEMO_PRODUCT_SUCCESS",
  PUT_DEMO_PRODUCT_FAILURE: "PUT_DEMO_PRODUCT_FAILURE",

  DELETE_DEMO_PRODUCT_REQUEST: "DELETE_DEMO_PRODUCT_REQUEST",
  DELETE_DEMO_PRODUCT_SUCCESS: "DELETE_DEMO_PRODUCT_SUCCESS",
  DELETE_DEMO_PRODUCT_FAILURE: "DELETE_DEMO_PRODUCT_FAILURE"
};

const scanActions = {
  GET_SCANS_REQUEST: "GET_SCANS_REQUEST",
  GET_SCANS_SUCCESS: "GET_SCANS_SUCCESS",
  GET_SCANS_FAILURE: "GET_SCANS_FAILURE",

  GET_SCAN_REQUEST: "GET_SCAN_REQUEST",
  GET_SCAN_SUCCESS: "GET_SCAN_SUCCESS",
  GET_SCAN_FAILURE: "GET_SCAN_FAILURE",

  POST_SCAN_REQUEST: "POST_SCAN_REQUEST",
  POST_SCAN_SUCCESS: "POST_SCAN_SUCCESS",
  POST_SCAN_FAILURE: "POST_SCAN_FAILURE",

  PUT_SCAN_REQUEST: "PUT_SCAN_REQUEST",
  PUT_SCAN_SUCCESS: "PUT_SCAN_SUCCESS",
  PUT_SCAN_FAILURE: "PUT_SCAN_FAILURE",

  GET_SCAN__DOWNLOAD_REQUEST: "GET_SCAN__DOWNLOAD_REQUEST",
  GET_SCAN__DOWNLOAD_SUCCESS: "GET_SCAN__DOWNLOAD_SUCCESS",
  GET_SCAN__DOWNLOAD_FAILURE: "GET_SCAN__DOWNLOAD_FAILURE",

  POST_SCAN__DOWNLOAD_REQUEST: "POST_SCAN__DOWNLOAD_REQUEST",
  POST_SCAN__DOWNLOAD_SUCCESS: "POST_SCAN__DOWNLOAD_SUCCESS",
  POST_SCAN__DOWNLOAD_FAILURE: "POST_SCAN__DOWNLOAD_FAILURE",

  DELETE_SCAN_REQUEST: "DELETE_SCAN_REQUEST",
  DELETE_SCAN_SUCCESS: "DELETE_SCAN_SUCCESS",
  DELETE_SCAN_FAILURE: "DELETE_SCAN_FAILURE"
};

const rbacActions = {
  GET_RBACS_REQUEST: "GET_RBACS_REQUEST",
  GET_RBACS_SUCCESS: "GET_RBACS_SUCCESS",
  GET_RBACS_FAILURE: "GET_RBACS_FAILURE",

  GET_RBAC_REQUEST: "GET_RBAC_REQUEST",
  GET_RBAC_SUCCESS: "GET_RBAC_SUCCESS",
  GET_RBAC_FAILURE: "GET_RBAC_FAILURE",

  POST_RBAC_REQUEST: "POST_RBAC_REQUEST",
  POST_RBAC_SUCCESS: "POST_RBAC_SUCCESS",
  POST_RBAC_FAILURE: "POST_RBAC_FAILURE",

  PUT_RBAC_REQUEST: "PUT_RBAC_REQUEST",
  PUT_RBAC_SUCCESS: "PUT_RBAC_SUCCESS",
  PUT_RBAC_FAILURE: "PUT_RBAC_FAILURE",

  DELETE_RBAC_REQUEST: "DELETE_RBAC_REQUEST",
  DELETE_RBAC_SUCCESS: "DELETE_RBAC_SUCCESS",
  DELETE_RBAC_FAILURE: "DELETE_RBAC_FAILURE"
};

const authentagActions = {
  GET_AUTHENTAGS_REQUEST: "GET_AUTHENTAGS_REQUEST",
  GET_AUTHENTAGS_SUCCESS: "GET_AUTHENTAGS_SUCCESS",
  GET_AUTHENTAGS_FAILURE: "GET_AUTHENTAGS_FAILURE",

  GET_AUTHENTAG_REQUEST: "GET_AUTHENTAG_REQUEST",
  GET_AUTHENTAG_SUCCESS: "GET_AUTHENTAG_SUCCESS",
  GET_AUTHENTAG_FAILURE: "GET_AUTHENTAG_FAILURE",

  POST_AUTHENTAG_REQUEST: "POST_AUTHENTAG_REQUEST",
  POST_AUTHENTAG_SUCCESS: "POST_AUTHENTAG_SUCCESS",
  POST_AUTHENTAG_FAILURE: "POST_AUTHENTAG_FAILURE",

  PUT_AUTHENTAG_REQUEST: "PUT_AUTHENTAG_REQUEST",
  PUT_AUTHENTAG_SUCCESS: "PUT_AUTHENTAG_SUCCESS",
  PUT_AUTHENTAG_FAILURE: "PUT_AUTHENTAG_FAILURE",

  DELETE_AUTHENTAG_REQUEST: "DELETE_AUTHENTAG_REQUEST",
  DELETE_AUTHENTAG_SUCCESS: "DELETE_AUTHENTAG_SUCCESS",
  DELETE_AUTHENTAG_FAILURE: "DELETE_AUTHENTAG_FAILURE"
};

const dashboardActions = {
  GET_DASHBOARD__USERS_REQUEST: "GET_DASHBOARD__USERS_REQUEST",
  GET_DASHBOARD__USERS_SUCCESS: "GET_DASHBOARD__USERS_SUCCESS",
  GET_DASHBOARD__USERS_FAILURE: "GET_DASHBOARD__USERS_FAILURE",

  GET_DASHBOARD__ORGANIZATIONS_REQUEST: "GET_DASHBOARD__ORGANIZATIONS_REQUEST",
  GET_DASHBOARD__ORGANIZATIONS_SUCCESS: "GET_DASHBOARD__ORGANIZATIONS_SUCCESS",
  GET_DASHBOARD__ORGANIZATIONS_FAILURE: "GET_DASHBOARD__ORGANIZATIONS_FAILURE",

  GET_DASHBOARD__SESSIONS_REQUEST: "GET_DASHBOARD__SESSIONS_REQUEST",
  GET_DASHBOARD__SESSIONS_SUCCESS: "GET_DASHBOARD__SESSIONS_SUCCESS",
  GET_DASHBOARD__SESSIONS_FAILURE: "GET_DASHBOARD__SESSIONS_FAILURE"
};

const atkitActions = {
  GET_ATKITS_REQUEST: "GET_ATKITS_REQUEST",
  GET_ATKITS_SUCCESS: "GET_ATKITS_SUCCESS",
  GET_ATKITS_FAILURE: "GET_ATKITS_FAILURE"
};

const deviceActions = {
  GET_DEVICES_REQUEST: "GET_DEVICES_REQUEST",
  GET_DEVICES_SUCCESS: "GET_DEVICES_SUCCESS",
  GET_DEVICES_FAILURE: "GET_DEVICES_FAILURE",

  GET_DEVICE_REQUEST: "GET_DEVICE_REQUEST",
  GET_DEVICE_SUCCESS: "GET_DEVICE_SUCCESS",
  GET_DEVICE_FAILURE: "GET_DEVICE_FAILURE",

  PUT_DEVICE_REQUEST: "PUT_DEVICE_REQUEST",
  PUT_DEVICE_SUCCESS: "PUT_DEVICE_SUCCESS",
  PUT_DEVICE_FAILURE: "PUT_DEVICE_FAILURE",

  GET_DEVICE__DOWNLOAD_REQUEST: "GET_DEVICE__DOWNLOAD_REQUEST",
  GET_DEVICE__DOWNLOAD_SUCCESS: "GET_DEVICE__DOWNLOAD_SUCCESS",
  GET_DEVICE__DOWNLOAD_FAILURE: "GET_DEVICE__DOWNLOAD_FAILURE",

  POST_DEVICE__DOWNLOAD_REQUEST: "POST_DEVICE__DOWNLOAD_REQUEST",
  POST_DEVICE__DOWNLOAD_SUCCESS: "POST_DEVICE__DOWNLOAD_SUCCESS",
  POST_DEVICE__DOWNLOAD_FAILURE: "POST_DEVICE__DOWNLOAD_FAILURE"
};

const requestActions = {
  SET_NOTIFICATION_SOCKET: "SET_NOTIFICATION_SOCKET"
};

let actions = {
  ...authActions,
  ...miscActions,
  ...userActions,
  ...organizationActions,
  ...experimentActions,
  ...productActions,
  ...demoProductActions,
  ...scanActions,
  ...rbacActions,
  ...authentagActions,
  ...dashboardActions,
  ...deviceActions,
  ...requestActions
};

/*
 * This file is kinda manual so that everywhere else the actions are easy to recognize. Make sure you didn't screw
 * up the cut-n-paste.
 */
for (let action in actions) {
  if (actions.hasOwnProperty(action)) {
    if (action !== actions[action]) {
      console.log(`ACTION TYPE PROBLEM: ${action} != ${actions[action]} ~~~~~~~~~~~~~~~~~~~~~ `);
    }
  }
}

export {
  authActions,
  miscActions,
  userActions,
  organizationActions,
  actions,
  experimentActions,
  productActions,
  demoProductActions,
  scanActions,
  rbacActions,
  authentagActions,
  dashboardActions,
  deviceActions,
  requestActions,
  atkitActions
};
