import React, { useState } from "react";
import { Button, Snackbar } from "@material-ui/core";
import AddProductForm from "./AddProductForm";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  snackbar: {
    margin: 0,
    top: "auto",
    right: 0,
    bottom: 40,
    left: "auto",
    position: "fixed"
  }
}));

const ProductActions = ({ organization }) => {
  const [showForm, setShowForm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="users-grid-actions">
      <AddProductForm isOpen={showForm} setOpenSnackbar={setOpenSnackbar} onClose={() => setShowForm(false)} organization={organization} />
      <Button size="small" id="productgrid-addproductbutton" onClick={() => setShowForm(true)} style={{ marginRight: "10px" }}>
        Add Product
      </Button>
      <Snackbar className={classes.snackbar} open={openSnackbar} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Product Successfully Created!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ProductActions;
