///////////////////////////////////////////////////////////////////////////////
/////*** defaultGridConfig ***/////
///////////////////////////////////////////////////////////////////////////////

import lodash from "lodash";

const defaultGridConfig = {
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    // By default all columns get the search and filter tab only
    menuTabs: ["filterMenuTab"],
    icons: {
      menu: "<span class='material-icons'>search</span>",
      filter: "<span class='material-icons'>search</span>"
    }
  },
  rowSelection: "single",
  getRowNodeId: (data) => data._id,
  deltaRowDataMode: true
  // onColumnVisible: function (params) {
  //   params.api.sizeColumnsToFit();
  // },
  // onGridSizeChanged: function (params) {
  //   params.api.sizeColumnsToFit();
  // },
  // onRowGroupOpened: function (params) {
  //   params.api.sizeColumnsToFit();
  // }
};

// This function looks at the spec'd grid component and checks to
// see if the column customizations have changed since it was
// mapped.  If they were changed, then return a new version of
// user_options that the caller should use to update the User
// record.  Return null if nothing was changed.
const getGridUpdate = (grid) => {
  const { thisUser } = grid.props;
  if (thisUser == null) return null;
  if (grid.state.grid_id == null) return null;
  let api = grid.gridColumnApi;
  if (typeof api === "undefined") return null;

  // if column config has not changed, do not save
  let newConfig = lodash.cloneDeep(grid.gridColumnApi.getColumnState());
  if (lodash.isEqual(newConfig, grid.readyCustoms)) return null;

  let newUser = { ...thisUser };
  if (typeof newUser.user_options === "undefined") newUser.user_options = {};
  if (typeof newUser.user_options.grid_customizations === "undefined") newUser.user_options.grid_customizations = {};

  // Ensure the config and menu columns are visible on the right
  const restoreColumn = (index) => {
    let column = newConfig.splice(index, 1)[0];
    column["hide"] = false;
    newConfig.push(column);
  };
  const configIndex = newConfig.findIndex((x) => x.colId === "config");
  if (configIndex > -1) restoreColumn(configIndex);
  const menuIndex = newConfig.findIndex((x) => x.colId === "menu");
  if (menuIndex > -1) restoreColumn(menuIndex);

  newConfig.forEach((col) => delete col["width"]);

  newUser.user_options.grid_customizations[grid.state.grid_id] = newConfig;
  return newUser.user_options;
};

// Common version of the gridReady callback used to load column customizations
// from storage in the User record.

///////////////////////////////////////////////////////////////////////////////
/////*** exports ***/////
///////////////////////////////////////////////////////////////////////////////
export { defaultGridConfig, getGridUpdate };
