import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeAuthentag } from "../../../../data/authentag/actions";
import ArchiveIcon from "@material-ui/icons/ArchiveSharp";
import { IconButton } from "@material-ui/core";
import ConfirmDialog from "../../../utils/ConfirmDialog";

const DeleteAuthentagRow = (props) => {
  const { data } = props;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteRow = () => {
    dispatch(removeAuthentag(data._id)).then(() => {
      props.setOpenDeleteAuthentagSnackbar(true);
    });
  };
  if (data.archived) return <div>Archived</div>;

  return (
    <div>
      <IconButton onClick={() => setConfirmOpen(true)}>
        <ArchiveIcon />
      </IconButton>
      <ConfirmDialog title="Archive this Authentag?" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteRow}>
        <div style={{ color: "red" }}>Are you sure you want to delete this Authentag?</div>
        <div style={{ color: "red", marginBottom: 10, marginTop: 10 }}>
          This action cannot be undone and will archive all experiments and scans using this Authentag recipe.
        </div>
        <div>
          The users will no longer see this Authentag in the recipe list when creating new experiments. They will not be able to create new
          scans for any experiment using this Authentag. However, they will continue to see the experiments and scans in the pilot app, but
          it will be marked as archived.{" "}
        </div>
      </ConfirmDialog>
    </div>
  );
};

export default DeleteAuthentagRow;
