import { format } from "date-fns";
import { createLogger } from "./logging";
import lodash from "lodash";

const DEBUG = true;
const debug = createLogger(DEBUG, `utils.js`);

const capitalize = (arg) => (lodash.isString(arg) ? arg[0].toUpperCase() + arg.slice(1) : arg);
const objArray = (obj) => Object.entries(obj);

function Map(object) {
  this.prototype = Object.prototype;
  this.prototype.constructor = Map;
  for (let prop in object) {
    if (object.hasOwnProperty(prop)) {
      this[prop] = object[prop];
    }
  }
  this.prototype.forin = function (callback) {
    let index = 0;
    for (let prop in this) {
      if (this.hasOwnProperty(prop)) {
        callback(prop, this[prop], index++, this);
      }
    }
  };
  this.prototype.map = function (callback) {
    let map = {};
    let index = 0;
    for (let prop in this) {
      if (this.hasOwnProperty(prop)) {
        map[prop] = callback(prop, this[prop], index++, this);
      }
    }
    return map;
  };
}

/*
 *  Set a complex object setting in obj with a field name that may be dot format:
 *
 *  dotSet({a: 1, b: 2, c: { d: { e: 9}}}, "c.d.e", 4)
 *  > {a: 1, b: 2, c: { d: { e: 4 }}}
 *  dotSet({a: 1, b: 2, c: { d: { e: 9}}}, "z.y", 9)
 *  > {a: 1, b: 2, c: { d: { e: 4 }}, z: { y: 9 }}
 *  dotSet({a: 1, b: 2, c: { d: { e: 9}}}, "a", 10)
 *  > {a: 10, b: 2, c: { d: { e: 9}}}
 *
 */
function dotSet(obj, field, value) {
  let pieces = field.split(".");
  let target = obj;
  for (var index = 0; index < pieces.length; index++) {
    if (index === pieces.length - 1) {
      target[pieces[index]] = value;
      return obj;
    } else if (target.hasOwnProperty(pieces[index])) {
      if (typeof target[pieces[index]] !== "object") {
        target[pieces[index]] = {};
        target = target[pieces[index]];
      } else {
        target = target[pieces[index]];
      }
    } else {
      target[pieces[index]] = {};
      target = target[pieces[index]];
    }
  }
  return obj;
}

/*
 * dereference an object with field, where field may have dot references.
 * dotReference({a: 1, b: 2, c: { d: { e: 9}}}, "c.d.e")
 * > 9
 * dotReference({a: 1, b: 2, c: { d: { e: 9}}}, "a")
 * > 1
 * dotReference({a: 1, b: 2, c: { d: { e: 9}}}, "c.d.q")
 * > undefined
 */
function dotReference(obj, field, defaultValue) {
  //debug.log(`dotReferece: ${field} in `, obj);
  // dereference an object that might be dot notated as some of ours are
  if (obj === undefined || obj === null) {
    return defaultValue;
  }

  if (typeof field === "string") {
    var keys = field.split(".");
    for (var i = 0; i < keys.length; i++) {
      if (keys[i] in obj) {
        obj = obj[keys[i]];
      } else {
        obj = undefined;
        break;
      }
      if (typeof obj === "number" || typeof obj === "string") {
        if (i !== keys.length - 1) {
          return defaultValue;
        }
      }
    }
    return obj === undefined ? defaultValue : obj;
  } else return obj[field];
}

function _sorter(field) {
  var order = field[0] === "-" ? -1 : 1;
  if (order === -1) {
    field = field.substr(1);
  }

  return function (a, b) {
    let aval = dotReference(a, field),
      bval = dotReference(b, field);

    return (aval < bval ? -1 : aval > bval ? 1 : 0) * order;
  };
}

function fieldSorter() {
  /*
   * Save the arguments object before its overwritten
   */
  var fieldNames = arguments;
  return function (a, b) {
    var index = 0,
      result = 0;

    while (result === 0 && index < fieldNames.length) {
      result = _sorter(fieldNames[index++])(a, b);
    }
    return result;
  };
}

function isObjectId(oid) {
  return oid.length === 24 && !isNaN(parseInt(oid, 16));
}

function isEmpty(field) {
  return field === undefined || field === null || field === "";
}

const updateWindowDimensions = (owner) => () => {
  debug.log(`updateWindowDimensions: ${window.innerHeight}`, this);
  owner.setState({ width: window.innerWidth, height: window.innerHeight });
};

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const makeKeysCamelCase = (object) => {
  const newObject = lodash.cloneDeep(object);
  for (const [key, value] of Object.entries(object)) {
    let newKey = key.replace(/_([a-zA-Z])/g, ($0, $1) => $1.toUpperCase());
    newObject[newKey] = value;
    if (newKey !== key) delete newObject[key];
  }
  return newObject;
};

const makeKeysSnakeCase = (object) => {
  const newObject = lodash.cloneDeep(object);
  for (const [key, value] of Object.entries(object)) {
    let newKey = key.replace(/([A-Z])/g, ($1) => "_" + $1.toLowerCase());
    newObject[newKey] = value;
    if (newKey !== key) delete newObject[key];
  }
  return newObject;
};

const download = (url, method, body, headers, filename) => {
  fetch(url, { method, body: JSON.stringify(body), headers })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      try {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        debug.log(`ERROR: ${e}`);
      }
    });
};

export {
  capitalize,
  objArray,
  Map,
  dotReference,
  dotSet,
  fieldSorter,
  isObjectId,
  updateWindowDimensions,
  makeKeysCamelCase,
  makeKeysSnakeCase,
  download
};
