import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { createLogger } from "../../utils/logging";
import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { defaultGridConfig } from "./config/defaultGridConfig";
import { Typography, Button, Dialog, DialogTitle, DialogContent, Switch, FormControlLabel } from "@material-ui/core";
import Auth0 from "../../auth/Auth0";
import { useAuth0 } from "@auth0/auth0-react";
import ConfigData from "./cellComponents/authentag/ConfigData";
import ReactMarkdown from "react-markdown";
import _ from "lodash";

const DEBUG = true;
const debug = createLogger(DEBUG, `AuthentagGrid.js`);

const AuthentagGrid = () => {
  const { isAuthenticated } = useAuth0();
  const organizations = useSelector((state) => state.organization.cacheById);
  const authentags = useSelector((state) =>
    _.filter(state.authentag.cache, {
      archived: false
    })
  );
  const allAuthentags = useSelector((state) => state.authentag.cache);

  // const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [configData, setConfigData] = useState(null);

  debug.log("AuthentagGrid render()");

  const handleClose = () => {
    setOpen(false);
  };

  const rowData = useMemo(() => {
    let sortedArr = [];

    Object.keys(organizations).forEach((orgId) => {
      let authentagsList = showArchived
        ? allAuthentags.filter((authentag) => authentag.organization_id === orgId)
        : authentags.filter((authentag) => authentag.organization_id === orgId);
      let newAuthentagList = [];

      authentagsList.forEach((authentag) => {
        let newAuthentag = { ...authentag };

        newAuthentag["orgName"] = organizations[orgId].name;
        newAuthentag["date"] = newAuthentag._inserted ? newAuthentag._inserted.toDateString() : "";
        newAuthentag["config"] = newAuthentag.config ? JSON.stringify(newAuthentag.config, null, 2) : "No Config Data Available";

        newAuthentagList.push(newAuthentag);
      });
      sortedArr.push(...newAuthentagList);
    });
    return sortedArr;
  }, [authentags, organizations]);

  const displayString = configData ? ` \`\`\`${configData}` : "No Config Available";

  const columnDefs = [
    { headerName: "Organization", field: "orgName", rowGroup: true, hide: true },
    { headerName: "Authentag", field: "name", tooltipField: "name" },
    { headerName: "ID", field: "_id", hide: true },
    {
      headerName: "Config",
      field: "config",
      cellRendererFramework: ConfigData,
      width: 40,
      onCellClicked: (params) => {
        if (params.data) {
          setConfigData(params.value);
          setOpen(true);
        }
      }
    },
    { headerName: "Description", field: "description", tooltipField: "description" },
    { headerName: "Created", field: "date", tooltipField: "date" },
    { headerName: "Notes", field: "notes", tooltipField: "notes" },
    {
      headerName: "Archived",
      field: "archived",
      valueGetter: (params) => {
        if (!params.data || !params.data.archived) return;
        else return "Yes";
      }
    }
  ];

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const gridConfig = {
    ...defaultGridConfig,
    onGridReady,
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: true,
      filter: true,
      resizable: true
    },
    autoGroupColumnDef: {
      minWidth: 200,
      cellRenderer: "agGroupCellRenderer"
    },
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
    groupMultiAutoColumn: true,
    enableRangeSelection: true,
    animateRows: true,
    rowData: rowData,
    columnDefs: columnDefs,
    getRowNodeId: (data) => data._id,
    rowSelection: "multiple",
    groupSelectsChildren: true,
    alwaysShowVerticalScroll: true,
    suppressHorizontalScroll: false,
    scrollbarWidth: 10,
    rowHeight: 50
  };

  const exportToExcel = () => {
    gridApi.exportDataAsExcel();
  };

  const toggleArchivedScans = () => {
    setShowArchived(!showArchived);
  };

  if (isAuthenticated) {
    return (
      <div style={{ width: "100%", height: 600 }}>
        <Typography gutterBottom variant="h4" component="h2">
          Authentags
        </Typography>

        <FormControlLabel control={<Switch onChange={toggleArchivedScans} checked={showArchived} />} label="Show Archived" />

        <div
          id="authentagGrid"
          style={{
            height: "100%",
            width: "100%"
          }}
          className="ag-theme-alpine">
          <AgGridReact {...gridConfig} />
          <Dialog
            fullWidth="md"
            maxWidth="md"
            scroll="body"
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle>Config</DialogTitle>
            <DialogContent dividers>
              <ReactMarkdown source={displayString} children={displayString} />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  } else {
    debug.log("Reauthenticating...");
    return Auth0.authRedirect();
  }
};

export default AuthentagGrid;
