import React from "react";
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import Auth from "./Auth";
import { AppLayout } from "./static/Layouts";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { createLogger } from "../utils/logging";
import MainApp from "./MainApp";
import { Provider } from "react-redux";
import configureStore from "../utils/configureStore";
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH_CONFIG } from "../auth/config";

const DEBUG = true;
const debug = createLogger(DEBUG, `App.js`);

function App() {
  debug.log("render App");
  return (
    <AppLayout>
      <Auth0Provider domain={AUTH_CONFIG.domain} clientId={AUTH_CONFIG.clientId} redirectUri={AUTH_CONFIG.callbackUrl}>
        <Provider store={configureStore()}>
          <Router>
            <Switch>
              <Route path="/auth" component={Auth} />
              <Route path="/callback" component={Auth} />
              <Route path="/c2sense" component={MainApp} />
              <Route path="/">
                <Redirect to="/auth" />
              </Route>
            </Switch>
          </Router>
        </Provider>
      </Auth0Provider>
    </AppLayout>
  );
}

export default App;
