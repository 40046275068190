import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeUser } from "../../../../data/user/actions";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import ConfirmUserDialog from "../../../utils/ConfirmUserDialog";
import Reassign from "./Reassign";

const DeleteUserRow = (props) => {
  const { data } = props;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteRow = () => {
    dispatch(removeUser(data._id)).then(() => {
      props.setOpenDeleteUserSnackbar(true);
    });
  };

  const reassignUsersObjects = (newUser) => {
    dispatch(removeUser(data._id, newUser));
  };

  return (
    <div>
      <IconButton onClick={() => setConfirmOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmUserDialog
        title="Delete User?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteRow}
        reassign={reassignUsersObjects}
        data={data}>
        Are you sure you want to delete this user? This action cannot be undone. You can also choose to reassign this user's scans.
      </ConfirmUserDialog>
      <Reassign />
    </div>
  );
};

export default DeleteUserRow;
