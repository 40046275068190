import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, Divider, AccordionActions, TextField, Snackbar } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import "ag-grid-enterprise";
import { defaultGridConfig } from "../../grid/config/defaultGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DeleteUserRow from "../../grid/cellComponents/user/DeleteUserRow";
import DeleteAuthentagRow from "../../grid/cellComponents/authentag/DeleteAuthentagRow";
import DeleteProductRow from "../../grid/cellComponents/product/DeleteProductRow";
import DeleteExperimentRow from "../../grid/cellComponents/experiment/DeleteExperimentRow";
import DeleteOrganization from "./subcomponents/DeleteOrganization";
import DeleteScanRow from "../../grid/cellComponents/scan/DeleteScanRow";
import UserActions from "../../grid/cellComponents/user/UserActions";
import AuthentagActions from "../../grid/cellComponents/authentag/AuthentagActions";
import ExperimentActions from "../../grid/cellComponents/experiment/ExperimentActions";
import ProductActions from "../../grid/cellComponents/product/ProductActions";
import EditUserForm from "../../grid/cellComponents/user/EditUserForm";
import EditAuthentagForm from "../../grid/cellComponents/authentag/EditAuthentagForm";
import EditExperimentForm from "../../grid/cellComponents/experiment/EditExperimentForm";
import EditProductForm from "../../grid/cellComponents/product/EditProductForm";
import EditScanForm from "../../grid/cellComponents/scan/EditScanForm";
import EditOrganizationForm from "./subcomponents/EditOrganizationForm";
import { useSelector } from "react-redux";
import ModeActions from "./subcomponents/ModeActions";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//TODO:::
function ScanViewportDataSource() {}
ScanViewportDataSource.prototype.init = function (params) {
  this.params = params; // has setRowCount, setRowData(, getRow(index)
};
ScanViewportDataSource.prototype.destroy = function () {};
ScanViewportDataSource.prototype.setViewportRange = function (firstRow, lastRow) {};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    maxWidth: 500,
    maxHeight: 1000,
    height: 300,
    width: "100%",
    transition: "transform 0.15s ease-in-out",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  media: {
    width: "100%",
    outline: "2px",
    backgroundColor: "white",
    paddingBottom: "15px",
    marginTop: "20px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular
  },
  actions: {
    paddingBottom: "20px"
  },
  return: {
    marginBottom: "10px"
  },
  modeContainer: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  modeBox: {
    margin: "10px",
    width: "12vw"
  },
  snackbar: {
    margin: 0,
    top: "auto",
    right: 0,
    bottom: 40,
    left: "auto",
    position: "fixed"
  }
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails);

const OrganizationShow = ({
  organization,
  setCurrentOrganizationInfo,
  setOpenUpdateOrganizationSnackbar,
  setOpenDeleteOrganizationSnackbar
}) => {
  const [expanded, setExpanded] = useState("panel1");
  const [userRowData, setUserRowData] = useState([]);
  const [authentagRowData, setAuthentagRowData] = useState([]);
  const [atkitRowData, setAtkitRowData] = useState([]);
  const [productRowData, setProductRowData] = useState([]);
  const [scanRowData, setScanRowData] = useState([]);
  const [experimentRowData, setExperimentRowData] = useState([]);

  const usersUpdate = useSelector((state) => state.user.cacheUpdate);
  const organizationsUpdate = useSelector((state) => state.organization.cacheUpdate);
  const scansUpdate = useSelector((state) => state.scan.cacheUpdate);
  const productsUpdate = useSelector((state) => state.product.cacheUpdate);
  const authentagsUpdate = useSelector((state) => state.authentag.cacheUpdate);
  const atkitUpdate = useSelector((state) => state.atkit.cacheUpdate);
  const experimentsUpdate = useSelector((state) => state.experiment.cacheUpdate);
  const rbacsUpdate = useSelector((state) => state.rbac.cacheUpdate);

  const users = useSelector((state) => state.user.cache);
  const scans = useSelector((state) => state.scan.cache);
  const experiments = useSelector((state) => state.experiment.cache);
  const products = useSelector((state) => state.product.cache);
  const authentags = useSelector((state) => state.authentag.cache);
  const atkits = useSelector((state) => state.atkit.cache);
  const superOrg = useSelector((state) => state.auth.organization.super_org);

  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let numScans = 0;
  scans.map((scan) => {
    if (scan.organization_id === organization._id) {
      numScans++;
    }
    return numScans;
  });

  let numAuthentags = 0;
  authentags.map((authentag) => {
    if (authentag.organization_id === organization._id) {
      numAuthentags++;
    }
    return numAuthentags;
  });

  let numExperiments = 0;
  experiments.map((experiment) => {
    if (experiment.organization_id === organization._id) {
      numExperiments++;
    }
    return numExperiments;
  });

  let numUsers = 0;
  users.map((user) => {
    if (user.organization_id === organization._id) {
      numUsers++;
    }
    return numUsers;
  });

  let numProducts = 0;
  products.map((product) => {
    if (product.organization_id === organization._id) {
      numProducts++;
    }
    return numProducts;
  });

  useEffect(() => {
    function createUserData(_id, first_name, last_name, email, organization_id, password_reset_url) {
      return { _id, first_name, last_name, email, organization_id, password_reset_url };
    }
    console.log("ENTERED: useEffect orgshow");
    let userRows = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].organization_id === organization._id) {
        let rowUserData = createUserData(
          users[i]._id,
          users[i].first_name,
          users[i].last_name,
          users[i].email,
          users[i].organization_id,
          users[i].password_reset_url
        );
        userRows.push(rowUserData);
      }
    }

    // FIXME: should just be a rows = authentags.filter - org id - .map - spreader
    let authentagRows = [];
    for (let i = 0; i < authentags.length; i++) {
      if (authentags[i].organization_id === organization._id) {
        authentagRows.push({ ...authentags[i], supported_versions: [...authentags[i].supported_versions] });
      }
    }

    function createProductData(_id, name, description, authentag_location, authentag_id) {
      return { _id, name, description, authentag_location, authentag_id };
    }
    let productRows = [];
    for (let i = 0; i < products.length; i++) {
      if (products[i].organization_id === organization._id) {
        let rowProductData = createProductData(
          products[i]._id,
          products[i].name,
          products[i].description,
          products[i].authentag_location,
          products[i].authentag_id
        );
        productRows.push(rowProductData);
      }
    }

    function createScanData(_id, name, scan_datetime, authentag_id, parent_type, archived) {
      return { _id, name, scan_datetime, authentag_id, parent_type, archived };
    }
    let scanRows = [];
    for (let i = 0; i < scans.length; i++) {
      if (scans[i].organization_id === organization._id) {
        let rowScanData = createScanData(
          scans[i]._id,
          scans[i].name,
          scans[i].scan_datetime.toDateString(),
          scans[i].authentag_id,
          scans[i].parent_type,
          scans[i].archived
        );
        scanRows.push(rowScanData);
      }
    }

    function createExperimentData(_id, name, description, _inserted, authentag_id, archived) {
      return { _id, name, description, _inserted, authentag_id, archived };
    }
    let experimentRows = [];
    for (let i = 0; i < experiments.length; i++) {
      if (experiments[i].organization_id === organization._id) {
        let rowExperimentData = createExperimentData(
          experiments[i]._id,
          experiments[i].name,
          experiments[i].description,
          experiments[i]._inserted.toDateString(),
          experiments[i].authentag_id,
          experiments[i].archived
        );
        experimentRows.push(rowExperimentData);
      }
    }
    setUserRowData(userRows);
    setProductRowData(productRows);
    setAuthentagRowData(authentagRows);
    setScanRowData(scanRows);
    setExperimentRowData(experimentRows);
    setAtkitRowData(atkits);

    console.log("FINISHED: useEffect orgshow");
  }, [
    users,
    products,
    scans,
    authentags,
    usersUpdate,
    experiments,
    organization,
    organizationsUpdate,
    scansUpdate,
    experimentsUpdate,
    productsUpdate,
    authentagsUpdate,
    rbacsUpdate
  ]);

  //success message configs
  const [openUpdateUserSnackbar, setOpenUpdateUserSnackbar] = useState(false);
  const [openDeleteUserSnackbar, setOpenDeleteUserSnackbar] = useState(false);

  const [openUpdateAuthentagSnackbar, setOpenUpdateAuthentagSnackbar] = useState(false);
  const [openDeleteAuthentagSnackbar, setOpenDeleteAuthentagSnackbar] = useState(false);

  const [openUpdateProductSnackbar, setOpenUpdateProductSnackbar] = useState(false);
  const [openDeleteProductSnackbar, setOpenDeleteProductSnackbar] = useState(false);

  const [openUpdateExperimentSnackbar, setOpenUpdateExperimentSnackbar] = useState(false);
  const [openDeleteExperimentSnackbar, setOpenDeleteExperimentSnackbar] = useState(false);

  const [openUpdateScanSnackbar, setOpenUpdateScanSnackbar] = useState(false);
  const [openDeleteScanSnackbar, setOpenDeleteScanSnackbar] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenUpdateUserSnackbar(false);
    setOpenDeleteUserSnackbar(false);
    setOpenUpdateAuthentagSnackbar(false);
    setOpenDeleteAuthentagSnackbar(false);
    setOpenUpdateProductSnackbar(false);
    setOpenDeleteProductSnackbar(false);
    setOpenUpdateExperimentSnackbar(false);
    setOpenDeleteExperimentSnackbar(false);
    setOpenUpdateScanSnackbar(false);
    setOpenDeleteScanSnackbar(false);
  };

  //column defs
  const userColumnDefs = [
    {
      headerName: "First Name",
      field: "first_name"
    },
    { headerName: "Last Name", field: "last_name" },
    { headerName: "Email", field: "email" },
    { headerName: "Password Reset URL", field: "password_reset_url", hide: true },
    {
      cellRendererFramework: EditUserForm,
      cellRendererParams: {
        setOpenUpdateUserSnackbar: setOpenUpdateUserSnackbar
      },
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    },
    {
      cellRendererFramework: DeleteUserRow,
      cellRendererParams: {
        setOpenDeleteUserSnackbar: setOpenDeleteUserSnackbar
      },
      immutable: true,
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    }
  ];

  const nonSuperOrgUserColumnDefs = [
    {
      headerName: "First Name",
      field: "first_name"
    },
    { headerName: "Last Name", field: "last_name" },
    { headerName: "Email", field: "email" },
    { headerName: "Password Reset URL", field: "password_reset_url", hide: true }
  ];

  const authentagColumnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Description", field: "description" },
    { headerName: "Notes", field: "notes" },
    { headerName: "ID", field: "_id", hide: true },
    { headerName: "Date Created", field: "_inserted" },
    {
      cellRendererFramework: EditAuthentagForm,
      cellRendererParams: {
        setOpenUpdateAuthentagSnackbar: setOpenUpdateAuthentagSnackbar
      },
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    },
    // {
    //   cellRendererFramework: EditAuthentagForm,
    //   cellRendererParams: {
    //     setOpenUpdateAuthentagSnackbar: setOpenUpdateAuthentagSnackbar
    //   },
    //   editable: false,
    //   sortable: false,
    //   filter: false,
    //   width: 40
    // },
    {
      headerName: "Archive",
      cellRendererFramework: DeleteAuthentagRow,
      cellRendererParams: {
        setOpenDeleteAuthentagSnackbar: setOpenDeleteAuthentagSnackbar
      },
      immutable: true,
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    }
    // {
    //   headerName: "Archived",
    //   field: "archived",
    //   valueGetter: (params) => {
    //     if (!params.data || !params.data.archived) return;
    //     else return "Yes";
    //   }
    // }
  ];

  const atkitColumnDefs = [
    { headerName: "Version", field: "name" },
    {
      headerName: "compatible_versions",
      field: "description",
      valueGetter: (params) => {
        if (!params.data || !params.data.compatible_versions) return;
        else return params.data.compatible_versions.join(", ");
      }
    },
    {
      headerName: "Used By",
      field: "used_by",
      valueGetter: (params) => {
        if (!params.data || !params.data.used_by) return;
        else return params.data.used_by.join(", ");
      }
    },
    { headerName: "Archived", field: "archived", hide: true },
    { headerName: "Date Created", field: "_inserted" }
  ];

  const nonSuperOrgAuthentagColumnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Description", field: "description" },
    { headerName: "Notes", field: "notes" },
    { headerName: "ID", field: "_id", hide: true },
    { headerName: "Date Created", field: "_inserted" },
    {
      headerName: "Archived",
      field: "archived",
      valueGetter: (params) => {
        if (!params.data || !params.data.archived) return;
        else return "Yes";
      }
    }
  ];

  const productColumnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Description", field: "description" },
    { headerName: "Authentag Location", field: "authentag_location" },
    {
      cellRendererFramework: EditProductForm,
      cellRendererParams: {
        setOpenUpdateProductSnackbar: setOpenUpdateProductSnackbar
      },
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    },
    {
      cellRendererFramework: DeleteProductRow,
      cellRendererParams: {
        setOpenDeleteProductSnackbar: setOpenDeleteProductSnackbar
      },
      immutable: true,
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    }
  ];

  const scanColumnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Date Scanned", field: "scan_datetime" },
    { headerName: "Parent Type", field: "parent_type" },
    { headerName: "Authentag ID", field: "authentag_id", hide: true },
    {
      cellRendererFramework: EditScanForm,
      cellRendererParams: {
        setOpenUpdateScanSnackbar: setOpenUpdateScanSnackbar
      },
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    },
    {
      cellRendererFramework: DeleteScanRow,
      cellRendererParams: {
        setOpenDeleteScanSnackbar: setOpenDeleteScanSnackbar
      },
      immutable: true,
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    },
    {
      headerName: "Archived",
      field: "archived",
      valueGetter: (params) => {
        if (!params.data || !params.data.archived) return;
        else return "Yes";
      }
    }
  ];

  const nonSuperOrgScanColumnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Date Scanned", field: "scan_datetime" },
    { headerName: "Authentag ID", field: "authentag_id", hide: true }
  ];

  const experimentColumnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Description", field: "description" },
    { headerName: "Date Created", field: "_inserted" },
    {
      cellRendererFramework: EditExperimentForm,
      cellRendererParams: {
        setOpenUpdateExperimentSnackbar: setOpenUpdateExperimentSnackbar
      },
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    },
    {
      cellRendererFramework: DeleteExperimentRow,
      cellRendererParams: {
        setOpenDeleteExperimentSnackbar: setOpenDeleteExperimentSnackbar
      },
      immutable: true,
      editable: false,
      sortable: false,
      filter: false,
      width: 40
    },
    { headerName: "Authentag ID", field: "authentag_id", hide: true },
    {
      headerName: "Archived",
      field: "archived",
      valueGetter: (params) => {
        if (!params.data || !params.data.archived) return;
        else return "Yes";
      }
    }
  ];

  const nonSuperOrgExperimentColumnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Description", field: "description" },
    { headerName: "Date Created", field: "_inserted" },
    { headerName: "Authentag ID", field: "authentag_id", hide: true },
    {
      headerName: "Archived",
      field: "archived",
      valueGetter: (params) => {
        if (!params.data || !params.data.archived) return;
        else return "Yes";
      }
    }
  ];

  //ag grid config
  const gridConfig = {
    ...defaultGridConfig,
    defaultColDef: {
      flex: 1,
      minWidth: 20,
      sortable: true,
      filter: true,
      editable: false,
      resizable: true
    },
    groupMultiAutoColumn: true,
    enableRangeSelection: true,
    animateRows: true,
    getRowNodeId: (data) => data._id,
    rowSelection: "single",
    groupSelectsChildren: true,
    alwaysShowVerticalScroll: true,
    suppressHorizontalScroll: false,
    scrollbarWidth: 10,
    rowHeight: 50,
    paginationPageSize: 50,
    pagination: true,
    paginateChildRows: true
  };
  const scanGridConfig = { ...gridConfig };

  //mode config and states
  const modes = [
    {
      value: 0,
      label: "Disabled"
    },
    {
      value: 1,
      label: "Enabled"
    },
    {
      value: 2,
      label: "Invisible"
    }
  ];

  const [experimentsMode, setExperimentsMode] = useState(organization.experiments_mode);
  const [consumerMode, setConsumerMode] = useState(organization.consumer_mode);
  const [manufacturingMode, setManufacturingMode] = useState(organization.manufacturing_mode);
  const [calibrationMode, setCalibrationMode] = useState(organization.calibration_mode);
  const [showIntensityMode, setShowIntensityMode] = useState(organization.show_intensity);
  const [demoMode, setDemoMode] = useState(organization.demo_mode);

  const handleExperimentsModeChange = (e) => setExperimentsMode(parseInt(e.target.value));
  const handleConsumerModeChange = (e) => setConsumerMode(parseInt(e.target.value));
  const handleManufacturingModeChange = (e) => setManufacturingMode(parseInt(e.target.value));
  const handleCalibrationModeChange = (e) => setCalibrationMode(parseInt(e.target.value));
  const handleDemoModeChange = (e) => setDemoMode(parseInt(e.target.value));

  const handleShowIntensityModeChange = (e) => {
    console.log(`Setting --> ${e.target.value}`, e.target);
    setShowIntensityMode(e.target.value === "Visible");
    console.log(`set intensity to ${showIntensityMode}`);
  };

  if (superOrg) {
    console.log("RENDER ORG: super");
    return (
      <div>
        <img alt="media" src={organization.logo} align="center" className={classes.media} />
        <Divider />
        <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography className={classes.heading}>Select Mode</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form className={classes.modeContainer} noValidate autoComplete="off">
              <div>
                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  label="Experiments Mode"
                  value={experimentsMode}
                  onChange={handleExperimentsModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  label="Manufacturing Mode"
                  value={manufacturingMode}
                  onChange={handleManufacturingModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  label="Consumer Mode"
                  value={consumerMode}
                  onChange={handleConsumerModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  label="Calibration Mode"
                  value={calibrationMode}
                  onChange={handleCalibrationModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  label="Demo Mode"
                  value={demoMode}
                  onChange={handleDemoModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  label="Show Intensity Mode"
                  value={showIntensityMode ? "Visible" : "Hidden"}
                  onChange={handleShowIntensityModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  <option key="Visible" value="Visible">
                    Visible
                  </option>
                  <option key="Hidden" value="Hidden">
                    Invisible
                  </option>
                </TextField>
              </div>
            </form>
          </AccordionDetails>
          <AccordionActions className={classes.actions}>
            <ModeActions
              organization={organization}
              experimentsMode={experimentsMode}
              consumerMode={consumerMode}
              manufacturingMode={manufacturingMode}
              calibrationMode={calibrationMode}
              demoMode={demoMode}
              showIntensityMode={showIntensityMode}
            />
          </AccordionActions>
        </Accordion>

        <Accordion square expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography className={classes.heading}>Users: {numUsers}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgUsersGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact
                {...gridConfig}
                columnDefs={userColumnDefs}
                rowData={userRowData}
                domLayout={"autoHeight"}
                checkboxSelection={true}
              />
            </div>
          </AccordionDetails>
          <AccordionActions className={classes.actions}>
            <UserActions organization={organization} />
            <Snackbar className={classes.snackbar} open={openUpdateUserSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                User Updated Successfully!
              </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={openDeleteUserSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                User Deleted Successfully!
              </Alert>
            </Snackbar>
          </AccordionActions>
        </Accordion>

        <Accordion square expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography className={classes.heading}>Authentags: {numAuthentags}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgUsersGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact {...gridConfig} columnDefs={authentagColumnDefs} rowData={authentagRowData} domLayout={"autoHeight"} />
            </div>
          </AccordionDetails>
          <AccordionActions className={classes.actions}>
            <AuthentagActions organization={organization} />
            <Snackbar className={classes.snackbar} open={openUpdateAuthentagSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Authentag Updated Successfully!
              </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={openDeleteAuthentagSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Authentag Deleted Successfully!
              </Alert>
            </Snackbar>
          </AccordionActions>
        </Accordion>

        <Accordion square expanded={expanded === "panel3-1"} onChange={handleChange("panel3-1")}>
          <AccordionSummary aria-controls="panel3d-1-content" id="panel3d-1-header">
            <Typography className={classes.heading}>AtKit Versions: {atkits.length}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgAtkitGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact {...gridConfig} columnDefs={atkitColumnDefs} rowData={atkitRowData} domLayout={"autoHeight"} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography className={classes.heading}>Products: {numProducts}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgProductsGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact {...gridConfig} columnDefs={productColumnDefs} rowData={productRowData} domLayout={"autoHeight"} />
            </div>
          </AccordionDetails>
          <AccordionActions className={classes.actions}>
            <ProductActions organization={organization} />
            <Snackbar className={classes.snackbar} open={openUpdateProductSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Product Updated Successfully!
              </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={openDeleteProductSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Product Deleted Successfully!
              </Alert>
            </Snackbar>
          </AccordionActions>
        </Accordion>

        <Accordion square expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography className={classes.heading}>Experiments: {numExperiments}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgExperimentsGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact {...gridConfig} columnDefs={experimentColumnDefs} rowData={experimentRowData} domLayout={"autoHeight"} />
            </div>
          </AccordionDetails>
          <AccordionActions className={classes.actions}>
            <ExperimentActions organization={organization} />
            <Snackbar className={classes.snackbar} open={openUpdateExperimentSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Experiment Updated Successfully!
              </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={openDeleteExperimentSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Experiment Deleted Successfully!
              </Alert>
            </Snackbar>
          </AccordionActions>
        </Accordion>

        <Accordion square expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography className={classes.heading}>Scans: {numScans}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgScansGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact {...gridConfig} columnDefs={scanColumnDefs} rowData={scanRowData} domLayout={"autoHeight"} />
            </div>
          </AccordionDetails>
          <AccordionActions>
            <Snackbar className={classes.snackbar} open={openUpdateScanSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Scan Updated Successfully!
              </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={openDeleteScanSnackbar} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Scan Deleted Successfully!
              </Alert>
            </Snackbar>
          </AccordionActions>
        </Accordion>

        <EditOrganizationForm
          organization={organization}
          setCurrentOrganizationInfo={setCurrentOrganizationInfo}
          setOpenUpdateOrganizationSnackbar={setOpenUpdateOrganizationSnackbar}
        />
        <DeleteOrganization
          organization={organization}
          setCurrentOrganizationInfo={setCurrentOrganizationInfo}
          setOpenDeleteOrganizationSnackbar={setOpenDeleteOrganizationSnackbar}
        />
      </div>
    );
  } else {
    return (
      <div>
        <img alt="media" src={organization.logo} align="center" className={classes.media} />
        <Divider />
        <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography className={classes.heading}>Select Mode</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form className={classes.modeContainer} noValidate autoComplete="off">
              <div>
                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  disabled
                  label="Experiments Mode"
                  value={experimentsMode}
                  onChange={handleExperimentsModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  disabled
                  label="Manufacturing Mode"
                  value={manufacturingMode}
                  onChange={handleManufacturingModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  disabled
                  label="Consumer Mode"
                  value={consumerMode}
                  onChange={handleConsumerModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  className={classes.modeBox}
                  select
                  disabled
                  label="Calibration Mode"
                  value={calibrationMode}
                  onChange={handleCalibrationModeChange}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined">
                  {modes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </form>
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography className={classes.heading}>Users: {numUsers}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgUsersGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact
                {...gridConfig}
                columnDefs={nonSuperOrgUserColumnDefs}
                rowData={userRowData}
                domLayout={"autoHeight"}
                checkboxSelection={true}
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography className={classes.heading}>Authentags: {numAuthentags}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgUsersGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact
                {...gridConfig}
                columnDefs={nonSuperOrgAuthentagColumnDefs}
                rowData={authentagRowData}
                domLayout={"autoHeight"}
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography className={classes.heading}>Experiments: {numExperiments}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgExperimentsGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact
                {...gridConfig}
                columnDefs={nonSuperOrgExperimentColumnDefs}
                rowData={experimentRowData}
                domLayout={"autoHeight"}
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography className={classes.heading}>Scans: {numScans}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id="orgScansGrid"
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: "100%"
              }}>
              <AgGridReact {...gridConfig} columnDefs={nonSuperOrgScanColumnDefs} rowData={scanRowData} domLayout={"autoHeight"} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
};

export default OrganizationShow;
