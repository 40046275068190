import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import { createLogger } from "../utils/logging";
import ProfileMenu from "./views/profileView/ProfileMenu";
import MainListItems from "./MainListItems";
import { useAuth0 } from "@auth0/auth0-react";
import Organizations from "./views/organizationsView/Organizations";
import Dashboard from "./views/dashboardView/Dashboard";
import ProductGrid from "./grid/ProductGrid";
import DemoProductsGrid from "./grid/DemoProductsGrid";
import DemoProductScanGrid from "./grid/DemoProductScanGrid";
import ScanGrid from "./grid/ScanGrid";
import ExperimentGrid from "./grid/ExperimentGrid";
import AuthentagGrid from "./grid/AuthentagGrid";
import DeviceGrid from "./grid/DeviceGrid";
import { AppBar, Container, Drawer, Link, Toolbar, Typography, CssBaseline } from "@material-ui/core";
import { getUsers } from "../data/user/actions";
import { getOrganizations } from "../data/organization/actions";
import { getScans } from "../data/scan/actions";
import { getProducts } from "../data/product/actions";
import { getDemoProducts } from "../data/demoproduct/actions";
import { getAuthentags } from "../data/authentag/actions";
import { getExperiments } from "../data/experiment/actions";
import { getRbacs } from "../data/rbac/actions";
import { getDevices } from "../data/device/actions";
import { getAtkitVersions } from "../data/atkit/actions";
import { useDispatch, useSelector } from "react-redux";
import Auth0 from "../auth/Auth0";
import { epoch } from "../utils/dataUtils";
import { getOrganizationsDashboard, getSessionsDashboard, getUsersDashboard } from "../data/dashboard/actions";
import SpinLoader from "./utils/SpinLoader";
import DataManagement from "./DataManagement";

const DEBUG = true;
const debug = createLogger(DEBUG, `MainApp.js`);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  scroll: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
    position: "fixed"
  },
  logo: {
    maxWidth: 200,
    marginTop: "5px"
  },
  profile: {
    color: "#676767"
  },
  name: {
    color: "#676767",
    paddingTop: "15px",
    display: "flex"
  },
  title: {
    flexGrow: 1,
    fontFamily: "Arial"
  },
  toolbar: {
    height: "5.5vh"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#4B9F71",
    color: "#fff"
  },
  drawerContainer: {
    overflow: "auto",
    paddingTop: theme.spacing(0),
    background: "#4B9F71"
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "visible",
    padding: theme.spacing(3)
  },
  container: {
    paddingBottom: theme.spacing(5),
    position: "sticky"
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `white`
  }
}));

const MainApp = () => {
  const classes = useStyles();

  const [currentOrganizationInfo, setCurrentOrganizationInfo] = useState(null);

  const changeOrg = (org) => () => {
    setCurrentOrganizationInfo(org);
  };

  const nullOrg = () => {
    setCurrentOrganizationInfo(null);
  };

  const { isAuthenticated, user, logout } = useAuth0();

  const [asyncWaiter, setAsyncWaiter] = useState(false);
  const [requestsInit, setRequestsInit] = useState(false);
  const [usersRequests, setUsersRequests] = useState(false);
  const [organizationsRequests, setOrganizationsRequests] = useState(false);
  const [sessionsRequests, setSessionsRequests] = useState(false);
  const [ready, setReady] = useState(false);

  const usersCacheUpdate = useSelector((state) => state.user.cacheUpdate);
  const organizationsCacheUpdate = useSelector((state) => state.organization.cacheUpdate);
  const scansCacheUpdate = useSelector((state) => state.scan.cacheUpdate);
  const authentagsCacheUpdate = useSelector((state) => state.authentag.cacheUpdate);
  const experimentCacheUpdate = useSelector((state) => state.experiment.cacheUpdate);
  const productCacheUpdate = useSelector((state) => state.product.cacheUpdate);
  const demoProductCacheUpdate = useSelector((state) => state.demoproduct.cacheUpdate);
  const deviceCacheUpdate = useSelector((state) => state.device.cacheUpdate);
  const organizationsDataUpdateTime = useSelector((state) => state.dashboard.organizationsDataUpdateTime);
  const usersDataUpdateTime = useSelector((state) => state.dashboard.usersDataUpdateTime);
  const sessionsDataUpdateTime = useSelector((state) => state.dashboard.sessionsDataUpdateTime);
  const totalScans = useSelector((state) => state.scan.totalScans);
  const downloadedScans = useSelector((state) => state.scan.cache.length);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ready) {
      if (
        usersCacheUpdate !== epoch &&
        organizationsCacheUpdate !== epoch &&
        //scansCacheUpdate !== epoch &&
        totalScans > -1 &&
        authentagsCacheUpdate !== epoch &&
        experimentCacheUpdate !== epoch &&
        productCacheUpdate !== epoch &&
        demoProductCacheUpdate !== epoch &&
        deviceCacheUpdate !== epoch &&
        organizationsDataUpdateTime !== epoch &&
        usersDataUpdateTime !== epoch &&
        sessionsDataUpdateTime !== epoch
      ) {
        setReady(true);
      }
    }
  }, [
    ready,
    usersCacheUpdate,
    organizationsCacheUpdate,
    scansCacheUpdate,
    authentagsCacheUpdate,
    experimentCacheUpdate,
    productCacheUpdate,
    demoProductCacheUpdate,
    deviceCacheUpdate,
    organizationsDataUpdateTime,
    usersDataUpdateTime,
    sessionsDataUpdateTime
  ]);

  //dashboard sessions call
  useEffect(() => {
    debug.log(`useEffect -- dashboard sessions call ${sessionsRequests}`);

    if (isAuthenticated && sessionsRequests === false) {
      debug.log(`useEffect hitting the request calls for dashboard sessions -- ${sessionsRequests}`);
      setSessionsRequests(true);

      if (
        usersCacheUpdate === epoch ||
        scansCacheUpdate === epoch ||
        usersCacheUpdate > sessionsDataUpdateTime ||
        scansCacheUpdate > sessionsDataUpdateTime
      ) {
        dispatch(getSessionsDashboard()).then(
          () => {
            debug.log("getSessionsDashboard: success");
          },
          (err) => {
            debug.error(`getSessionsDashboard: failure ${err}`);
          }
        );
      }
    }
  }, [isAuthenticated, setSessionsRequests, sessionsRequests, dispatch, usersCacheUpdate, scansCacheUpdate, sessionsDataUpdateTime]);

  //dashboard users call
  useEffect(() => {
    debug.log(`useEffect -- dashboard users call ${usersRequests}`);

    if (isAuthenticated && usersRequests === false) {
      debug.log(`useEffect hitting the request calls for dashboard users -- ${usersRequests}`);
      setUsersRequests(true);

      if (usersCacheUpdate === epoch || usersCacheUpdate > usersDataUpdateTime) {
        dispatch(getUsersDashboard()).then(
          () => {
            debug.log("getUsersDashboard: success");
          },
          (err) => {
            debug.error(`getUsersDashboard: failure ${err}`);
          }
        );
      }
    }
  }, [setUsersRequests, usersRequests, dispatch, usersCacheUpdate, usersDataUpdateTime]);

  //dashboard organizations call
  useEffect(() => {
    debug.log(`useEffect -- dashboard organizations call ${organizationsRequests}`);

    if (isAuthenticated && organizationsRequests === false) {
      debug.log(`useEffect hitting the request calls for dashboard organizations -- ${organizationsRequests}`);
      setOrganizationsRequests(true);

      if (organizationsCacheUpdate === epoch || organizationsCacheUpdate > organizationsDataUpdateTime) {
        dispatch(getOrganizationsDashboard()).then(
          () => {
            debug.log("getOrganizationsDashboard: success");
          },
          (err) => {
            debug.error(`getOrganizationsDashboard: failure ${err}`);
          }
        );
      }
    }
  }, [setUsersRequests, organizationsRequests, dispatch, organizationsCacheUpdate, organizationsDataUpdateTime]);

  useEffect(() => {
    function actionsTrue() {
      dispatch(getOrganizations(true));
      dispatch(getUsers(true));
      dispatch(getProducts(true));
      dispatch(getDemoProducts(true));
      dispatch(getExperiments(true));
      dispatch(getScans(true));
      dispatch(getAuthentags(true));
      dispatch(getRbacs(true));
      dispatch(getDevices(true));
      dispatch(getAtkitVersions(true));
      setAsyncWaiter(false);
    }

    if (requestsInit && !asyncWaiter) {
      setAsyncWaiter(setTimeout(actionsTrue, 1000000));
    }
  }, [dispatch, asyncWaiter, requestsInit]);

  // Check for required data and make load calls if not present
  useEffect(() => {
    if (isAuthenticated) {
      if (requestsInit === false) {
        setRequestsInit(true);
        dispatch(getOrganizations());
        dispatch(getUsers());
        dispatch(getProducts());
        dispatch(getDemoProducts());
        dispatch(getExperiments());
        dispatch(getScans(false, { page: 1, page_size: 50, sort: "_inserted" }));
        dispatch(getAuthentags());
        dispatch(getRbacs());
        dispatch(getDevices());
        dispatch(getAtkitVersions());
      }
    }
  }, [dispatch, setRequestsInit, requestsInit, isAuthenticated]);

  useEffect(() => {
    // FIXME: for now, grab the first page above, and once it's here, grab the rest.
    /*
     * The basic problem is that the admin app, on teh scans screen, does grouping.  The grouping
     * is by org.  So that screen is really only useful if you have all the scans in redux, where
     * the rest of the app we could get away with doing server-side pagination.  Per talking to
     * Trey, went the cheap way here:
     *  -- just have the grids paginate on the full scan map so the grid is fast, but we still
     *     have everything in redux for now
     *  -- do an initial scan get of a page, which lets the app move forward from the spinner,
     *     and immediately request the rest of the scans in the background.  Lets the user start
     *     using the admin app while the scans are being downloaded.
     */
    if (totalScans > 0 && totalScans > downloadedScans) {
      dispatch(getScans());
    }
  }, [dispatch, totalScans, downloadedScans]);

  if (isAuthenticated) {
    if (ready) {
      console.log(`TOTALS -- ${downloadedScans} / ${totalScans}`);
      return (
        <div className={classes.root}>
          <DataManagement key="data-manager-hoc" />
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar} id="back-to-top-anchor">
            <Toolbar className={classes.toolbar} id="back-to-top-anchor">
              <Link target="_blank" href="https://www.c2sense.com/">
                <img src="https://i.imgur.com/mrweRcy.png" alt="logo" className={classes.logo} />
              </Link>
              <Typography variant="h2" color="inherit" noWrap className={classes.title} />
              <div className={classes.profile}>
                <ProfileMenu user={user} logout={logout} />
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}>
            <Toolbar />
            <div className={classes.drawerContainer}>
              <MainListItems nullOrg={nullOrg} />
            </div>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Switch>
                <Route path="/c2sense/authentags" component={AuthentagGrid} />
                <Route path="/c2sense/products" component={ProductGrid} />
                <Route path="/c2sense/demo-products" component={DemoProductsGrid} />
                <Route path="/c2sense/demo-product-scans" component={DemoProductScanGrid} />
                <Route path="/c2sense/experiments" component={ExperimentGrid} />
                <Route path="/c2sense/scans" component={ScanGrid} />
                <Route path="/c2sense/devices" component={DeviceGrid} />
                <Route path="/c2sense/organizations">
                  <Organizations
                    changeOrg={changeOrg}
                    currentOrganizationInfo={currentOrganizationInfo}
                    setCurrentOrganizationInfo={setCurrentOrganizationInfo}
                  />
                </Route>
                <Route path="/c2sense" component={Dashboard} />
              </Switch>
            </Container>
          </main>
        </div>
      );
    } else {
      const spinner = { left: "calc(50vw - 40px)", top: "calc(50vh - 40px)", position: "absolute" };
      debug.exit("Auth: spinning waiting for auth flow");
      return (
        <div style={spinner} id="SpinLoader">
          <SpinLoader />
        </div>
      );
    }
  } else {
    debug.log("We should be redirecting to auth0...");
    return Auth0.authRedirect();
  }
};

export default MainApp;
