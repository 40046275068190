import { scanActions } from "../actionTypes";
import { createLogger } from "../../utils/logging";
import { mergeLists, insertRecord, deleteRecord, processDependentChanges } from "../../utils/requestUtils";
import { epoch } from "../../utils/dataUtils";

const DEBUG = true;
const debug = createLogger(DEBUG, `scanReducer.js`);

const initialState = {
  cache: [],
  cacheById: {},
  totalScans: -1,
  lastPage: -1,
  pageSize: -1,
  pageCount: -1,
  cacheUpdate: epoch,
  lastUpdate: epoch,
  inflight: 0
};

const scanReducer = (state = initialState, action) => {
  debug.enter("scanReducer");
  debug.log(`action(${action.type}) -> `, action);
  let newState = processDependentChanges(action, state, "Scan");

  switch (action.type) {
    case scanActions.GET_SCANS_REQUEST:
      newState = { ...state };
      newState.inflight++;
      break;

    case scanActions.GET_SCANS_FAILURE:
      newState = { ...state };
      if (newState.inflight > 0) newState.inflight--;
      break;

    case scanActions.GET_SCANS_SUCCESS:
      newState = { ...state };
      if (action.data.objects.length > 0) {
        newState.cache = mergeLists(state.cache, action.data.objects, newState.cacheById);
        newState.cacheUpdate = new Date();
      } else if (newState.cacheUpdate === epoch) {
        newState.cacheUpdate = new Date();
      }
      newState.lastUpdate = action.data.update_time;
      if (action.data.pagination) {
        newState.totalScans = action.data.pagination.item_count;
        newState.lastPage = action.data.pagination.page;
        newState.pageSize = action.data.pagination.page_size;
        newState.pageCount = action.data.pagination.page_count;
      }
      if (newState.inflight > 0) newState.inflight--;
      break;

    case scanActions.GET_SCAN_SUCCESS:
    case scanActions.POST_SCAN_SUCCESS:
    case scanActions.PUT_SCAN_SUCCESS:
      newState = { ...state };
      insertRecord(newState.cache, action.data.object, newState.cacheById);
      newState.cacheUpdate = new Date();
      if (action.data.updated) newState.lastUpdate = action.data.update_time;
      newState.inflight = 0;
      break;

    case scanActions.DELETE_SCAN_SUCCESS:
      newState = { ...state };
      newState.cache = deleteRecord(state.cache, action.data.object._id, newState.cacheById);
      newState.cacheUpdate = new Date();
      newState.inflight = 0;
      break;

    default:
      break;
  }
  debug.log("scanReducer results: ", newState);
  debug.exit("scanReducer");
  return newState;
};

export default scanReducer;
