import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { putOrganization } from "../../../../data/organization/actions";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  snackbar: {
    margin: 0,
    top: "auto",
    right: 0,
    bottom: 40,
    left: "auto",
    position: "fixed"
  }
}));

const ModeActions = ({ organization, experimentsMode, consumerMode, manufacturingMode, calibrationMode, demoMode, showIntensityMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const id = organization._id;

  const handleSubmit = (e) => {
    e.preventDefault();
    const editedOrgMode = {
      experiments_mode: experimentsMode,
      consumer_mode: consumerMode,
      manufacturing_mode: manufacturingMode,
      calibration_mode: calibrationMode,
      show_intensity: showIntensityMode,
      demo_mode: demoMode
    };

    dispatch(putOrganization(id, editedOrgMode)).then(() => {
      setOpenSnackbar(true);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="users-grid-actions">
      <Button size="small" id="usergrid-adduserbutton" onClick={handleSubmit} style={{ marginRight: "10px" }}>
        Save
      </Button>
      <Snackbar className={classes.snackbar} open={openSnackbar} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Mode Updated Successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ModeActions;
