// src/App.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { postSession, setIdent } from "../data/auth/actions";
import { createLogger } from "../utils/logging";
import SpinLoader from "./utils/SpinLoader";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setupLogRocket } from "../utils/logRocketUtils";
import Auth0 from "../auth/Auth0";

const DEBUG = true;
const debug = createLogger(DEBUG, `Auth.js`);

function Auth() {
  // prettier-ignore
  const {
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout
  } = useAuth0();
  const dispatch = useDispatch();
  const [searchParam] = useState(String(window.location.search));
  const [waitingForSession, setWaitingForSession] = useState(false);
  const [authComplete, setAuthComplete] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const userRecord = useSelector((state) => state.auth.user);
  const sessionError = useSelector((state) => state.auth.sessionError);
  const idToken = useSelector((state) => state.auth.idToken);

  debug.enter("Auth");
  debug.log("store, userRecord, idToken", userRecord, idToken);

  useEffect(() => {
    debug.enter("Effect: check/init authentication");
    if (!isAuthenticated && !isLoading) {
      // Here, not auth'd or loading so start something
      if (!searchParam || searchParam.length === 0) {
        loginWithRedirect().then(
          () => {
            debug.log("Successful loginWithRedirect");
          },
          (err) => {
            debug.error(`Failed loginWithRedirect: ${err}`);
            window.location.pathname = "/";
          }
        );
      } else {
        getAccessTokenSilently().then(
          () => {
            debug.log("Successful getAccessTokenSilently");
          },
          (err) => {
            debug.error(`Failed getAccessTokenSilently: ${err}`);
            window.location.search = "";
          }
        );
      }
    }
    debug.exit("Effect: check/init authentication");
  });

  useEffect(() => {
    debug.enter("Effect::getIdTokenClaims");
    debug.log(`isAuthenticated ${isAuthenticated}, waitingForSession ${waitingForSession}`);
    if (isAuthenticated && !idToken) {
      debug.log("Getting Id Token Claims...");
      getIdTokenClaims().then((cc) => {
        debug.log(`Token claims: `, cc);
        dispatch(setIdent(cc.__raw));
      });
    }
    debug.exit("Effect::getIdTokenClaims");
  }, [idToken, isAuthenticated, waitingForSession, dispatch, getIdTokenClaims]);

  useEffect(() => {
    debug.enter("Effect::postSession");
    debug.log(`idToken ${!!idToken}, waitingForSession ${waitingForSession} userRecord`, userRecord);
    if (idToken && !userRecord && !waitingForSession && !sessionError) {
      debug.log("dispatching postSession");
      dispatch(postSession({ is_mobile: false })).then(
        (obj) => {
          debug.log("postSession: success");
          setWaitingForSession(false);
          let swk = obj.object.sentry_web_key;
          if (swk) {
            debug.log(`init sentry.io with ${swk}`);
            Sentry.init({
              dsn: swk,
              integrations: [new Integrations.BrowserTracing()],
              tracesSampleRate: 1.0
            });
          }
          let lr_org = obj.object.log_rocket_organization;
          let lr_web_key = obj.object.log_rocket_web_key;
          let session = obj.object.session;
          let user = obj.object.user;
          setupLogRocket(session._id, lr_org, lr_web_key, user, dispatch);
          setAuthComplete(true);
        },
        (err) => {
          debug.error(`postSession: failure ${err}`);
          setWaitingForSession(false);
        }
      );
      setWaitingForSession(true);
    }
    debug.exit("Effect::postSession");
  }, [idToken, waitingForSession, setWaitingForSession, dispatch, userRecord, logout]);

  useEffect(() => {
    debug.log("Auth: sessionError:", sessionError);
    if (sessionError && !loggedOut) {
      debug.error(`postSession: failure ${sessionError}`);
      setLoggedOut(true);
      // Need to return to login prompt
      setTimeout(() => {
        logout({ returnTo: `${window.location.origin}/logout` });
      }, 4000);
    }
  }, [loggedOut, logout, sessionError]);

  if (userRecord && authComplete) {
    let redirectObj = Auth0.getAppRedirect(searchParam);
    debug.exit("Auth: redirecting to dashboard");
    return <Redirect to={redirectObj} />;
  } else if (sessionError) {
    return (
      <div className="leadgnome-spinloader-wrapper">
        <h2>User Account Not Found</h2>
      </div>
    );
  } else {
    const spinner = { left: "calc(50vw - 40px)", top: "calc(50vh - 40px)", position: "absolute" };
    debug.exit("Auth: spinning waiting for auth flow");
    return (
      <div style={spinner} id="SpinLoader">
        <SpinLoader />
      </div>
    );
  }
}

//export default compose(withAlert, connect(mapStateToProps, actionsNeeded))(App);
export default Auth;
