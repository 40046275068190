import { scanActions } from "../actionTypes";

const getScans = (updates, extra) => (dispatch, getState) => {
  if (extra === undefined) {
    extra = {};
  }
  if (updates) {
    const state = getState();
    extra.update_time = state.scan.lastUpdate;
  }
  // extra.page = 1;
  // extra.page_size = 50;
  // extra.sort = "_inserted"
  return dispatch({ type: scanActions.GET_SCANS_REQUEST, ...extra });
};
const getScan = (id) => (dispatch) => dispatch({ type: scanActions.GET_SCAN_REQUEST, id });
const putScan = (id, changes) => (dispatch) => dispatch({ type: scanActions.PUT_SCAN_REQUEST, id, ...changes });
const postScan = (scan) => (dispatch) => dispatch({ type: scanActions.POST_SCAN_REQUEST, ...scan });
const removeScan = (id) => (dispatch) => dispatch({ type: scanActions.DELETE_SCAN_REQUEST, id });
const downloadScan = (id) => (dispatch) => dispatch({ type: scanActions.GET_SCAN__DOWNLOAD_REQUEST, id });
const downloadScans = (scan_ids, include_data) => (dispatch) =>
  dispatch({ type: scanActions.POST_SCAN__DOWNLOAD_REQUEST, scan_ids, include_data });

export { getScan, getScans, putScan, postScan, removeScan, downloadScan, downloadScans };
