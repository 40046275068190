import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ListItem, List, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BuildIcon from "@material-ui/icons/Build";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import BusinessIcon from "@material-ui/icons/Business";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DevicesIcon from "@material-ui/icons/Devices";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360
  },
  link: {
    color: "white"
  },
  "&.active": {
    backgroundColor: "white"
  }
}));

const getPathIndex = () => {
  switch (window.location.pathname) {
    case window.location.pathname.includes("/c2sense/organizations"):
      return 1;
    case "/c2sense/authentags":
      return 2;
    case "/c2sense/demo-products":
      return 3;
    case "/c2sense/demo-product-scans":
      return 4;
    case "/c2sense/products":
      return 5;
    case "/c2sense/experiments":
      return 6;
    case "/c2sense/scans":
      return 7;
    case "/c2sense/devices":
      return 8;
    case "/c2sense/settings":
      return 9;
    default:
      return 0;
  }
};

const MainListItems = ({ nullOrg }) => {
  const classes = useStyles();
  const pathIndex = getPathIndex();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const superOrg = useSelector((state) => state.auth.organization.super_org);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(pathIndex);
  }, [pathIndex]);

  if (superOrg) {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="nav tabs">
          <ListItem
            className={classes.link}
            button
            component={Link}
            to="/c2sense"
            selected={selectedIndex === 0}
            onClick={(event) => {
              handleListItemClick(event, 0);
            }}>
            <ListItemIcon>
              <DashboardIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Dashboard" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/organizations"
            selected={selectedIndex === 1}
            onClick={(event) => {
              handleListItemClick(event, 1);
              nullOrg();
            }}>
            <ListItemIcon>
              <BusinessIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Organizations" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/authentags"
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}>
            <ListItemIcon>
              <LocalOfferIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Authentags" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/demo-products"
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              nullOrg();
            }}>
            <ListItemIcon>
              <LocalMallIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Demo Products" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/demo-product-scans"
            selected={selectedIndex === 4}
            onClick={(event) => {
              handleListItemClick(event, 4);
              nullOrg();
            }}>
            <ListItemIcon>
              <PhoneIphoneIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Demo Scans" />
          </ListItem>

          {/*<ListItem*/}
          {/*  button*/}
          {/*  component={Link}*/}
          {/*  to="/c2sense/products"*/}
          {/*  selected={selectedIndex === 5}*/}
          {/*  onClick={(event) => handleListItemClick(event, 5)}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <LocalMallIcon style={{ color: "white" }} />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText className={classes.link} primary="Products" />*/}
          {/*</ListItem>*/}

          <ListItem
            button
            component={Link}
            to="/c2sense/experiments"
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}>
            <ListItemIcon>
              <BuildIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Experiments" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/scans"
            selected={selectedIndex === 7}
            onClick={(event) => handleListItemClick(event, 7)}>
            <ListItemIcon>
              <PhoneIphoneIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Scans" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/devices"
            selected={selectedIndex === 8}
            onClick={(event) => handleListItemClick(event, 8)}>
            <ListItemIcon>
              <DevicesIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Devices" />
          </ListItem>
        </List>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="nav tabs">
          <ListItem
            className={classes.link}
            button
            component={Link}
            to="/c2sense"
            selected={selectedIndex === 0}
            onClick={(event) => {
              handleListItemClick(event, 0);
            }}>
            <ListItemIcon>
              <DashboardIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Dashboard" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/organizations"
            selected={selectedIndex === 1}
            onClick={(event) => {
              handleListItemClick(event, 1);
              nullOrg();
            }}>
            <ListItemIcon>
              <BusinessIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Organization" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/demo-product-scans"
            selected={selectedIndex === 4}
            onClick={(event) => {
              handleListItemClick(event, 4);
              nullOrg();
            }}>
            <ListItemIcon>
              <PhoneIphoneIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Demo Scans" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/experiments"
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}>
            <ListItemIcon>
              <BuildIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Experiments" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/c2sense/scans"
            selected={selectedIndex === 7}
            onClick={(event) => handleListItemClick(event, 7)}>
            <ListItemIcon>
              <PhoneIphoneIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText className={classes.link} primary="Scans" />
          </ListItem>
        </List>
      </div>
    );
  }
};

export default MainListItems;
