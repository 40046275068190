import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createLogger } from "../../utils/logging";
import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Auth0 from "../../auth/Auth0";
import { defaultGridConfig } from "./config/defaultGridConfig";
import { download } from "../../utils/utils";
import { getHostPort } from "../../utils/environmentHelper";
import lodash from "lodash";
import CalibrationDataDialog from "./cellComponents/device/CalibrationDataDialog";
import ReactMarkdown from "react-markdown";

const DEBUG = true;
const debug = createLogger(DEBUG, `DeviceGrid.js`);

const DeviceGrid = () => {
  const { isAuthenticated } = useAuth0();
  const devices = useSelector((state) => state.device.cache);
  const idToken = useSelector((state) => state.auth.idToken);

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [calibrationData, setCalibrationData] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const onDownloadSelectedPackage = (include_data) => () => {
    let device_ids = gridApi.getSelectedNodes().map((node) => node.data._id);

    download(
      `${getHostPort()}/api/v1/devices/download`,
      "POST",
      { device_ids },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      },
      include_data ? "package.zip" : "package.xlsx"
    );
  };

  useEffect(() => {
    let sortedArr = [];

    devices.forEach((device) => {
      // let newDevice = { ...device, calibration: "No Calibration Data", horzScan: "-", pulsePeriod: "-", pulseWidth: "-" };
      let newDevice = { ...device };

      newDevice.name = device.name;
      newDevice.id = device._id;
      newDevice.created = device._inserted.toDateString();
      newDevice.updated = device._updated.toDateString();
      newDevice["calibrationData"] = newDevice.calibration
        ? JSON.stringify(newDevice.calibration, null, 2)
        : "No Calibration Data Available";
      // if (!lodash.isEmpty(device.calibration) && !lodash.isEmpty(device.calibration.strobeParameters)) {
      //
      //   newDevice.calibration = device.calibration.calibrationPoints;
      //   // newDevice.strobeParameters = device.calibration.strobeParameters;
      //   // newDevice.horzScan = device.calibration.strobeParameters.horzScan || "-";
      //   // newDevice.pulsePeriod = device.calibration.strobeParameters.pulsePeriod || "-";
      //   // newDevice.pulseWidth = device.calibration.strobeParameters.pulseWidth || "-";
      // }
      sortedArr.push(newDevice);
    });
    setRowData(sortedArr);
  }, [devices]);

  const renderCheckbox = (params) => !!params.data;

  const columnDefs = [
    { headerName: "Device Name", field: "name", tooltipField: "name", checkboxSelection: renderCheckbox, width: 200 },
    { headerName: "ID", field: "id", tooltipField: "id" },
    {
      headerName: "View Calibration",
      field: "calibrationData",
      cellRendererFramework: CalibrationDataDialog,
      width: 40,
      onCellClicked: (params) => {
        if (params.data) {
          setCalibrationData(params.value);
          setOpen(true);
        }
      }
    },
    { headerName: "App Version", field: "app_version", tooltipField: "App Version" },
    { headerName: "ATKit Version", field: "atkit_version", tooltipField: "ATKit Version" },
    { headerName: "iOS Version", field: "version", tooltipField: "iOS Version" },
    { headerName: "Unique ID", field: "unique_id", tooltipField: "id", hide: true },
    { headerName: "Device ID", field: "device_id", tooltipField: "id", hide: true },
    { headerName: "Created", field: "created", tooltipField: "created" },
    { headerName: "Last Updated", field: "updated", tooltipField: "updated", hide: true }
  ];

  const displayString = { calibrationData } ? ` \`\`\`${calibrationData}` : "No Calibration Data available";

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const gridConfig = {
    ...defaultGridConfig,
    onGridReady,
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      sortable: true,
      filter: true,
      resizable: true
    },
    autoGroupColumnDef: {
      minWidth: 200,
      cellRenderer: "agGroupCellRenderer"
    },
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
    groupMultiAutoColumn: true,
    enableRangeSelection: true,
    animateRows: true,
    rowData: rowData,
    columnDefs: columnDefs,
    getRowNodeId: (data) => data._id,
    rowSelection: "multiple",
    groupSelectsChildren: true,
    alwaysShowVerticalScroll: true,
    suppressHorizontalScroll: false,
    scrollbarWidth: 10,
    rowHeight: 50
  };

  if (isAuthenticated) {
    return (
      <div style={{ width: "100%", height: 600 }}>
        <Typography gutterBottom variant="h4" component="h2">
          Devices
        </Typography>
        <div
          id="deviceGrid"
          style={{
            height: "100%",
            width: "100%"
          }}
          className="ag-theme-alpine">
          <Button
            onClick={onDownloadSelectedPackage(true)}
            variant="outlined"
            style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Download Selected Devices
          </Button>
          <AgGridReact {...gridConfig} />
          <Dialog
            fullWidth="md"
            maxWidth="md"
            scroll="body"
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle>Calibration Data</DialogTitle>
            <DialogContent dividers>
              <ReactMarkdown source={displayString} children={displayString} />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  } else {
    debug.log("Reauthenticating...");
    return Auth0.authRedirect();
  }
};

export default DeviceGrid;
