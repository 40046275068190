import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, Divider, DialogTitle, IconButton, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { putUser } from "../../../../data/user/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  }
}));

const EditUserForm = (props) => {
  const { data } = props;

  const [showForm, setShowForm] = useState(false);
  const [firstName, setFirstName] = useState(data.first_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [email, setEmail] = useState(data.email);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleFirstName = (e) => setFirstName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);

  const id = data._id;

  const handleSubmit = (e) => {
    e.preventDefault();

    const editedUser = {
      first_name: firstName,
      last_name: lastName,
      email: email
    };

    dispatch(putUser(id, editedUser)).then(() => {
      props.setOpenUpdateUserSnackbar(true);
      setFirstName(firstName);
      setLastName(lastName);
      setEmail(email);
      setShowForm(false);
    });
  };

  return (
    <div>
      <Dialog open={showForm} onClose={() => setShowForm(false)} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Update User
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="first_name"
            name="first_name"
            label="First Name"
            value={firstName}
            onChange={handleFirstName}
            type="text"
            required
            fullWidth
            error={firstName === ""}
            helperText={firstName === "" ? "First name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="last_name"
            name="last_name"
            label="Last Name"
            value={lastName}
            onChange={handleLastName}
            type="text"
            required
            fullWidth
            error={lastName === ""}
            helperText={lastName === "" ? "Last name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="email"
            name="email"
            label="Email"
            value={email}
            onChange={handleEmail}
            type="email"
            required
            fullWidth
            error={email === ""}
            helperText={email === "" ? "Email is required!" : " "}
            className={classes.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={() => setShowForm(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setShowForm(true)}>
        <EditIcon />
      </IconButton>
    </div>
  );
};

export default EditUserForm;
