import { productActions } from "../actionTypes";

const getProducts = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.product.lastUpdate;
  }
  return dispatch({ type: productActions.GET_PRODUCTS_REQUEST, ...extra });
};
const getProduct = (id) => (dispatch) => dispatch({ type: productActions.GET_PRODUCT_REQUEST, id });
const putProduct = (id, changes) => (dispatch) => dispatch({ type: productActions.PUT_PRODUCT_REQUEST, id, ...changes });
const postProduct = (product) => (dispatch) => dispatch({ type: productActions.POST_PRODUCT_REQUEST, ...product });
const removeProduct = (id) => (dispatch) => dispatch({ type: productActions.DELETE_PRODUCT_REQUEST, id });

export { getProduct, getProducts, putProduct, postProduct, removeProduct };
