import { userActions } from "../actionTypes";

const getUsers = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.user.lastUpdate;
  }
  return dispatch({ type: userActions.GET_USERS_REQUEST, ...extra });
};
const getUser = (id) => (dispatch) => dispatch({ type: userActions.GET_USER_REQUEST, id });
const putUser = (id, changes) => (dispatch) => dispatch({ type: userActions.PUT_USER_REQUEST, id, ...changes });
const postUser = (user) => (dispatch) => dispatch({ type: userActions.POST_USER_REQUEST, ...user });
const removeUser = (id, new_owner) => (dispatch) => dispatch({ type: userActions.DELETE_USER_REQUEST, id, new_owner });

export { getUser, getUsers, putUser, postUser, removeUser };
