import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeScan } from "../../../../data/scan/actions";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import ConfirmDialog from "../../../utils/ConfirmDialog";

const DeleteScanRow = (props) => {
  const { data } = props;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteRow = () => {
    dispatch(removeScan(data._id)).then(() => {
      props.setOpenDeleteScanSnackbar(true);
    });
  };

  return (
    <div>
      <IconButton onClick={() => setConfirmOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog title="Delete Scan?" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteRow}>
        Are you sure you want to delete this scan? This action cannot be undone.
      </ConfirmDialog>
    </div>
  );
};

export default DeleteScanRow;
