import { format } from "date-fns";
import * as Sentry from "@sentry/react";

const debug = true;
const logDates = true;
const fmt = "HH:mm:ss.SSS";
const noop = () => {};

const tag_prefix = `----- `;

function pure_tag(tag) {
  let index = tag.indexOf(" ");
  return index === -1 ? tag : tag.substr(0, index);
}

const _logger = (tag) =>
  function () {
    let arg0 = arguments[0],
      args = Array.prototype.slice.call(arguments, 1),
      first = logDates ? format(new Date(), fmt) + ": " + arg0 : arg0;

    [first, ...args].forEach((arg) => console.log(arg));
  };

const _logError = (tag) =>
  function () {
    [...arguments].forEach((arg) => {
      console.log(`ERROR: ${arg}`);
    });
    console.error(`Error in ${tag}`);
  };

const _logEnter = (tag) =>
  function () {
    let method = arguments[0],
      args = Array.prototype.slice.call(arguments, 1);

    console.group(`${tag_prefix}${tag}::${pure_tag(method)} entered at ${format(new Date(), fmt)}`);
    args.forEach((arg) => console.log(arg));
  };

const _logExit = (tag) =>
  function () {
    let method = arguments[0],
      args = Array.prototype.slice.call(arguments, 1),
      stamp = logDates ? method + " at " + format(new Date(), fmt) : method;
    args.forEach((arg) => console.log(arg));
    console.log(`Exit:${tag}:${stamp}`);
    console.groupEnd();
  };

const baseLogger = (tag) => ({
  info: _logger(tag),
  error: _logError(tag),
  group: (tag) => console.group(tag),
  groupEnd: () => console.groupEnd()
});

const _logger_inactive = (tag) => ({
  ...baseLogger(tag),
  enter: noop,
  exit: noop,
  log: noop
});
//
// const _logger_active = (tag) => ({
//   ...baseLogger(tag),
//   enter: _logEnter(tag),
//   exit: _logExit(tag),
//   log: _logger(tag)
// });
const _logger_active = (tag) => ({
  ...baseLogger(tag),
  enter: _logger(tag),
  exit: _logger(tag),
  log: _logger(tag)
});

const createLogger = (enabled, tag) => {
  if (!debug || !enabled) return { ..._logger_inactive(tag) };

  console.log(`Logging turned on for ${tag}`);
  return _logger_active(tag);
};

function sentryActive() {
  try {
    if (Sentry.getCurrentHub()._stack[0].client) return true;
  } catch (e) {
    return false;
  }
  return false;
}

export { createLogger, sentryActive };
