import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../../../data/user/actions";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import ConfirmDialog from "../../../utils/ConfirmDialog";

const Reassign = (props) => {
  const { data } = props;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteRow = () => {
    dispatch(removeUser(data._id)).then(() => {
      props.setOpenDeleteUserSnackbar(true);
    });
  };

  return <div>HELLO</div>;
};

export default Reassign;
