import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { MenuItem, Menu, IconButton, Divider, ListItemIcon, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import WhatsNew from "./WhatsNew";
import Auth0 from "../../../auth/Auth0";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#000"
  },
  "&.active": {
    color: "#4B9F71"
  },
  "&.hover": {
    color: "#4B9F71"
  },
  root: {
    width: 230
  },
  list: {
    padding: '0',
  }
}));

export default function ProfileMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const openMenu = Boolean(anchorEl);
  const classes = useStyles();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const {
      location: { search }
    } = window;
    const returnTo = encodeURIComponent("http://localhost:3000/logout");
    window.location.href = Auth0.logoutLink(returnTo, true);
  };

  return (
    <div>
      <WhatsNew isOpen={open} onClose={() => setOpen(false)} />
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenu}>
        <MenuIcon />
      </IconButton>
      <Menu
        disableScrollLock={true}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        classes={{list:classes.list}}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={openMenu}
        onClose={handleClose}>
        <MenuItem color="inherit" onClick={() => setOpen(true)}>
          <ListItemIcon>
            <AnnouncementIcon />
          </ListItemIcon>
          <Typography className={classes.link}>What's New?</Typography>
        </MenuItem>
        <Divider />
        <MenuItem color="inherit" onClick={handleLogout}>
          <ListItemIcon>
            <MeetingRoomIcon />
          </ListItemIcon>
          <Typography className={classes.link}>Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
