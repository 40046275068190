import { dashboardActions } from "../actionTypes";
import { createLogger } from "../../utils/logging";
import { epoch } from "../../utils/dataUtils";

const DEBUG = true;
const debug = createLogger(DEBUG, `dashboardReducer.js`);

const initialState = {
  usersData: {},
  usersDataUpdateTime: epoch,
  organizationsData: {},
  organizationsDataUpdateTime: epoch,
  sessionsData: {},
  sessionsDataUpdateTime: epoch
};

const dashboardReducer = (state = initialState, action) => {
  debug.enter("dashboardReducer");
  debug.log(`action(${action.type}) -> `, action);
  let newState = state;

  switch (action.type) {
    case dashboardActions.GET_DASHBOARD__USERS_SUCCESS:
      newState = { ...state, usersData: action.data.object, usersDataUpdateTime: new Date() };
      break;

    case dashboardActions.GET_DASHBOARD__ORGANIZATIONS_SUCCESS:
      newState = { ...state, organizationsData: action.data.object, organizationsDataUpdateTime: new Date() };
      break;

    case dashboardActions.GET_DASHBOARD__SESSIONS_SUCCESS:
      newState = { ...state, sessionsData: action.data.object, sessionsDataUpdateTime: new Date() };
      break;

    default:
      break;
  }
  debug.log("dashboardReducer results: ", newState);
  debug.exit("dashboardReducer");
  return newState;
};

export default dashboardReducer;
