const HOST = process.env.REACT_APP_HOST || window.location.hostname;
const PORT = process.env.REACT_APP_PORT || 8080;

export const getHostPort = (port) => {
  if (process.env.REACT_APP_HOST && process.env.REACT_APP_HOST !== "localhost") {
    // Prod mode
    if (process.env.REACT_APP_PORT === "443") {
      console.log("https - with react_app_port");
      return `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;
    }
    console.log(`https with react_app_port of ${process.env.REACT_APP_PORT}`);
    return `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;
  } else {
    // Dev mode
    // NODE_ENV is set to "production" when you run 'npm run build'
    if (process.env.NODE_ENV === "production") {
      console.log(`https without env port but node_env is prod ${process.env.NODE_ENV}`);
      return parseInt(PORT, 10) === 443 ? `https://${HOST}/${PORT}` : `http://${HOST}:${PORT}`;
    }
    console.log("defaulting to http");
    return port ? `http://${HOST}:${port}` : `http://${HOST}:${PORT}`;
  }
};
