import React, { useState } from "react";
import { Button, Snackbar } from "@material-ui/core";
import AddAuthentagForm from "./AddAuthentagForm";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  snackbar: {
    margin: 0,
    top: "auto",
    right: 0,
    bottom: 40,
    left: "auto",
    position: "fixed"
  }
}));

const AuthentagActions = ({ organization }) => {
  const [showForm, setShowForm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setErrorOpenSnackbar] = useState(null);
  const classes = useStyles();

  const handleClose = (which) => (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (which === "error") {
      setErrorOpenSnackbar(null);
    } else {
      setOpenSnackbar(false);
    }
  };

  return (
    <div className="users-grid-actions">
      <AddAuthentagForm
        isOpen={showForm}
        setOpenSnackbar={setOpenSnackbar}
        setErrorOpenSnackbar={setErrorOpenSnackbar}
        onClose={() => setShowForm(false)}
        organization={organization}
      />
      <Button size="small" id="authentaggrid-addauthentagbutton" onClick={() => setShowForm(true)} style={{ marginRight: "10px" }}>
        Add Authentag
      </Button>
      <Snackbar className={classes.snackbar} open={openSnackbar} autoHideDuration={4000} onClose={handleClose("done")}>
        <Alert onClose={handleClose} severity="success">
          Authentag Successfully Created!
        </Alert>
      </Snackbar>
      <Snackbar className={classes.snackbar} open={openErrorSnackbar} autoHideDuration={4000} onClose={handleClose("error")}>
        <Alert onClose={handleClose} severity="error">
          Recipe is not valid JSON: {openErrorSnackbar}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AuthentagActions;
