import { miscActions } from "../actionTypes";
import { createLogger } from "../../utils/logging";
import lodash from "lodash";

const DEBUG = true;
const debug = createLogger(DEBUG, `miscReducer.js`);

const initialState = {
  docs: {},
  errors: {}
};

const miscReducer = (state = initialState, action) => {
  debug.enter("miscReducer");
  debug.log(`action(${action.type}) -> `, action);
  let newState = state;

  switch (action.type) {
    case miscActions.LOG_ERROR:
      let addErrors = lodash.cloneDeep(newState.errors);
      const route = action.message.route;
      const error = action.message.error;
      addErrors.hasOwnProperty(route) ? addErrors[route].push(error) : (addErrors[route] = [error]);
      newState = { ...newState, errors: addErrors };
      break;

    case miscActions.CLEAR_ERROR:
      let removeErrors = lodash.cloneDeep(newState.errors);
      removeErrors[action.path].splice(action.index, 1);
      newState = { ...newState, errors: removeErrors };
      break;

    default:
      newState = state;
  }
  debug.log("miscReducer results: ", newState);
  debug.exit("miscReducer");
  return newState;
};

export default miscReducer;
