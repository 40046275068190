import { requestActions } from "../actionTypes";
import Moment from "moment";
import { createLogger } from "../../utils/logging";

const DEBUG = false;
const debug = createLogger(DEBUG, `requestsReducer.js`);

const initialState = {
  notificationSocket: null
};

const requestsReducer = (state = initialState, action) => {
  debug.enter("requestsReducer");
  debug.log(`action(${action.type}) -> `, action);
  let newState;

  switch (action.type) {
    case requestActions.SET_NOTIFICATION_SOCKET:
      let now = Moment();
      return { ...state, notificationSocket: action.socket, notifySocketUpdateTime: now };

    default:
      return state;
    // break;
  }
  debug.log("requestsReducer results: ", newState);
  debug.exit("requestsReducer");
};

export default requestsReducer;
