import { combineReducers } from "redux";
import auth from "./auth/reducer";
import user from "./user/reducer";
import organization from "./organization/reducer";
import experiment from "./experiment/reducer";
import product from "./product/reducer";
import demoproduct from "./demoproduct/reducer";
import scan from "./scan/reducer";
import rbac from "./rbac/reducer";
import authentag from "./authentag/reducer";
import misc from "./misc/reducer";
import dashboard from "./dashboard/reducer";
import device from "./device/reducer";
import requests from "./requests/reducer";
import atkit from "./atkit/reducer";

const rootReducer = combineReducers({
  auth,
  misc,
  user,
  organization,
  experiment,
  product,
  demoproduct,
  scan,
  rbac,
  authentag,
  dashboard,
  device,
  requests,
  atkit
});

export default rootReducer;
