import { createLogger } from "../utils/logging";
import { getHostPort } from "../utils/environmentHelper";
import { findRoute, appendFiles, buildActionFromResponse, replacer, processFetchError, logError } from "../utils/middlewaresUtils";
import lodash from "lodash";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

// import * as Sentry from '@sentry/browser';

const DEBUG = true;
const debug = createLogger(DEBUG, `middlewares.js`);

const blobContentTypes = {
  ZIP: "application/zip",
  PDF: "application/pdf",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  JPG: "image/jpeg",
  PNG: "image/png"
};

const ROOT = `api/v1`;

const headers = new Headers();
headers.append(`Access-Control-Allow-Origin`, `*`);

const loggerMiddleware = (store) => (next) => (action) => {
  debug.enter(`ACTION::${action.type}`);
  debug.log("dispatching: ", action);
  debug.log("prev state: ", store.getState());
  try {
    const result = next(action);
    debug.log("next state: ", store.getState());
    debug.exit(`ACTION::${action.type}`);
    return result;
  } catch (err) {
    debug.error(`ERROR ${err}`);
    debug.exit(`ACTION::${action.type}`);
    throw err;
  }

  // return next(action)
};

const crashReporterMiddleware = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    let extra = { extra: { action, state: store.getState() } };
    debug.error("Caught an exception!", err);
    Sentry.captureException(err, extra);
    LogRocket.captureException(err, extra);
    throw err;
  }
};

const generateHeaders = (type) => {
  let key = Object.keys(blobContentTypes).find((key) => type.endsWith(key));
  return blobContentTypes[key] || false;
};

const requestMiddleware = (store) => (next) => (action) => {
  debug.log(`requestMiddleware: store, action`, store, action);
  let { type, ...arg_list } = { ...action };
  let match = type.match(/^(GET|PUT|POST|DELETE)_(.*)_REQUEST$/);
  if (match) {
    let { route, args } = findRoute(match[2], store.getState(), arg_list),
      request = {
        url: `${getHostPort()}/${ROOT}/${route}`,
        type: match[2],
        route: route,
        method: match[1],
        headers: generateHeaders(match[2]) || {}
      },
      // findRoute may find the id if it's in the route string, but if it's not we might also pick it up for the end.
      { _id, id, ...rest } = args;

    id = id || _id;
    debug.log(`Middlewares: ${request.method} method using ${request.url} -- sending (req, id, args): `, request, id, rest);
    console.log(`Middlewares: ${request.method} method using ${request.url} -- sending (req, id, args): `, request, id, rest);

    if (id) {
      request.url += `/${id}`;
    }
    if (Object.keys(rest).length > 0) {
      if (request.method === "GET" || request.method === "DELETE") {
        request.url += `?`;
        Object.entries(rest).forEach(([key, val]) => (request.url += `${key}=${val}&`));
        request.url = request.url.slice(0, request.url.length - 1);
      } else if (request.method === "FORM") {
        request.method = "POST";
        request.body = new FormData();
        Object.entries(rest).forEach((key, value) => {
          key === "files" ? appendFiles(request.body, rest) : request.body.append(key, value);
        });
      } else {
        request.body = new Blob([JSON.stringify(rest, replacer, 2)], {
          type: "application/json"
        });
      }
    }

    // create response parser
    request.parseResponseToAction = buildActionFromResponse(request, action);
    debug.log(`  built request:`, request);
    action.request = request;
  }
  return next(action);
};

const securityMiddleware = (store) => (next) => async (action) => {
  debug.log(`security middleware:`, action);
  if (action.request) {
    let state = store.getState();
    if (state.auth.idToken) {
      action.request.headers = {
        Authorization: `Bearer ${state.auth.idToken}`,
        "Access-Token": `${state.auth.accessToken}`
      };
    }
  }
  return next(action);
};

const apiFetch = async (fetchImpl, request) => {
  debug.log("FETCHMTR awaiting on fetch...");
  const response = await fetchImpl(request.url, {
    method: request.method,
    body: request.body,
    headers: request.headers
  });
  debug.log("FETCHMTR fetched response: ", response);
  console.log("fetched response: ", response);
  let data;
  if (generateHeaders(request.type)) {
    debug.log(`blob type ${request.type}`);
    data = await response.blob();
  } else {
    let json;
    try {
      json = await response.json();
      debug.log(`Response json decoded (isString: ${lodash.isString(json)}): `, json);
    } catch (err) {
      debug.log(`Response JSON error: ${err}`);
      throw err;
    }
    data = lodash.isString(json) ? JSON.parse(json) : json;
  }
  debug.log("apiFetch: returning data, response: ", data, response);
  return { response, data };
};

const handleFetch = (fetchImpl, store, action, unauthorizedAttempts) => {
  return apiFetch(fetchImpl, action.request).then(
    (results) => processResponse(store, action, results, fetchImpl, unauthorizedAttempts),
    (error) => {
      debug.log(`ERROR: request failed for ${action.type}`, error);
      return Promise.reject(processFetchError(store, action, error));
    }
  );
};

const fetchMiddleware = (fetchImpl) => (store) => (next) => async (action) => {
  debug.log("fetchMiddleware: action ", action);
  if (!!action.request) {
    debug.log(`API call: ${action.request.type}`);
    debug.log(`  dispatching request action (${action.type}):`, action);
    next(action);

    return handleFetch(fetchImpl, store, action, 0);
  } else {
    debug.log("next Action");
    return next(action); // pass through for non-requests
  }
};

const processResponse = (store, action, results, fetchImpl, unauthorizedAttempts) => {
  const { response, data } = results;
  debug.log("ProcessResponse: ", results, response, data);
  store.dispatch(action.request.parseResponseToAction(data, response));
  if (response.ok) {
    debug.log(`Response was good - return a resolved promise for ${action.type}`);
    return Promise.resolve(data);
  } else {
    if (!!data) {
      // We need to make sure the back end starts using the standard JSON error response, but for now we patch a standard
      // response -- which has the requirest detail/title fields, and populate "error" that we have been using for now.
      switch (data.status) {
        case 500: // backend crashed
        case 405: // called with invalid args or a bad entry point
          if (!data.error) data.error = "The system had an unexpected error due to invalid arguments or a bad entry point";
          break;
        case 401: // unauthorized, try refreshing id_token from auth0
          // FIXME: should force back to Auth
          debug.log(`401 unauthorized ${action.type}`);
          break;
        default:
          data.error = `${data.title}: ${data.detail}`;
          break;
      }
    }
    if (!!data) logError(store, action, data);

    debug.log(" > API Error -- rejecting with res...");
    return Promise.reject({ response, data });
  }
};

export { loggerMiddleware, crashReporterMiddleware, fetchMiddleware, securityMiddleware, requestMiddleware };
