import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { createLogger } from "../../../../utils/logging";

const DEBUG = true;
const debug = createLogger(DEBUG, `OrgScans.js`);

const OrgScans = ({ orgName, superOrg }) => {
  debug.log("OrgScans render");

  const dashboardOrganizations = useSelector((state) => state.dashboard.organizationsData);

  let today = new Date();

  const options = {
    title: {
      text: "Scans by Organizations"
    },
    subtitle: {
      text: "Last 10 Days"
    },
    series: dashboardOrganizations?.data,
    yAxis: {
      title: {
        text: "Number of Scans"
      }
    },
    xAxis: {
      title: {
        text: "Last 10 days"
      },
      type: "datetime",
      max: Date.parse(today),
      tickInterval: 24 * 3600 * 1000,
      tickWidth: 0,
      gridLineWidth: 1
    },
    plotOptions: {
      series: {
        pointStart: Date.parse(dashboardOrganizations?.start_datetime),
        pointIntervalUnit: "day",
        pointInterval: 1
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  };

  const nonSuperOrgOptions = {
    title: {
      text: `Scans by ${orgName}`
    },
    subtitle: {
      text: "Last 10 Days"
    },
    series: dashboardOrganizations?.data,
    yAxis: {
      title: {
        text: "Number of Scans"
      }
    },
    xAxis: {
      title: {
        text: "Last 10 days"
      },
      type: "datetime",
      max: Date.parse(today),
      tickInterval: 24 * 3600 * 1000,
      tickWidth: 0,
      gridLineWidth: 1
    },
    plotOptions: {
      series: {
        pointStart: Date.parse(dashboardOrganizations?.start_datetime),
        pointIntervalUnit: "day",
        pointInterval: 1
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  };

  return (
    <Fragment>
      <HighchartsReact highcharts={Highcharts} options={superOrg ? options : nonSuperOrgOptions} />
    </Fragment>
  );
};

export default OrgScans;
