import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Select,
  MenuItem,
  ListItemText,
  Collapse,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    float: "right"
  },
  tip: {
    color: "red",
    marginBottom: "10px"
  }
}));

const ConfirmUserDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, reassign, data } = props;
  const [expanded, setExpanded] = useState(false);
  const [newUser, setNewUser] = useState();
  const users = useSelector((state) => state.user.cache);
  const classes = useStyles();

  const sameOrgUsers = users.filter((user) => user.organization_id === data.organization_id && user._id !== data._id);
  let noOtherUsersInOrg = users.filter((user) => user._id !== data._id);

  const handleChange = (event) => {
    setNewUser(event.target.value);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setExpanded(false);
        }}
        aria-labelledby="confirm-dialog">
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}>
            Delete User
          </Button>
          <Button variant="contained" onClick={handleExpandClick}>
            {expanded ? "Close Reassign Window" : "Delete and Reassign"}
          </Button>
          <Button variant="contained" onClick={() => setOpen(false)} color="default">
            Cancel
          </Button>
        </DialogActions>
        <Divider />
        <Collapse in={expanded} unmountOnExit>
          <DialogTitle id="confirm-dialog">Reassign User's Scans and Experiments?</DialogTitle>
          <DialogContent>
            <div className={classes.tip}>
              {sameOrgUsers.length === 0
                ? "Since there are no other users in this organization, you can reassign it to any other user."
                : ""}
            </div>
            <Typography variant="h6">Reassign To:</Typography>
            <Select className={classes.select} value={newUser} onChange={handleChange}>
              {sameOrgUsers.length !== 0
                ? sameOrgUsers.map((user) => (
                    <MenuItem key={user} value={user._id}>
                      <ListItemText primary={user.first_name + " " + user.last_name} />
                    </MenuItem>
                  ))
                : noOtherUsersInOrg.map((user) => (
                    <MenuItem key={user} value={user._id}>
                      <ListItemText primary={user.first_name + " " + user.last_name} />
                    </MenuItem>
                  ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                reassign(newUser);
                setOpen(false);
                setExpanded(!expanded);
              }}>
              Delete and Reassign
            </Button>
          </DialogActions>
        </Collapse>
      </Dialog>
    </div>
  );
};
export default ConfirmUserDialog;
