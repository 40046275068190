import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeOrganization } from "../../../../data/organization/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ConfirmDialog from "../../../utils/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "10px"
  }
}));

const DeleteOrganization = ({ organization, setCurrentOrganizationInfo, setOpenDeleteOrganizationSnackbar }) => {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteOrg = () => {
    dispatch(removeOrganization(organization._id)).then(() => {
      setCurrentOrganizationInfo(null);
      setOpenDeleteOrganizationSnackbar(true);
    });
  };

  return (
    <div>
      <Button color="secondary" className={classes.button} onClick={() => setConfirmOpen(true)}>
        Delete Organization?
      </Button>
      <ConfirmDialog title="Delete Organization?" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteOrg}>
        Are you sure you want to delete this organization? This action cannot be undone.
      </ConfirmDialog>
    </div>
  );
};

export default DeleteOrganization;
