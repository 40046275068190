import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Divider, TextField, Button, DialogTitle, DialogContent, Dialog, DialogActions } from "@material-ui/core";
import { postUser } from "../../../../data/user/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  }
}));

const AddUserForm = ({ isOpen, onClose, organization, setOpenSnackbar }) => {
  const [first_name, setFirstName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const userPackage = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      organization_id: organization._id
    };

    dispatch(postUser(userPackage)).then(() => {
      setOpenSnackbar(true);
      setFirstName(null);
      setLastName(null);
      setEmail(null);
      onClose();
    });
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Add New User
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="first_name"
            name="first_name"
            label="First Name"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            required
            fullWidth
            error={first_name === ""}
            helperText={first_name === "" ? "First name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="last_name"
            name="last_name"
            label="Last Name"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            required
            fullWidth
            error={last_name === ""}
            helperText={last_name === "" ? "Last name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="email"
            name="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            required
            fullWidth
            error={email === ""}
            helperText={email === "" ? "Email is required!" : " "}
            className={classes.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddUserForm;
