import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, Divider, DialogTitle, TextField } from "@material-ui/core";
import { putOrganization } from "../../../../data/organization/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#4B9F71",
    color: "#FFF"
  },
  input: {
    "& label.Mui-focused": {
      color: "#4B9F71"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4B9F71"
    }
  },
  button: {
    marginTop: "10px"
  }
}));

const EditOrganizationForm = ({ organization, setCurrentOrganizationInfo, setOpenUpdateOrganizationSnackbar }) => {
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState(organization.name);
  const [description, setDescription] = useState(organization.description);
  const [logo, setLogo] = useState(organization.logo);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleName = (e) => setName(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleLogo = (e) => setLogo(e.target.value);

  const id = organization._id;

  const handleSubmit = (e) => {
    e.preventDefault();

    const editedUser = {
      name: name,
      description: description,
      logo: logo
    };

    dispatch(putOrganization(id, editedUser)).then(() => {
      setName(name);
      setDescription(description);
      setLogo(logo);
      setShowForm(false);
      setCurrentOrganizationInfo(null);
      setOpenUpdateOrganizationSnackbar(true);
    });
  };

  return (
    <div>
      <Dialog open={showForm} onClose={() => setShowForm(false)} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Update Organization
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            key="name"
            name="name"
            label="First Name"
            value={name}
            onChange={handleName}
            type="text"
            required
            fullWidth
            error={name === ""}
            helperText={name === "" ? "Name is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="description"
            name="description"
            label="Description"
            value={description}
            onChange={handleDescription}
            type="text"
            required
            fullWidth
            error={description === ""}
            helperText={description === "" ? "Description is required!" : " "}
            className={classes.input}
          />
          <TextField
            margin="dense"
            key="logo"
            name="logo"
            label="Logo URL"
            value={logo}
            onChange={handleLogo}
            type="logo"
            required
            fullWidth
            error={logo === ""}
            helperText={logo === "" ? "Logo URL is required!" : " "}
            className={classes.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={() => setShowForm(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setShowForm(true)} className={classes.button}>
        Edit Organization?
      </Button>
    </div>
  );
};

export default EditOrganizationForm;
