import { demoProductActions } from "../actionTypes";

const getDemoProducts = (updates) => (dispatch, getState) => {
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.product.lastUpdate;
  }
  return dispatch({ type: demoProductActions.GET_DEMO_PRODUCTS_REQUEST, ...extra });
};
const getDemoProduct = (id) => (dispatch) => dispatch({ type: demoProductActions.GET_DEMO_PRODUCT_REQUEST, id });
const putDemoProduct = (id, changes) => (dispatch) => dispatch({ type: demoProductActions.PUT_DEMO_PRODUCT_REQUEST, id, ...changes });
const postDemoProduct = (demo_product) => (dispatch) => dispatch({ type: demoProductActions.POST_DEMO_PRODUCT_REQUEST, ...demo_product });
const removeDemoProduct = (id) => (dispatch) => dispatch({ type: demoProductActions.DELETE_DEMO_PRODUCT_REQUEST, id });

export { getDemoProduct, getDemoProducts, putDemoProduct, postDemoProduct, removeDemoProduct };
