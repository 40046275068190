import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createLogger } from "../../../utils/logging";
import OrganizationCard from "./OrganizationCard";
import { Button, Grid, Snackbar } from "@material-ui/core";
import OrganizationShow from "./OrganizationShow";
import AddOrganizationForm from "./subcomponents/AddOrganizationForm";
import { useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

const DEBUG = true;
const debug = createLogger(DEBUG, `Organizations.js`);

const useStyles = makeStyles((theme) => ({
  organizationsTab: {
    marginTop: "10px"
  },
  snackbar: {
    margin: 0,
    top: "auto",
    right: 0,
    bottom: 40,
    left: "auto",
    position: "fixed"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Organizations = ({ currentOrganizationInfo, changeOrg, setCurrentOrganizationInfo }) => {
  const organizations = useSelector((state) => state.organization.cache);
  const superOrg = useSelector((state) => state.auth.organization.super_org);

  const [showForm, setShowForm] = useState(false);
  const [openUpdateOrganizationSnackbar, setOpenUpdateOrganizationSnackbar] = useState(false);
  const [openDeleteOrganizationSnackbar, setOpenDeleteOrganizationSnackbar] = useState(false);
  const [openCreateOrganizationSnackbar, setOpenCreateOrganizationSnackbar] = useState(false);

  const classes = useStyles();

  const handleOrgSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenUpdateOrganizationSnackbar(false);
    setOpenDeleteOrganizationSnackbar(false);
    setOpenCreateOrganizationSnackbar(false);
  };

  debug.log("Organizations render");

  if (superOrg) {
    if (currentOrganizationInfo != null) {
      return (
        <div>
          <OrganizationShow
            organization={currentOrganizationInfo}
            setCurrentOrganizationInfo={setCurrentOrganizationInfo}
            handleOrgSuccessClose={handleOrgSuccessClose}
            setOpenUpdateOrganizationSnackbar={setOpenUpdateOrganizationSnackbar}
            setOpenDeleteOrganizationSnackbar={setOpenDeleteOrganizationSnackbar}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.organizationsTab}>
          <Button onClick={() => setShowForm(true)} variant="outlined" style={{ fontWeight: "bold" }}>
            Create New Organization
          </Button>

          <AddOrganizationForm
            isOpen={showForm}
            onClose={() => setShowForm(false)}
            setOpenCreateOrganizationSnackbar={setOpenCreateOrganizationSnackbar}
          />

          <Grid container spacing={5} style={{ padding: 24 }} className="organizationsGrid">
            {organizations.map((organization, index) => (
              <Grid key={index} item xs={6} sm={6} onClick={changeOrg(organization)}>
                <OrganizationCard organization={organization} />
              </Grid>
            ))}
          </Grid>
          <Snackbar
            className={classes.snackbar}
            open={openUpdateOrganizationSnackbar}
            autoHideDuration={4000}
            onClose={handleOrgSuccessClose}>
            <Alert onClose={handleOrgSuccessClose} severity="success">
              Organization Updated Successfully!
            </Alert>
          </Snackbar>
          <Snackbar
            className={classes.snackbar}
            open={openDeleteOrganizationSnackbar}
            autoHideDuration={4000}
            onClose={handleOrgSuccessClose}>
            <Alert onClose={handleOrgSuccessClose} severity="success">
              Organization Deleted Successfully!
            </Alert>
          </Snackbar>
          <Snackbar
            className={classes.snackbar}
            open={openCreateOrganizationSnackbar}
            autoHideDuration={4000}
            onClose={handleOrgSuccessClose}>
            <Alert onClose={handleOrgSuccessClose} severity="success">
              Organization Successfully Created!
            </Alert>
          </Snackbar>
        </div>
      );
    }
  } else {
    return (
      <div>
        <OrganizationShow
          organization={organizations[0]}
          setCurrentOrganizationInfo={setCurrentOrganizationInfo}
          handleOrgSuccessClose={handleOrgSuccessClose}
          setOpenUpdateOrganizationSnackbar={setOpenUpdateOrganizationSnackbar}
          setOpenDeleteOrganizationSnackbar={setOpenDeleteOrganizationSnackbar}
        />
      </div>
    );
  }
};

export default Organizations;
