import React from "react";
import { useSelector } from "react-redux";
import { createLogger } from "../../../utils/logging";
import "./Tabler.css";
import { makeStyles } from "@material-ui/core/styles";
import { TabbedCard, Tab, Grid } from "tabler-react";
import OrgScans from "./charts/OrgScans";
import UserScans from "./charts/UserScans";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0 from "../../../auth/Auth0";
import DashStats from "./subcomponents/DashStats";

const DEBUG = true;
const debug = createLogger(DEBUG, `Dashboard.js`);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px"
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  const dashboardSessions = useSelector((state) => state.dashboard.sessionsData);
  const superOrg = useSelector((state) => state.auth.organization.super_org);
  const orgName = useSelector((state) => state.auth.organization.name);

  debug.log("Dashboard render()");

  const orgNameTab = `Scans by ${orgName} - Last 10 Days`;

  if (isAuthenticated) {
    if (superOrg) {
      return (
        <div className={classes.root}>
          <Grid>
            <Grid.Row>{dashboardSessions && <DashStats />}</Grid.Row>
            <Grid.Row>
              <TabbedCard initialTab="Scans by Organizations - Last 10 Days">
                <Tab title="Scans by Organizations - Last 10 Days">
                  <OrgScans statistic="revenue" datatype="summary" />
                </Tab>
              </TabbedCard>
            </Grid.Row>
            <Grid.Row>
              <TabbedCard initialTab="Scans by Users - Last 10 Days">
                <Tab title="Scans by Users - Last 10 Days">
                  <UserScans statistic="revenue" datatype="summary" />
                </Tab>
              </TabbedCard>
            </Grid.Row>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <Grid>
            <Grid.Row>{dashboardSessions && <DashStats />}</Grid.Row>
            <Grid.Row>
              <TabbedCard initialTab={orgNameTab}>
                <Tab title={orgNameTab}>
                  <OrgScans statistic="revenue" datatype="summary" orgName={orgName} superOrg={superOrg} />
                </Tab>
              </TabbedCard>
            </Grid.Row>
            <Grid.Row>
              <TabbedCard initialTab="Scans by Users - Last 10 Days">
                <Tab title="Scans by Users - Last 10 Days">
                  <UserScans statistic="revenue" datatype="summary" />
                </Tab>
              </TabbedCard>
            </Grid.Row>
          </Grid>
        </div>
      );
    }
  } else {
    debug.log("We should be redirecting to auth0...");
    return Auth0.authRedirect();
  }
};
export default Dashboard;
