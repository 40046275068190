import { authActions } from "../actionTypes";
// import {createLogger} from "../../utils/logging";
//
// const DEBUG = true;
// const debug = createLogger(DEBUG, `AuthAction.js`);

const setIdent = (idToken) => (dispatch) => dispatch({ type: authActions.SET_ID_TOKEN, idToken });

const postSession = (session) => (dispatch) => dispatch({ ...session, type: authActions.POST_SESSION_REQUEST });

const putSession = (id, updates) => (dispatch) => dispatch({ type: authActions.PUT_SESSION_REQUEST, id, ...updates });

export { postSession, putSession, setIdent };
