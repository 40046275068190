import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    background: "#4B9F71",
    color: "white"
  }
}));

const ExperimentNotesImage = (props) => {
  const classes = useStyles();
  const { data } = props;

  if (data) {
    return <Button className={classes.button}>Notes Image</Button>;
  }
  return null;
};

export default ExperimentNotesImage;
