import { atkitActions } from "../actionTypes";

const getAtkitVersions = (updates) => (dispatch, getState) => {
  console.log("calling for ATKIT versions....");
  let extra = {};
  if (updates) {
    const state = getState();
    extra.update_time = state.device.lastUpdate;
  }
  return dispatch({ type: atkitActions.GET_ATKITS_REQUEST, ...extra });
};

export { getAtkitVersions };
