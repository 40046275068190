import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import registerServiceWorker from "./utils/registerServiceWorker";
//import './styles/index.css';
import "typeface-roboto";
import { LicenseManager, AllModules, ModuleRegistry } from "@ag-grid-enterprise/all-modules";

// FIXME: We need to get C2sense a license here eventually.
LicenseManager.setLicenseKey(
  "CompanyName=Too Symphony Solutions, LLC,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-013708,ExpiryDate=19_March_2022_[v2]_MTY0NzY0ODAwMDAwMA==52bc1a5e9ab50cab2d5f2766e0e53d9c"
);
ModuleRegistry.registerModules(AllModules);

// Sentry.init({dsn: "https://80a178d8033d4e278b90df45a017b1d3@sentry.io/1824681"});

ReactDOM.render(<App />, document.getElementById("root"));

registerServiceWorker();
