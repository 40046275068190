import React from "react";
import { useSelector } from "react-redux";
import { createLogger } from "../../../../utils/logging";
import { Grid, ProgressCard, Tab, TabbedCard } from "tabler-react";
import Pie from "../charts/Pie.js";

const DEBUG = true;
const debug = createLogger(DEBUG, `DashStats.js`);

const DashStats = () => {
  debug.log("render DashStats");

  const superOrg = useSelector((state) => state.auth.organization.super_org);
  const dashboardSessions = useSelector((state) => state.dashboard.sessionsData);

  const stats = dashboardSessions.data;

  if (superOrg) {
    return (
      <TabbedCard initialTab="ACME Rockets">
        {stats.map((organization) => (
          <Tab title={organization.name}>
            <Grid.Row cards deck>
              <Grid.Col>
                <ProgressCard header="Users" content={organization.user_count} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
              <Grid.Col>
                <ProgressCard header="Products" content={organization.product_count} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
              <Grid.Col>
                <ProgressCard header="Prod Scans" content={organization.product_scans} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
              <Grid.Col>
                <ProgressCard header="Experiments" content={organization.experiment_count} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
              <Grid.Col>
                <ProgressCard header="Exp Scans" content={organization.experiment_scans} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Pie
                  title="Product Scans"
                  series={[
                    { name: "Successes", y: organization.product_scan_successes, color: "#4B9F71" },
                    { name: "Failures", y: organization.product_scan_failures, color: "#676767" }
                  ]}
                />
              </Grid.Col>
              <Grid.Col>
                <Pie
                  title="Experiment Scans"
                  series={[
                    { name: "Successes", y: organization.experiment_scan_successes, color: "#4B9F71" },
                    { name: "Failures", y: organization.experiment_scan_failures, color: "#676767" }
                  ]}
                />
              </Grid.Col>
            </Grid.Row>
          </Tab>
        ))}
      </TabbedCard>
    );
  } else {
    return (
      <TabbedCard initialTab="ACME Rockets">
        {stats.map((organization) => (
          <Tab title={organization.name}>
            <Grid.Row cards deck>
              <Grid.Col>
                <ProgressCard header="Users" content={organization.user_count} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
              <Grid.Col>
                <ProgressCard header="Experiments" content={organization.experiment_count} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
              <Grid.Col>
                <ProgressCard header="Exp Scans" content={organization.experiment_scans} progressColor="#4B9F71" progressWidth={100} />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Pie
                  title="Experiment Scans"
                  series={[
                    { name: "Successes", y: organization.experiment_scan_successes, color: "#4B9F71" },
                    { name: "Failures", y: organization.experiment_scan_failures, color: "#676767" }
                  ]}
                />
              </Grid.Col>
            </Grid.Row>
          </Tab>
        ))}
      </TabbedCard>
    );
  }
};

export default DashStats;
