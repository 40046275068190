import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createLogger } from "../../utils/logging";
import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { defaultGridConfig } from "./config/defaultGridConfig";
import { Typography, Button, Dialog, DialogTitle, DialogContent, TextField, IconButton, InputAdornment } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Auth0 from "../../auth/Auth0";
import { useAuth0 } from "@auth0/auth0-react";
import ReactMarkdown from "react-markdown";
import { useFormik } from "formik";
import * as yup from "yup";
import { putDemoProduct, removeDemoProduct, postDemoProduct } from "../../data/demoproduct/actions";
import TrashIcon from "@material-ui/icons/Delete";
import lodash from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faWind, faFingerprint, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const DEBUG = true;
const debug = createLogger(DEBUG, `DemoProductsGrid.js`);

const useStyles = makeStyles((theme) => ({
  modeBox: {
    margin: "10px",
    width: "12vw"
  }
}));

// for display, matches index of drop down values from rnIcons
const icons = [faCheckCircle, faTimesCircle, faWind, faFingerprint];
// for drop down list selection, set success / failure icons
const rnIcons = ["check-circle", "times-circle", "wind", "fingerprint"];

const DemoProductsGrid = () => {
  const { isAuthenticated } = useAuth0();
  const organizations = useSelector((state) => state.organization.cache);
  const demoproducts = useSelector((state) => state.demoproduct.cache);
  const authentags = useSelector((state) => state.authentag.cache);

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [configData, setConfigData] = useState(null);
  const [selectedDemoProduct, setSelectedDemoProduct] = useState(null);

  const [selectedAuthentag, setSelectedAuthentag] = useState(authentags[0] ?? null);
  const [selectedAuthentagName, setSelectedAuthentagName] = useState("Select Recipe");
  const [selectedOrganization, setSelectedOrganization] = useState(organizations[0] ?? null);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState(organizations[0]["_id"] ?? null);

  const [successIcon, setSuccessIcon] = useState("");
  const [failureIcon, setFailureIcon] = useState("");
  const [successIcon2, setSuccessIcon2] = useState("");
  const [failureIcon2, setFailureIcon2] = useState("");
  const [tagDisplays, setTagDisplays] = useState(0);

  const gridRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  var descriptionHTML = "";

  const iconElement = <FontAwesomeIcon icon={faWind} />;

  const validationSchema = yup.object({
    productname: yup.string("Demo Product Name").min(2, "minimum 2 characters").required("name is required"),
    productHeader: yup.string("Header").min(2, "minimum 2 characters").required("header is required"),
    productSubHeader: yup.string("Subheader").min(2, "minimum 2 characters"),
    tagName: yup.string("Tag Name"),
    tagName2: yup.string("Tag 2 Name"),
    failureText: yup.string("Failure Text"),
    successText: yup.string("Success Text"),
    failureText2: yup.string("Failure Text 2"),
    successText2: yup.string("Success Text 2")
  });

  const formik = useFormik({
    initialValues: {
      productname: "name",
      productHeader: "header",
      productSubHeader: "sub header",
      tagName: "Tag Name",
      tagName2: "Tag 2 Name",
      failureText: "Failure Text",
      successText: "Success Text",
      failureText2: "Failure Text 2",
      successText2: "Success Text 2"
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsUpdating(true);
      let product = getCurrentDemoProductFromFormValues();
      //console.log(`updated demo product ${descriptionHTML} ======= ${JSON.stringify(product)}`);
      dispatch(putDemoProduct(selectedDemoProduct["_id"], product))
        .then(() => {
          updateProductsList();
          alert("PUT success!!");
        })
        .catch(() => {
          alert("PUT failed ---- !!");
        })
        .finally(() => {
          setIsUpdating(false);
        });
    }
  });

  const getCurrentDemoProductFromFormValues = () => {
    let tags = [];
    if (tagDisplays >= 1) {
      const tagObj1 = {
        tag_name: formik.values.tagName,
        success_background_color: "#f4f4f4",
        success_foreground_color: "#4D9F71",
        success_icon: successIcon,
        success_text: formik.values.successText,
        failure_background_color: "#f4f4f4",
        failure_foreground_color: "#B02318",
        failure_icon: failureIcon,
        failure_text: formik.values.failureText
      };
      tags.push(tagObj1);
    }
    if (tagDisplays === 2) {
      const tagObj2 = {
        tag_name: formik.values.tagName2,
        success_background_color: "#f4f4f4",
        success_foreground_color: "#4D9F71",
        success_icon: successIcon2,
        success_text: formik.values.successText2,
        failure_background_color: "#f4f4f4",
        failure_foreground_color: "#B02318",
        failure_icon: failureIcon2,
        failure_text: formik.values.failureText2
      };
      tags.push(tagObj2);
    }
    descriptionHTML =
      "<html><head><meta  name='viewport' content='width=device-width, initial-scale=1'><style type='text/css'> body { font-family: HelveticaNeue-Light; font-size: 1.25rem; color: black; padding: 0px 10px 10px 10px; } p { text-align: center; } </style></head><body style='color:#535353; background-color:#f4f4f4;'><div style='height:100%; width:100%;  font-size: 16;'><center><h1 style='margin-bottom:-30; margin-top: 55px;'>" +
      formik.values.productHeader +
      "</h1></center><center><p style='margin-top: 35px; font-size: 30; '>O<sub>2</sub>" +
      formik.values.productSubHeader +
      "</p></center></div></body></html>";

    return {
      organization_id: selectedOrganization["_id"],
      authentag_id: selectedAuthentag["_id"],
      name: formik.values.productname,
      header: formik.values.productHeader,
      subheader: formik.values.productSubHeader,
      description: descriptionHTML,
      tag_display: tags.length > 0 ? tags : JSON.parse("[]")
    };
  };

  const deleteDemoProduct = () => {
    if (selectedDemoProduct) {
      setIsUpdating(true);
      dispatch(removeDemoProduct(selectedDemoProduct["_id"]))
        .then(() => {
          updateProductsList();
          alert("DELETE success!!");
        })
        .catch(() => {
          alert("DELETE failed ---- !!");
        })
        .finally(() => {
          setIsUpdating(false);
        });
    }
  };

  const postNewDemoProduct = () => {
    if (selectedOrganization && selectedAuthentag && formik.isValid) {
      setIsUpdating(true);
      let product = getCurrentDemoProductFromFormValues();
      dispatch(postDemoProduct(product))
        .then(() => {
          updateProductsList();
          alert("POST success!!");
        })
        .catch(() => {
          alert("POST failed ---- !!");
        })
        .finally(() => {
          setIsUpdating(false);
        });
    } else {
      alert("ERROR - No Organization or Recipe was selected!");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateProductsList = () => {
    let sortedArr = [];
    organizations
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((org) => {
        let demoproductsList = demoproducts.filter((demoproduct) => demoproduct.organization_id === org["_id"]);
        let newDemoProductsList = [];

        demoproductsList.forEach((demoproduct) => {
          let newDemoProduct = { ...demoproduct };

          newDemoProduct["orgName"] = org["name"];
          newDemoProduct["date"] = newDemoProduct._inserted.toDateString();
          newDemoProduct["name"] = newDemoProduct.name;

          newDemoProductsList.push(newDemoProduct);
        });
        sortedArr.push(...newDemoProductsList);
      });
    setRowData(sortedArr);
    setSelectedAuthentag(authentags[0]);
  };

  useEffect(() => {
    updateProductsList();
  }, []);

  const displayString = "No Config Available";

  const columnDefs = [
    { headerName: "Organization", field: "orgName", rowGroup: true, hide: true },
    { headerName: "Demo Product", field: "name", width: 40, tooltipField: "name" },
    { headerName: "ID", field: "_id", hide: true },
    { headerName: "Description", field: "description", width: 60, tooltipField: "description" },
    { headerName: "Auth ID", field: "authentag_id", width: 30, tooltipField: "authentag id" },
    { headerName: "Tag Display", field: "tag_display", width: 60, tooltipField: "tag display" }
  ];

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const gridConfig = {
    ...defaultGridConfig,
    // ref: gridRef,
    // onSelectionChanged: onSelectionChanged,
    onGridReady,
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: true,
      filter: true,
      resizable: true
    },
    autoGroupColumnDef: {
      minWidth: 200,
      cellRenderer: "agGroupCellRenderer"
    },
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
    groupMultiAutoColumn: true,
    enableRangeSelection: true,
    animateRows: true,
    rowData: rowData,
    columnDefs: columnDefs,
    getRowNodeId: (data) => data._id,
    rowSelection: "single",
    alwaysShowVerticalScroll: true,
    suppressHorizontalScroll: false,
    scrollbarWidth: 10,
    rowHeight: 50
  };

  const stringSplitter = (start, end, value) => {
    return value.split(start)[1].split(end)[0];
  };

  useEffect(() => {
    if (selectedDemoProduct && formik) {
      formik.setFieldValue("productname", selectedDemoProduct["name"]);
      formik.setFieldValue("background", selectedDemoProduct["product_background"]);
      formik.setFieldValue("foreground", selectedDemoProduct["product_foreground"]);

      const desc = selectedDemoProduct["description"];
      if (desc && desc.includes(`html>`) && desc.includes(`55px;'>`) && desc.includes(`/sub>`)) {
        const header = stringSplitter(`55px;'>`, `<`, desc);
        const subHeader = stringSplitter(`/sub>`, `<`, desc);
        formik.setFieldValue("productHeader", header ?? "header");
        formik.setFieldValue("productSubHeader", subHeader ?? "sub header");
      } else {
        formik.setFieldValue("productHeader", desc ?? "header");
      }

      if (selectedDemoProduct["tag_display"][0]) {
        formik.setFieldValue("tagName", selectedDemoProduct["tag_display"][0]["tag_name"] ?? "");
        formik.setFieldValue("successText", selectedDemoProduct["tag_display"][0]["success_text"] ?? "");
        formik.setFieldValue("failureText", selectedDemoProduct["tag_display"][0]["failure_text"] ?? "");
        const successIc = lodash.find(rnIcons, function (o) {
          return o.includes(selectedDemoProduct["tag_display"][0]["success_icon"]);
        });
        const failIc = lodash.find(rnIcons, function (o) {
          return o.includes(selectedDemoProduct["tag_display"][0]["failure_icon"]);
        });
        setSuccessIcon(successIc ?? rnIcons[0]);
        setFailureIcon(failIc ?? rnIcons[0]);
      }

      if (selectedDemoProduct["tag_display"][1]) {
        formik.setFieldValue("tagName2", selectedDemoProduct["tag_display"][1]["tag_name"] ?? "");
        formik.setFieldValue("successText2", selectedDemoProduct["tag_display"][1]["success_text"] ?? "");
        formik.setFieldValue("failureText2", selectedDemoProduct["tag_display"][1]["failure_text"] ?? "");
        const successIc2 = lodash.find(rnIcons, function (o) {
          return o.includes(selectedDemoProduct["tag_display"][1]["success_icon"]);
        });
        const failIc2 = lodash.find(rnIcons, function (o) {
          return o.includes(selectedDemoProduct["tag_display"][1]["failure_icon"]);
        });
        setSuccessIcon2(successIc2 ?? rnIcons[0]);
        setFailureIcon2(failIc2 ?? rnIcons[0]);
      }

      if (selectedDemoProduct["tag_display"][0]) {
        console.log(`selectedDemoProduct["tag_display"].length ${selectedDemoProduct["tag_display"].length}`);
        setTagDisplays(selectedDemoProduct["tag_display"].length);
      }
    }
  }, [selectedDemoProduct]);

  useEffect(() => {
    //console.log(`selectedAuthentag ${JSON.stringify(selectedAuthentag)}`);
  }, [selectedAuthentag]);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    // populate the form...
    if (selectedRows.length === 1) {
      setSelectedDemoProduct(selectedRows[0]);
    }
    // set the recipe dropdown value if recipe match...
    let filtered = authentags.filter((authentag) => authentag["_id"] === selectedRows[0]["authentag_id"]);
    if (filtered && filtered.length === 1) {
      setSelectedAuthentag(filtered[0]);
      setSelectedAuthentagName(filtered[0]["_id"]);
    }
    // set the org drop down value
    let filteredOrg = organizations.filter((organization) => organization["_id"] === selectedRows[0]["organization_id"]);
    if (filteredOrg && filteredOrg.length === 1) {
      setSelectedOrganization(filteredOrg[0]);
      setSelectedOrganizationName(filteredOrg[0]["_id"]);
    }
  }, []);

  const handleAuthentagSelectionChange = (e) => {
    let filtered = authentags.filter((authentag) => authentag["_id"] === e.target.value);
    if (filtered && filtered.length > 0) {
      setSelectedAuthentag(filtered[0]);
      setSelectedAuthentagName(filtered[0]["_id"]);
    }
  };

  const handleOrganizationSelectionChange = (e) => {
    let filtered = organizations.filter((organization) => organization["_id"] === e.target.value);
    if (filtered && filtered.length > 0) {
      setSelectedOrganization(filtered[0]);
      setSelectedOrganizationName(filtered[0]["_id"]);
    }
  };

  const handleTagDisplaysChange = (e) => {
    let tagDisplaysCopy = tagDisplays;
    setTagDisplays(Number(e.target.value) > 1 ? 2 : Number(e.target.value));
  };

  const handleSuccessIconSelectionChange = (e) => {
    setSuccessIcon(e.target.value);
  };

  const handleFailureIconSelectionChange = (e) => {
    setFailureIcon(e.target.value);
  };

  const handleSuccessIcon2SelectionChange = (e) => {
    setSuccessIcon2(e.target.value);
  };

  const handleFailureIcon2SelectionChange = (e) => {
    setFailureIcon2(e.target.value);
  };

  const exportToExcel = () => {
    gridApi.exportDataAsExcel();
  };

  const DemoDropDown = (props) => {
    const { labelTxt, value, onFunc, data } = props;
    return (
      <TextField
        id="outlined-select-currency-native"
        className={classes.modeBox}
        select
        style={{ width: "30%" }}
        label={labelTxt}
        value={value}
        onChange={onFunc}
        SelectProps={{
          native: true
        }}
        variant="outlined">
        {data.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </TextField>
    );
  };

  const DemoIconDropDown = (props) => {
    const { labelTxt, icon, onFunc } = props;
    return (
      <>
        <TextField
          id="outlined-select-currency-native"
          className={classes.modeBox}
          select
          style={{ width: "25%" }}
          label={labelTxt}
          value={rnIcons[rnIcons.indexOf(icon)] ?? rnIcons[0]}
          onChange={onFunc}
          SelectProps={{
            native: true
          }}
          variant="outlined">
          {rnIcons.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </>
    );
  };

  const DemoFormikTextField = (props) => {
    const { id, label, widthPercentage } = props;
    return (
      <TextField
        style={{ width: widthPercentage }}
        id={id}
        name={id}
        label={label}
        value={formik.values[id]}
        onChange={formik.handleChange}
        error={formik.touched[id] && Boolean(formik.errors[id])}
        helperText={formik.touched[id] && formik.errors[id]}
      />
    );
  };

  if (isAuthenticated) {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Typography gutterBottom variant="h4" component="h2">
          Demo Products
        </Typography>
        <div
          id="demoProductsGrid"
          style={{
            height: 300,
            width: "100%"
          }}
          className="ag-theme-alpine">
          <AgGridReact {...gridConfig} onSelectionChanged={onSelectionChanged} ref={gridRef} />
          <Dialog
            fullWidth="md"
            maxWidth="md"
            scroll="body"
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle>Config</DialogTitle>
            <DialogContent dividers>
              <ReactMarkdown source={displayString} children={displayString} />
            </DialogContent>
          </Dialog>
        </div>
        <div
          style={{
            height: 90,
            width: "100%"
          }}
        />
        <div
          class="flexbox-container"
          style={{
            height: 50,
            width: "100%",
            flexDirection: "row",
            alignContent: "space-between"
          }}>
          <Typography gutterBottom variant="h4" component="h3">
            Demo Product Editor
          </Typography>
          <div style={{ float: "right" }}>
            <IconButton style={{ height: 60, width: 60 }} onClick={() => deleteDemoProduct()} disabled={isUpdating}>
              <TrashIcon />
            </IconButton>
          </div>
        </div>
        <div
          id="demoProductsEditor"
          style={{
            height: 700,
            width: "100%",
            padding: 0,
            marginLeft: 0
          }}>
          {/*<span id="selectedDemoProduct"></span>*/}
          <form onSubmit={formik.handleSubmit}>
            <div style={{ height: 60, width: "100%", marginRight: 20, marginBottom: 30 }}>
              <DemoDropDown
                labelTxt={"Select Organization"}
                value={selectedOrganizationName}
                onFunc={handleOrganizationSelectionChange}
                data={organizations}
              />
              <TextField
                id="outlined-select-currency-native"
                className={classes.modeBox}
                select
                style={{ width: "40%" }}
                label="Select Authentag"
                value={selectedAuthentagName}
                onChange={handleAuthentagSelectionChange}
                SelectProps={{
                  native: true
                }}
                variant="outlined">
                {authentags
                  .filter((tag) => tag["organization_id"] === selectedOrganizationName)
                  .map((option) => (
                    <option key={option._id} value={option._id}>
                      {`${option.name} - AT ver (${option.supported_versions[option.supported_versions.length - 1]})`}
                    </option>
                  ))}
              </TextField>
            </div>
            {/*<DemoFormikTextField id={"productname"} label={"Demo Product Name"} widthPercentage={"60%"} />*/}
            <TextField
              style={{ width: "60%" }}
              id="productname"
              name="productname"
              label="Demo Product Name"
              value={formik.values.productname}
              onChange={formik.handleChange}
              error={formik.touched.productname && Boolean(formik.errors.productname)}
              helperText={formik.touched.productname && formik.errors.productname}
            />
            <TextField
              style={{ width: "60%" }}
              id="productHeader"
              name="productHeader"
              label="Header"
              value={formik.values.productHeader}
              onChange={formik.handleChange}
              error={formik.touched.productHeader && Boolean(formik.errors.productHeader)}
              helperText={formik.touched.productHeader && formik.errors.productHeader}
            />
            <TextField
              style={{ width: "60%" }}
              id="productSubHeader"
              name="productSubHeader"
              label="Sub header"
              value={formik.values.productSubHeader}
              onChange={formik.handleChange}
              error={formik.touched.productSubHeader && Boolean(formik.errors.productSubHeader)}
              helperText={formik.touched.productSubHeader && formik.errors.productSubHeader}
            />
            {/*<TextField*/}
            {/*  style={{ width: "60%" }}*/}
            {/*  id="background"*/}
            {/*  name="background"*/}
            {/*  label="Background"*/}
            {/*  value={formik.values.background}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  error={formik.touched.background && Boolean(formik.errors.background)}*/}
            {/*  helperText={formik.touched.background && formik.errors.background}*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*  style={{ width: "60%" }}*/}
            {/*  id="foreground"*/}
            {/*  name="foreground"*/}
            {/*  label="Foreground"*/}
            {/*  value={formik.values.foreground}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  error={formik.touched.foreground && Boolean(formik.errors.foreground)}*/}
            {/*  helperText={formik.touched.foreground && formik.errors.foreground}*/}
            {/*/>*/}
            <div style={{ height: 60, width: "100%", marginTop: 30, marginBottom: 30, alignItems: "center" }}>
              <TextField
                id="tagDisplays"
                label="tagDisplays"
                type="number"
                InputProps={{
                  inputProps: {
                    max: 2,
                    min: 0
                  }
                }}
                value={tagDisplays}
                onChange={handleTagDisplaysChange}
                placeholder=""
                margin="normal"
              />
            </div>
            {tagDisplays >= 1 && (
              <div>
                <div style={{ height: 60, width: "100%", marginRight: 20, marginBottom: 30 }}>
                  <DemoIconDropDown labelTxt={"Success Icon"} icon={successIcon} onFunc={handleSuccessIconSelectionChange} />
                  <FontAwesomeIcon icon={icons[rnIcons.indexOf(successIcon)]} size="5x" color={"#4D9F71"} />
                  <DemoIconDropDown labelTxt={"Failure Icon"} icon={failureIcon} onFunc={handleFailureIconSelectionChange} />
                  <FontAwesomeIcon icon={icons[rnIcons.indexOf(failureIcon)]} size="5x" color={"#B02318"} />
                </div>
                <TextField
                  style={{ width: "60%" }}
                  id="tagName"
                  name="tagName"
                  label="Tag Name"
                  value={formik.values.tagName}
                  onChange={formik.handleChange}
                  error={formik.touched.tagName && Boolean(formik.errors.tagName)}
                  helperText={formik.touched.tagName && formik.errors.tagName}
                />
                <TextField
                  style={{ width: "60%" }}
                  id="successText"
                  name="successText"
                  label="Success Text"
                  value={formik.values.successText}
                  onChange={formik.handleChange}
                  error={formik.touched.successText && Boolean(formik.errors.successText)}
                  helperText={formik.touched.successText && formik.errors.successText}
                />
                <TextField
                  style={{ width: "60%" }}
                  id="failureText"
                  name="failureText"
                  label="Failure Text"
                  value={formik.values.failureText}
                  onChange={formik.handleChange}
                  error={formik.touched.failureText && Boolean(formik.errors.failureText)}
                  helperText={formik.touched.failureText && formik.errors.failureText}
                />
              </div>
            )}
            {tagDisplays === 2 && (
              <div>
                <div style={{ height: 60, width: "100%", marginTop: 30, marginBottom: 30 }}>
                  <DemoIconDropDown labelTxt={"Success 2 Icon"} icon={successIcon2} onFunc={handleSuccessIcon2SelectionChange} />
                  <FontAwesomeIcon icon={icons[rnIcons.indexOf(successIcon2)]} size="5x" color={"#4D9F71"} />
                  <DemoIconDropDown labelTxt={"Failure 2 Icon"} icon={failureIcon2} onFunc={handleFailureIcon2SelectionChange} />
                  <FontAwesomeIcon icon={icons[rnIcons.indexOf(failureIcon2)]} size="5x" color={"#B02318"} />
                </div>
                <TextField
                  style={{ width: "60%" }}
                  id="tagName2"
                  name="tagName2"
                  label="Tag 2 Name"
                  value={formik.values.tagName2}
                  onChange={formik.handleChange}
                  error={formik.touched.tagName2 && Boolean(formik.errors.tagName2)}
                  helperText={formik.touched.tagName2 && formik.errors.tagName2}
                />
                <TextField
                  style={{ width: "60%" }}
                  id="successText2"
                  name="successText2"
                  label="Success 2 Text"
                  value={formik.values.successText2}
                  onChange={formik.handleChange}
                  error={formik.touched.successText2 && Boolean(formik.errors.successText2)}
                  helperText={formik.touched.successText2 && formik.errors.successText2}
                />
                <TextField
                  style={{ width: "60%" }}
                  id="failureText2"
                  name="failureText2"
                  label="Failure 2 Text"
                  value={formik.values.failureText2}
                  onChange={formik.handleChange}
                  error={formik.touched.failureText2 && Boolean(formik.errors.failureText2)}
                  helperText={formik.touched.failureText2 && formik.errors.failureText2}
                />
              </div>
            )}
            <div style={{ height: 60, width: "100%", marginTop: 24, marginLeft: 0 }}>
              <Button style={{ width: "25%", marginRight: 20 }} color="primary" variant="contained" type="submit" disabled={isUpdating}>
                Update Existing
              </Button>
              <Button
                style={{ width: "25%" }}
                color="#4e9f74"
                variant="contained"
                onClick={() => postNewDemoProduct()}
                disabled={isUpdating}>
                Create New
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    debug.log("Reauthenticating...");
    return Auth0.authRedirect();
  }
};

export default DemoProductsGrid;
