//import logging from './logging';
//const DEBUG = true;
//const debug = logging(DEBUG, `dataUtils.js`);

const epoch = new Date("1970-01-01");
const GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE";
const REQUEST = "REQUEST",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE";

const success = (op, verb) => `${verb.toUpperCase()}_${op.toUpperCase()}_SUCCESS`;
const failure = (op, verb) => `${verb.toUpperCase()}_${op.toUpperCase()}_FAILURE`;
const request = (op, verb) => `${verb.toUpperCase()}_${op.toUpperCase()}_REQUEST`;

export { success, failure, request, GET, POST, PUT, PATCH, DELETE, SUCCESS, FAILURE, REQUEST, epoch };
